import React from 'react';
import { useFormikContext } from 'formik';
import FormField from 'pages/TaskTemplates/components/FormField';
import { Col } from 'components/lib/Grid';
import { FormikLineCountTextarea } from 'components/LineCountTextarea';
import clsx from 'clsx';
import { OBJECT_CLASS_FIELD_OPTIONS } from 'utils/testIds';
import { ObjectClassFieldTypes } from 'utils/types/api/objectClassesFields.types';
import { FieldDefaultValue } from '../FieldDefaultValue/FieldDefaultValue';
import { useEnumSetValidators } from './hooks';
import { useClassFieldFormContext } from 'pages/ObjectClasses/components/ObjectClassForm/contexts/ClassFieldFormContext';
import { useClassFieldPropertiesStyles } from 'styles/classFieldPropertiesStyles';
import { FormContentProps } from '../../types';
import { ClassFieldForm } from '../../../../types';
import { ClassFieldFormFields } from 'pages/ObjectClasses/enums';
import { ObjectClassOptionsLimitReachedMessage } from 'pages/ObjectClasses/components/ObjectClassOptionsLimitReachedMessage';

export const FieldSelectionOptions: React.FC<FormContentProps> = ({
  shouldBeDisabled,
  parsedTypes,
}) => {
  const styles = useClassFieldPropertiesStyles();
  const { readOnly } = useClassFieldFormContext();
  const {
    values: { options = [], type, default_value: defaultValue },
    setFieldValue,
    setFieldTouched,
  } = useFormikContext<ClassFieldForm>();

  const optionsCount = options.length;

  const { maxOptionsCount, optionMaxLength } = useEnumSetValidators(
    parsedTypes
  );

  const handleBlur = () => {
    if (defaultValue && !options.includes(String(defaultValue))) {
      setFieldValue(ClassFieldFormFields.DefaultValue, undefined);
    }
    //Make MinValues field touched to validate if the min selections > options length
    setFieldTouched(ClassFieldFormFields.MinValues, true);
  };

  return (
    <div
      className={clsx([
        styles.fieldPropertiesWrapper,
        { disabled: readOnly || shouldBeDisabled },
      ])}
    >
      <FormField>
        <Col span={24}>
          <FormikLineCountTextarea<ClassFieldForm>
            name={ClassFieldFormFields.SelectionOptions}
            disabled={readOnly || shouldBeDisabled}
            {...{
              options,
              optionMaxLength,
              maxOptionsCount,
            }}
            onBlur={handleBlur}
            data-testid={OBJECT_CLASS_FIELD_OPTIONS}
          />
          <ObjectClassOptionsLimitReachedMessage {...{ optionsCount, maxOptionsCount }} />
        </Col>
      </FormField>
      {type === ObjectClassFieldTypes.Enum && (
        <FieldDefaultValue {...{ type, shouldBeDisabled, parsedTypes }} />
      )}
    </div>
  );
};