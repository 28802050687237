import { ViewUsageConfig, ViewUsageDisplayType } from "components/ViewUsage/types";
import { useMemo } from "react";
import { useIntl } from "react-intl";
import { ObjectClassCountObjectRecords } from "utils/types/api/objectClassesFields.types";
import { useObjectClassViewUsageData } from "./useObjectClassViewUsageData";

export const useObjectClassViewUsage = (id?: string) => {
    const intl = useIntl();
    const {
        loading,
        error,
        data: {
            num_of_records: numOfRecords = 0,
            num_of_sequences: numOfSequences = 0,
            num_of_task_group_templates: numOfTaskGroupTemplates = 0,
            num_of_task_templates: numOfTaskTemplates = [],
            sequences = [],
            object_models: relations = [],
            task_group_templates: taskGroupTemplates = [],
            task_templates: taskTemplates = [],
        } = {},
    } = useObjectClassViewUsageData(id);

    const objectRecords =
        !!numOfRecords ||
            !!numOfSequences ||
            !!numOfTaskGroupTemplates ||
            !!numOfTaskTemplates
            ? [
                {
                    num_of_records: numOfRecords,
                } as ObjectClassCountObjectRecords,
            ]
            : [];

    const displayConfig = useMemo<ViewUsageConfig[]>(
        () => [
            {
                usage: objectRecords,
                title: intl.formatMessage({
                    id: 'misc.objectRecords',
                    defaultMessage: 'Object records',
                }),
                type: ViewUsageDisplayType.CountOfRecords,
            },
            {
                usage: taskTemplates,
                title: intl.formatMessage({
                    id: 'misc.taskTemplates',
                    defaultMessage: 'Task templates',
                }),
            },
            {
                usage: taskGroupTemplates,
                title: intl.formatMessage({
                    id: 'misc.taskGroups',
                    defaultMessage: 'Task group templates',
                }),
            },
            {
                usage: sequences,
                title: intl.formatMessage({
                    id: 'misc.sequences',
                    defaultMessage: 'Sequences',
                }),
            },
            {
                usage: relations,
                title: intl.formatMessage({
                    id: 'misc.relations',
                    defaultMessage: 'Relations',
                }),
            },
        ],
        [
            intl,
            objectRecords,
            sequences,
            taskGroupTemplates,
            taskTemplates,
            relations,
        ]
    );

    return { loading, error, displayConfig };
};
