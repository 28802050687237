import { useFormikContext } from "formik";
import { ClassFieldFormFields } from "pages/ObjectClasses/enums";
import { useMemo, useEffect } from "react";
import { ObjectClassFieldTypes } from "utils/types/api/objectClassesFields.types";
import { ClassFieldForm } from "../../../../../types";

export const useUserMinMaxDisplay = () => {
    const {
        values: {
            type,
            users = [],
            user_groups: userGroups = [],
            allow_members_selection: allowIndividuallMembers,
        },
        setFieldValue,
    } = useFormikContext<ClassFieldForm>();
    const showUsersMinMax = useMemo(() => {
        if (type !== ObjectClassFieldTypes.User) {
            return false;
        }
        if (!users.length && !userGroups.length) {
            return false;
        }
        if (users.length) {
            return true;
        }
        if (allowIndividuallMembers) {
            return true;
        }
        return false;
    }, [users, userGroups, allowIndividuallMembers, type]);

    useEffect(() => {
        if (type !== ObjectClassFieldTypes.User) {
            return;
        }

        if (!showUsersMinMax) {
            setFieldValue(ClassFieldFormFields.MinUsersValues, undefined);
            setFieldValue(ClassFieldFormFields.MaxUsersValues, undefined);
        }
    }, [showUsersMinMax, setFieldValue, type]);
    return showUsersMinMax;
};