import React, {
  forwardRef,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import debounce from 'lodash/debounce';
import { useIntl } from 'react-intl';
import {
  SelectUserGroupOption,
  SelectUserOption,
} from 'utils/types/selectInput.types';
import { UsersGroupsSelectorProps } from './types';
import { Select, SelectOption } from 'components/lib/Select';
import { SearchBoldIcon } from 'components/Icon';
import useAutocompleteUsersSelectStyles from 'components/AutocompleteUsersSelect/styles';
import clsx from 'clsx';
import { v4 as uuidv4 } from 'uuid';
import { useScrollableParent } from 'hooks/useScrollableParent';
import { Select as AntSelect } from 'antd';
import {
  USERS_AND_GROUPS_SELECT_DROPDOWN_TESTID,
  USERS_AND_GROUPS_SELECT_TESTID,
  USERS_AND_GROUPS_SELECT_WRAPPER_TESTID,
} from 'utils/testIds';

/**
 * More verbose version of a component that allows selection of users and groups.
 *
 * Displays antd Select component with a purpose to select either users or groups. This component handles the antd Select logic with search
 * and other stuff related to the Select component.
 *
 * It's modular, so the final content has to be created using popoverContent prop. Some variants can be found in child components folder.
 */
export const UsersGroupsSelector = forwardRef<
  AntSelect,
  UsersGroupsSelectorProps
>(
  (
    {
      className,
      value,
      errors,
      minSelectedUsers,
      maxSelectedUsers,
      minSelectedGroups,
      maxSelectedGroups,
      popoverContent,
      searchPlaceholder,
      onChange: setValue,
      disabled,
      externalDropdownControl,
      dropdownClassName,
      getPopupContainer,
      dropdownAlign,
      onBlur,
    },
    ref
  ) => {
    const intl = useIntl();
    const styles = useAutocompleteUsersSelectStyles({ disabled });

    const uniqueIdentifierRef = useRef(uuidv4());
    const elementRef = useRef<HTMLDivElement>(null);

    const { getScrollableParent } = useScrollableParent(elementRef);

    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [displayedText, setDisplayedText] = useState('');
    const [searchText, setSearchText] = useState('');
    const [searchPlaceholderDynamic, setSearchPlaceholderDynamic] = useState(
      searchPlaceholder ??
        intl.formatMessage({
          id: 'misc.searchForUsersAndUserGroups',
          defaultMessage: 'Search for users and user groups',
        })
    );

    const finalDropdownOpenState =
      externalDropdownControl?.isDropdownOpen ?? isDropdownOpen;

    const debounceSearchText = useMemo(
      () =>
        debounce(value => {
          setSearchText(value);
        }, 200),
      []
    );

    const handleSearchChange = useCallback(
      (value: string) => {
        setDisplayedText(value);
        debounceSearchText(value);
      },
      [debounceSearchText]
    );

    const handleDropdownVisibleChange = (isOpen: boolean) => {
      if (!isOpen && onBlur) {
        onBlur();
      }

      if (externalDropdownControl) {
        externalDropdownControl.onDropdownVisibleChange(isOpen);
        return;
      }

      setIsDropdownOpen(isOpen);
    };

    const setUsers = (users: SelectUserOption[]) => {
      if (!setValue) {
        return;
      }

      setValue({
        users: users,
        groups: value.groups,
      });
    };

    const setGroups = (groups: SelectUserGroupOption[]) => {
      if (!setValue) {
        return;
      }

      setValue({
        users: value.users,
        groups: groups,
      });
    };

    const clearSearchField = useCallback(() => {
      setSearchText('');
      setDisplayedText('');
    }, []);

    // The standard Select component is being used only for it's search field and consistency of
    // display purposes only - it does not manage state in any way.
    return (
      <div
        ref={elementRef}
        className={styles.selectWrapper}
        data-testid={USERS_AND_GROUPS_SELECT_WRAPPER_TESTID}
      >
        <Select
          ref={ref}
          className={clsx(styles.autocompleteSelect, className)}
          dropdownClassName={dropdownClassName}
          open={finalDropdownOpenState}
          placeholder={searchPlaceholderDynamic}
          icon={<SearchBoldIcon size={12} className={styles.searchIcon} />}
          disabled={disabled}
          dropdownAlign={dropdownAlign}
          showSearch
          searchValue={displayedText}
          getPopupContainer={getPopupContainer ?? getScrollableParent}
          onSearch={handleSearchChange}
          onDropdownVisibleChange={handleDropdownVisibleChange}
          data-testid={USERS_AND_GROUPS_SELECT_TESTID}
          dropdownRender={() =>
            popoverContent({
              searchText,
              groups: value.groups,
              users: value.users,
              errors,
              setUsers,
              setGroups,
              setSearchPlaceholder: setSearchPlaceholderDynamic,
              uniqueIdentifier: uniqueIdentifierRef.current,
              minSelectedUsers,
              maxSelectedUsers,
              minSelectedGroups,
              maxSelectedGroups,
              searchHasFocus: finalDropdownOpenState,
              clearSearchField,
              testId: USERS_AND_GROUPS_SELECT_DROPDOWN_TESTID,
            })
          }
        >
          <SelectOption value={''}>{null}</SelectOption>
        </Select>
      </div>
    );
  }
);
