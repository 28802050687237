import { useState } from 'react';
import { useIntl } from 'react-intl';
import { StatusCodes } from 'http-status-codes';
import { apiCall } from 'utils/api';
import { parseError } from 'utils/parseError';
import ToastType from 'utils/Enums/ToastType';
import { ErrorCodes, ErrorMessages } from 'utils/types/errorResponse';
import { toast } from 'components/lib/toast';
import { FormMode } from 'utils/Enums/FormMode';
import { getTouchedData } from '../utils/functions/getTouchedData';
import useSuccessToast from 'hooks/useSuccessToast';
import { AxiosError, AxiosResponse } from 'axios';

type HideErrorToastMethod = (
  errorResponse: AxiosResponse | undefined
) => boolean;

interface UsePostWithToastsParams<T extends {}, U> {
  url: string;
  data: T;
  callback?: (data?: U) => Promise<U | void> | void;
  fields: MappedObject<string>;
  setErrors: (errors: ErrorMessages) => void;
  initialData?: T;
  successMessage?: { title: string; subtitle: string };
  setSubmitting?: (value: boolean) => void;
  flatErrors?: boolean;
  errorMessage?: { title: string; subtitle: string };
  hideErrorToast?: boolean | HideErrorToastMethod;
}

interface ErrorData
  extends Omit<
    UsePostWithToastsParams<{}, unknown>,
    'data' | 'url' | 'callback' | 'initialData'
  > {
  e: AxiosError;
}

export type SubmitErrorCode = string | number | undefined;

export const usePostWithToasts = <T, U>(
  mode?: FormMode,
  formPrefix?: string
) => {
  const intl = useIntl();
  const [submitErrorCode, setSubmitErrorCode] = useState<SubmitErrorCode>(
    undefined
  );
  const displayToast = useSuccessToast();

  const handleError = ({
    e,
    flatErrors,
    hideErrorToast,
    setErrors,
    errorMessage,
    setSubmitting,
    fields,
  }: ErrorData) => {
    const { status, messages } = parseError(e, formPrefix, flatErrors);
    const errorCode = messages?.error_code;

    if (errorCode === ErrorCodes.ERR_LIMIT_EXCEEDED) {
      setSubmitErrorCode(errorCode);

      return;
    }

    setSubmitErrorCode(e?.response?.status);

    if (
      (status === StatusCodes.METHOD_NOT_ALLOWED ||
        status === StatusCodes.BAD_REQUEST) &&
      messages
    ) {
      // in specific cases, with specific errors, we want to hide the toast message because in the meantime another error message shows up
      if (
        hideErrorToast === undefined ||
        (typeof hideErrorToast === 'boolean'
          ? !hideErrorToast
          : !hideErrorToast(e?.response))
      ) {
        const allFieldsExist = Object.keys(messages).every(field =>
          Object.values<string>(fields).includes(field)
        );

        if (!allFieldsExist)
          toast(
            {
              ...(errorMessage ?? {
                title: intl.formatMessage({
                  id: 'errors.somethingWentWrong',
                  defaultMessage: 'Something went wrong',
                }),
                subtitle: intl.formatMessage({
                  id: 'misc.pleaseTryAgainLater',
                  defaultMessage: 'Please try again later.',
                }),
              }),
            },
            ToastType.Error
          );
      }

      setErrors(messages);
    }

    if (setSubmitting) setSubmitting(false);

    throw e;
  };

  const sendData = async ({
    url,
    data,
    callback,
    fields,
    setErrors,
    initialData,
    successMessage,
    setSubmitting,
    flatErrors,
    errorMessage,
    hideErrorToast,
  }: UsePostWithToastsParams<T, U>) => {
    setSubmitErrorCode(undefined);

    try {
      const isEdit = mode === FormMode.Edit;
      const touchedData = getTouchedData<T>(initialData, data);
      const fixedData = isEdit ? touchedData : data;
      const { status, data: axiosData } = await apiCall[
        isEdit ? 'patch' : 'post'
      ]<U>(url, fixedData);

      if (status === StatusCodes.CREATED || status === StatusCodes.OK) {
        displayToast(successMessage);

        if (callback) {
          await callback(axiosData);
        }

        return axiosData;
      }
    } catch (e) {
      handleError({
        e: e as AxiosError,
        fields,
        flatErrors,
        hideErrorToast,
        setErrors,
        errorMessage,
        setSubmitting,
      });
    }
  };

  return {
    isLimitExceeded: submitErrorCode === ErrorCodes.ERR_LIMIT_EXCEEDED,
    submitErrorCode,
    sendData,
  };
};
