import React, { useEffect, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { useFormikContext } from 'formik';
import clsx from 'clsx';
import FlexLayoutWindows from 'utils/Enums/FlexLayoutWindows';
import { ButtonSecondaryOutlined, ButtonTertiary } from 'components/lib/Button';
import { CheckIcon, CloseIcon, PlusIcon } from 'components/Icon';
import Tooltip from 'components/lib/Tooltip';
import useFormHeader from 'components/FormHeader/hooks';
import useFlexLayoutWindow from 'hooks/useFlexLayoutWindow';
import { useFlexLayoutContext } from 'components/lib/FlexLayout/FlexLayoutContext';
import { BUTTON_CANCEL_TESTID, BUTTON_SUBMIT_TESTID } from 'utils/testIds';
import { ClassFieldForm, ClassFieldFormProps } from './types';
import { isEmpty } from 'lodash';
import { FormMode } from 'utils/Enums/FormMode';
import { useClassFieldFormContext } from 'pages/ObjectClasses/components/ObjectClassForm/contexts/ClassFieldFormContext';
import { useAdditionalControls } from '../../hooks/useAdditionalControls';
import { ObjectClassField } from 'utils/types/api/objectClassesFields.types';
import { useObjectFieldFormValidation, useStoredData } from './hooks';
import { ClassFieldChangesConfirmationModals } from './components/ClassFieldChangesConfirmationModals';
import { ClassFieldFormContent } from './components/ClassFieldFormContent';
import { useClassFieldPropertiesStyles } from 'styles/classFieldPropertiesStyles';

export const ClassFieldFormComponent: React.FC<ClassFieldFormProps> = ({
  savePanelValues,
  parsedTypes,
}) => {
  const styles = useClassFieldPropertiesStyles();
  const additionalControls = useAdditionalControls();
  const {
    dirty,
    isSubmitting,
    errors,
    submitForm,
    setValues,
    validateForm,
    values,
    initialValues,
  } = useFormikContext<ClassFieldForm>();
  const { closeComponent, isExternal } = useFlexLayoutWindow(
    FlexLayoutWindows.ObjectClassEditField
  );
  const { isComponentOpen } = useFlexLayoutContext();

  const isEditSidebarOpened = isComponentOpen(
    FlexLayoutWindows.ObjectClassEditField
  );

  const {
    isCancelModalVisible,
    onDontSaveClick,
    toggleCancelModal,
  } = useFormHeader();

  const { isExternal: isAddExternal } = useFlexLayoutWindow(
    FlexLayoutWindows.ObjectClassAddField
  );

  const { isObjectFieldFormValid } = useObjectFieldFormValidation();

  const closePanel = () => {
    if (isEditSidebarOpened) {
      closeComponent(FlexLayoutWindows.ObjectClassEditField);
    } else {
      closeComponent(FlexLayoutWindows.ObjectClassAddField);
    }

    savePanelValues({} as ClassFieldForm);
  };

  const onSubmit = async () => await submitForm();

  const handleCancel = () => {
    if (isExternal || isAddExternal) return closePanel();

    if (!dirty) return closePanel();

    toggleCancelModal(true);
  };

  const { panelId, mode } = useClassFieldFormContext();
  const { storedValues } = useStoredData<ClassFieldForm & ObjectClassField>(
    panelId
  );
  const wasUpdated = useRef<boolean>(false);

  useEffect(() => {
    if (
      mode === FormMode.Edit &&
      !isEmpty(storedValues) &&
      JSON.stringify(values) === JSON.stringify(initialValues) &&
      JSON.stringify(values) !== JSON.stringify(storedValues) &&
      values?.alias === storedValues?.alias
    ) {
      setValues({ ...storedValues });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues]);

  useEffect(() => {
    if (!isEmpty(storedValues)) {
      setValues({ ...storedValues });
      wasUpdated.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      wasUpdated.current &&
      JSON.stringify(values) === JSON.stringify(storedValues)
    ) {
      wasUpdated.current = false;
      setTimeout(() => validateForm(values));
    }
  }, [storedValues, validateForm, values]);

  return (
    <div className={clsx(styles.fullWidth, styles.colWrapper)}>
      <div
        className={styles.contentWrapper}
        onBlur={() => savePanelValues(values)}
      >
        <ClassFieldFormContent {...{ additionalControls, parsedTypes }} />
      </div>
      <div className={styles.buttonsWrapper}>
        <ButtonSecondaryOutlined
          onClick={handleCancel}
          icon={<CloseIcon size={18} />}
          data-testid={BUTTON_CANCEL_TESTID}
        >
          <FormattedMessage id='misc.cancel' defaultMessage='Cancel' />
        </ButtonSecondaryOutlined>
        <Tooltip
          placement='top'
          title={
            <FormattedMessage
              id='misc.mandatoryFields'
              defaultMessage='Mandatory fields are not complete'
            />
          }
          mouseEnterDelay={0.1}
          tooltipMaxWidth={132}
          shouldBeRendered={
            Object.keys(errors).length > 0 || !isObjectFieldFormValid
          }
          className={styles.tooltip}
        >
          <div></div>
        </Tooltip>
        <ButtonTertiary
          icon={
            mode === FormMode.Edit ? (
              <CheckIcon size={12} />
            ) : (
              <PlusIcon size={12} />
            )
          }
          disabled={
            Object.keys(errors).length > 0 ||
            isSubmitting ||
            !dirty ||
            !isObjectFieldFormValid
          }
          onClick={onSubmit}
          loading={isSubmitting}
          data-testid={BUTTON_SUBMIT_TESTID}
        >
          {mode === FormMode.Edit ? (
            <FormattedMessage id='misc.save' defaultMessage='Save' />
          ) : (
            <FormattedMessage id='misc.add' defaultMessage='Add' />
          )}
        </ButtonTertiary>
      </div>
      <ClassFieldChangesConfirmationModals
        {...{
          isCancelModalVisible,
          onDontSaveClick,
          toggleCancelModal,
          closePanel,
          onSubmit,
        }}
      />
    </div>
  );
};
