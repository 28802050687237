import { useMemo } from 'react';
import { useIntl } from 'react-intl';

export const useUserValidationMessages = () => {
  const intl = useIntl();

  const noUsersToSelect = useMemo(
    () =>
      intl.formatMessage({
        id: 'errors.noUsersToSelect',
        defaultMessage: 'No users to select',
      }),
    [intl]
  );

  const notEnoughUsersToSelect = useMemo(
    () =>
      intl.formatMessage({
        id: 'errors.notEnoughToSelect',
        defaultMessage: 'Not enough users to select',
      }),
    [intl]
  );

  const noUsersToSelectError = useMemo(
    () =>
      intl.formatMessage({
        id: 'errors.noUsersToSelectError',
        defaultMessage:
          'No users to select and save the record. Contact System Administrator.',
      }),
    [intl]
  );

  const notEnoughUsersToSelectError = useMemo(
    () =>
      intl.formatMessage({
        id: 'errors.notEnoughToSelectError',
        defaultMessage:
          'Not enough users to select and save the record. Contact System Administrator.',
      }),
    [intl]
  );

  return {
    noUsersToSelect,
    noUsersToSelectError,
    notEnoughUsersToSelect,
    notEnoughUsersToSelectError,
  };
};
