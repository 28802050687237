import { useCallback, useEffect, useState } from 'react';
import useData from 'hooks/useData';
import { TASKS_LIST } from 'utils/endpoints';
import { GetResponse } from 'utils/types';
import { Task } from 'utils/types/api/tasks.types';
import { DEFAULT_PAGE_SIZE_INFINITE_SCROLL } from 'components/Table/Table.consts';

export const useRecordTasks = (id?: string) => {
  const [page, setPage] = useState<number>(0);
  const [mergedData, setMergedData] = useState<GetResponse<Task> | undefined>();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, { loading, error, fetchData }] = useData<GetResponse<Task>>(
    id
      ? `${TASKS_LIST}?object_record=${id}&limit=${DEFAULT_PAGE_SIZE_INFINITE_SCROLL}&ordering=-id`
      : '',
    { fetchOnLoad: false }
  );

  const fetchNextPage = async () => {
    const newPage = page + 1;

    const { data } = await fetchData(
      `${TASKS_LIST}?object_record=${id}&limit=${DEFAULT_PAGE_SIZE_INFINITE_SCROLL}&offset=${
        newPage * DEFAULT_PAGE_SIZE_INFINITE_SCROLL
      }&ordering=-id`
    );

    setPage(newPage);

    if (data)
      setMergedData(prevData => ({
        ...data,
        results: [...(prevData?.results || []), ...data.results],
      }));
  };

  const fetchAndSetData = useCallback(async () => {
    if (id) {
      const { data } = await fetchData();

      setMergedData(data);
    }
  }, [fetchData, id]);

  useEffect(() => {
    (async () => await fetchAndSetData())();

    setPage(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return {
    data: mergedData,
    loading,
    error,
    fetchNextPage,
    fetchData: fetchAndSetData,
  };
};
