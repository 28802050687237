import { useFlexLayoutContext } from "components/lib/FlexLayout/FlexLayoutContext";
import { noop } from "lodash";
import FlexLayoutWindows from "utils/Enums/FlexLayoutWindows";

export const useTogglePanel = () => {
    const {
        flexLayoutMethods: { onAddTab },
    } = useFlexLayoutContext();

    return (name?: FlexLayoutWindows) => {
        const panelsMappedToObjectAction: MappedObject<() => void> = {
            [FlexLayoutWindows.ObjectClassEditField]: () => {
                onAddTab(FlexLayoutWindows.ObjectClassEditField);
            },
            [FlexLayoutWindows.ObjectClassAddField]: () => {
                onAddTab(FlexLayoutWindows.ObjectClassAddField);
            },
            [FlexLayoutWindows.ObjectClassFieldViewUsage]: () => {
                onAddTab(FlexLayoutWindows.ObjectClassFieldViewUsage);
            },
        };

        return name
            ? panelsMappedToObjectAction[name] && panelsMappedToObjectAction[name]()
            : noop();
    };
};