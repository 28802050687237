import { orderBy } from 'lodash';
import {
  SelectUserAndGroupOption,
  SelectUserAndGroupOptionMap,
} from 'utils/types/selectInput.types';

/**
 * Determines which items should be visible on the left list of UsersAndGroupsExpandablePicker based
 * on the search filter and the selected items.
 */
export const useUsersAndGroupsExpandablePickerVisibleItems = (
  availableItems: SelectUserAndGroupOption,
  selectedItems: SelectUserAndGroupOptionMap,
  searchFilter?: string
) => {
  const sortedGroups = orderBy(availableItems.groups, [
    ({ text }) => text.toLowerCase(),
  ]);

  const selectedUsersAsArray = Array.from(selectedItems.users.values());

  // groups with sync mode disabled can select users that were not present on the original available users list
  // we check here for any selected users that are outside the available list and add them to the left panel if
  // they are selected.
  const visibleUsers = [...availableItems.users];

  selectedUsersAsArray.forEach(user => {
    const isExistingOnAvailableList = visibleUsers.some(
      visibleUser => visibleUser.id === user.id
    );

    if (isExistingOnAvailableList) {
      return;
    }

    visibleUsers.push(user);
  });

  const sortedUsers = orderBy(visibleUsers, [
    ({ first_name, last_name, username }) =>
      (first_name + last_name + username).toLowerCase(),
  ]);

  const allItems = [...sortedGroups, ...sortedUsers];
  const filteredItems = allItems.filter(item =>
    item.text.toUpperCase().includes(searchFilter?.toUpperCase() ?? '')
  );

  return { visibleItems: filteredItems, allItems };
};
