import useValidationSchemaBuilder from "hooks/useValidationSchemaBuilder";
import { mapTaskOptionsToDictionary } from "pages/TaskTemplates/utils";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { getObjectClassFieldsDetails } from "store/selectors/objectClassesFieldsSelectors";
import { ClassFieldTypeOptions } from "../types";
import { parseTypes } from "../utils";
import * as yup from 'yup';
import { maxUsersCount } from "../components/ClassFieldForm/components/ClassFieldFormContent/consts";
import { maxGroupsCount } from "../consts";

export const useClassFieldOptions = () => {
  const intl = useIntl();
  const data = useSelector(getObjectClassFieldsDetails);
  const options = data ? mapTaskOptionsToDictionary(data.schema) : {};
  const {
    rawValidationSchema,
    buildValidationSchema,
  } = useValidationSchemaBuilder<ClassFieldTypeOptions | {}>(options, {
    max_num_of_files: yup.number().when('type', {
      is: 'document',
      then: yup.number().required(
        intl.formatMessage({
          id: 'errors.fieldIsRequired',
          defaultMessage: 'Field is required',
        })
      ),
    }),
    min_values: yup.number().test({
      name: 'min',
      params: {},
      message: intl.formatMessage({
        id: 'errors.notEnoughOptionsProvided',
        defaultMessage: 'Not enough options provided',
      }),
      test: function (value) {
        const length =
          this.parent?.users?.length || this.parent?.options?.length || 0;

        if (typeof value === 'number') {
          return value <= length;
        }
        return true;
      },
    }),
    max_values: yup.number().test({
      name: 'max',
      params: {},
      message: intl.formatMessage({
        id: 'errors.notEnoughOptionsProvided',
        defaultMessage: 'Not enough options provided',
      }),
      test: function (value) {
        const length =
          this.parent?.users?.length || this.parent?.options?.length || 0;

        if (typeof value === 'number') {
          return value <= length;
        }
        return true;
      },
    }),
    min_users_values: yup.number().test({
      name: 'min',
      params: {},
      message: intl.formatMessage({
        id: 'errors.notEnoughOptionsProvided',
        defaultMessage: 'Not enough options provided',
      }),
      test: function (value) {
        const length = this.parent?.users?.length ?? 0;

        if (this.parent?.allow_members_selection) {
          return true;
        }
        if (typeof value === 'number') {
          return value <= length;
        }
        return true;
      },
    }),
    max_users_values: yup.number().test({
      name: 'max',
      params: {},
      message: intl.formatMessage({
        id: 'errors.notEnoughOptionsProvided',
        defaultMessage: 'Not enough options provided',
      }),
      test: function (value) {
        const length = this.parent?.users?.length ?? 0;

        if (this.parent?.allow_members_selection) {
          return true;
        }

        if (typeof value === 'number') {
          return value <= length;
        }
        return true;
      },
    }),
    min_groups_values: yup.number().test({
      name: 'min',
      params: {},
      message: intl.formatMessage({
        id: 'errors.notEnoughOptionsProvided',
        defaultMessage: 'Not enough options provided',
      }),
      test: function (value) {
        const length = this.parent?.user_groups?.length ?? 0;

        if (typeof value === 'number') {
          return value <= length;
        }
        return true;
      },
    }),
    max_groups_values: yup.number().test({
      name: 'max',
      params: {},
      message: intl.formatMessage({
        id: 'errors.notEnoughOptionsProvided',
        defaultMessage: 'Not enough options provided',
      }),
      test: function (value) {
        const length = this.parent?.user_groups?.length ?? 0;

        if (typeof value === 'number') {
          return value <= length;
        }
        return true;
      },
    }),
    users: yup.array().max(maxUsersCount),
    groups: yup.array().max(maxGroupsCount),
    allow_sync: yup.boolean().test({
      name: 'allow_sync',
      params: {},
      message: intl.formatMessage({
        id: 'objectClasses.fields.atLeastOneOptionMustBeEnabled',
        defaultMessage: 'At least one option must be enabled',
      }),
      test: function (value) {
        if (
          this.parent?.user_groups?.length > 0 &&
          !this.parent?.allow_members_selection &&
          !value
        ) {
          return false;
        }
        return true;
      },
    }),
  });

  const parsedTypes = parseTypes(options?.type?.values || []);

  return { options, rawValidationSchema, buildValidationSchema, parsedTypes };
};