import { ViewUsageConfig } from "components/ViewUsage/types";
import { useMemo } from "react";
import { useIntl } from "react-intl";
import { useDocumentTemplateUsageData } from "./useDocumentTemplateUsageData";

export const useDocumentTemplateUsage = (id?: string, objectClassID?: string) => {
    const intl = useIntl();
    const {
        loading,
        error,
        data: { sequences = [] } = {},
    } = useDocumentTemplateUsageData(id, objectClassID);

    const displayConfig = useMemo<ViewUsageConfig[]>(
        () => [
            {
                usage: sequences,
                title: intl.formatMessage({
                    id: 'misc.sequences',
                    defaultMessage: 'Sequences',
                }),
            },
        ],
        [intl, sequences]
    );

    return { loading, error, displayConfig };
};