import { AvatarItem } from 'components/lib/Avatar/types';
import useTokenDecode from 'hooks/useTokenDecode';
import { StatusCodes } from 'http-status-codes';
import { ViewParams } from 'pages/Role/types';
import { useCallback, useState } from 'react';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setSidebarData } from 'store/actions/flexLayoutActions';
import {
  getObjectClassPermissionsClassOwners,
  setObjectClassPermissionsClassOwnersIsRemovingHimself,
  setObjectClassPermissionsOwnersFetching,
  setObjectClassSelectedPermissionSet,
} from 'store/actions/objectClassPermissionsActions';
import {
  getObjectClassPermissionsClassOwnersCount,
  getObjectClassPermissionsClassOwnersItemsLimit,
  getObjectClassPermissionsIsRemovingHimself,
} from 'store/selectors/objectClassPermissionsSelectors';
import { apiCall } from 'utils/api';
import { OBJECT_CLASS_DETAILS, OBJECT_CLASS_OWNER } from 'utils/endpoints';
import FlexLayoutWindows from 'utils/Enums/FlexLayoutWindows';
import errorToast from 'utils/functions/errorToast';
import { OwnerToDeleteType } from './types';
import { OwnersMode } from '../AddUsersPanel/types';
import routes from 'utils/routingPaths';
import { getPermissions } from 'store/actions/permissionsActions';
import { useFlexLayoutContext } from 'components/lib/FlexLayout/FlexLayoutContext';
import { ObjectClassDetailsUrlParams } from 'pages/ObjectClasses/components/ObjectClassForm/types';

const useManageOwnersPanel = () => {
  const { id = '' } = useParams<ObjectClassDetailsUrlParams>();
  const limitOwnersItems = useSelector(
    getObjectClassPermissionsClassOwnersItemsLimit
  );
  const ownersCount = useSelector(getObjectClassPermissionsClassOwnersCount);
  const isRemovingHimself = useSelector(
    getObjectClassPermissionsIsRemovingHimself
  );
  const history = useHistory();
  const dispatch = useDispatch();
  const { closePanelIfOpen } = useFlexLayoutContext();

  const checkAccessToObjectClass = useCallback(async () => {
    try {
      const { status, data } = await apiCall.get(
        id
          ? generatePath(OBJECT_CLASS_DETAILS, {
              id,
            })
          : ''
      );

      if (status === StatusCodes.OK) return data._meta.permissions.edit;
    } catch {}

    return false;
  }, [id]);

  const removeObjectClassPermissionsClassOwner = async (
    objectClassId: string,
    ownershipId: number
  ) => {
    dispatch(setObjectClassPermissionsOwnersFetching(true));

    try {
      const { status } = await apiCall.delete(
        generatePath(OBJECT_CLASS_OWNER, {
          objectClassId,
          ownershipId,
        })
      );

      const hasAccessToObjectClass = await checkAccessToObjectClass();
      // Andrzej asked for this comment: This place and other places on the system that require large amounts of data to be downloaded while deleting an assigned user are exceptions. We deliberately not get the class using the redux function, because that would cause the view to rerender, fetching all permission sets and their assignees again. We only update the required piece of data to avoid performance issues

      if (status === StatusCodes.NO_CONTENT) {
        await dispatch(
          getPermissions(async permissions => {
            if (isRemovingHimself && !hasAccessToObjectClass) {
              history.push(
                permissions.object_classes.list
                  ? routes.OBJECT_CLASSES
                  : routes.WORKSPACE
              );
            } else {
              await dispatch(
                getObjectClassPermissionsClassOwners(objectClassId)
              );
            }
          })
        );
      }
    } catch {
      errorToast();
    } finally {
      dispatch(setObjectClassPermissionsOwnersFetching(false));
      dispatch(setObjectClassPermissionsClassOwnersIsRemovingHimself(false));
    }
  };

  const isLimitExceeded =
    limitOwnersItems !== undefined && ownersCount >= limitOwnersItems;

  const openAddOwnersPanel = useCallback(
    () =>
      dispatch(
        setSidebarData(FlexLayoutWindows.ClassPermissionsOwners, {
          mode: OwnersMode.Adding,
        })
      ),
    [dispatch]
  );

  const handleCancel = () => {
    closePanelIfOpen(FlexLayoutWindows.ClassPermissionsOwners);
    dispatch(setObjectClassSelectedPermissionSet(undefined));
  };

  const { id: objectClassId } = useParams<ViewParams>();
  const { user_id: userId } = useTokenDecode() || {};

  const [ownerToDelete, setOwnerToDelete] = useState<OwnerToDeleteType>();

  const onDeleteFabric = (avatar: AvatarItem) => () => {
    const { ownershipId, userGroup, firstName, lastName } = avatar;

    if (!ownershipId) return;

    dispatch(
      setObjectClassPermissionsClassOwnersIsRemovingHimself(
        userId === avatar.id
      )
    );

    setOwnerToDelete({
      ownershipId,
      displayName: userGroup ? userGroup : `${firstName} ${lastName}`,
    });
  };

  return {
    isLimitExceeded,
    openAddOwnersPanel,
    onDeleteFabric,
    objectClassId,
    ownerToDelete,
    setOwnerToDelete,
    handleCancel,
    removeObjectClassPermissionsClassOwner,
  };
};

export default useManageOwnersPanel;
