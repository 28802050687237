import { useFormikContext } from "formik";
import { ClassFieldFormFields } from "pages/ObjectClasses/enums";
import { useCallback } from "react";
import { ClassFieldForm } from "../../../../../types";

export const useSelectGroupsMaxMinValidation = () => {
    const {
        setFieldValue,
        validateField,
        values: {
            min_groups_values: minGroupsValues,
            max_groups_values: maxGroupsValues,
        },
    } = useFormikContext<ClassFieldForm>();

    return useCallback(
        (name: string, value?: number) => {
            const isMinValueChange = name === ClassFieldFormFields.MinGroupsValues;

            if (
                value !== undefined &&
                (isMinValueChange
                    ? maxGroupsValues !== undefined && value > maxGroupsValues
                    : minGroupsValues !== undefined && value < minGroupsValues)
            ) {
                const valueKeyToChange = isMinValueChange
                    ? ClassFieldFormFields.MaxGroupsValues
                    : ClassFieldFormFields.MinGroupsValues;
                const valueToSet = Math.floor(value);

                setFieldValue(valueKeyToChange, valueToSet);
                validateField(valueKeyToChange);
            }
        },
        [maxGroupsValues, minGroupsValues, validateField, setFieldValue]
    );
};