import React, { useEffect } from 'react';
import { FormMode } from 'utils/Enums/FormMode';
import { useFlexLayoutContext } from 'components/lib/FlexLayout/FlexLayoutContext';
import FlexLayoutWindows from 'utils/Enums/FlexLayoutWindows';
import { setSidebarData } from 'store/actions/flexLayoutActions';
import { useDispatch } from 'react-redux';
import { useConfirmationModalContext } from 'contexts/ConfirmationModalContext';
import { ClassFieldFormWrapper } from 'pages/ObjectClasses/components/ClassFieldFormWrapper';

export const ObjectClassAddField = () => {
  const dispatch = useDispatch();
  const {
    globalMethods: { closeComponent },
    isComponentOpen,
  } = useFlexLayoutContext();

  const { windowToOpen } = useConfirmationModalContext();

  useEffect(() => {
    const clearPanel = (key: FlexLayoutWindows) => {
      if (!isComponentOpen(key)) return;

      closeComponent(key);
      dispatch(setSidebarData(key, {}));
    };

    if (
      isComponentOpen(FlexLayoutWindows.ObjectClassEditField) &&
      windowToOpen === FlexLayoutWindows.ObjectClassEditField
    ) {
      clearPanel(FlexLayoutWindows.ObjectClassAddField);
      clearPanel(FlexLayoutWindows.ObjectClassFieldViewUsage);
    }

    if (
      isComponentOpen(FlexLayoutWindows.ObjectClassAddField) &&
      windowToOpen === FlexLayoutWindows.ObjectClassAddField
    ) {
      clearPanel(FlexLayoutWindows.ObjectClassEditField);
      clearPanel(FlexLayoutWindows.ObjectClassFieldViewUsage);
    }

    if (
      isComponentOpen(FlexLayoutWindows.ObjectClassFieldViewUsage) &&
      windowToOpen === FlexLayoutWindows.ObjectClassFieldViewUsage
    ) {
      clearPanel(FlexLayoutWindows.ObjectClassAddField);
    }
  }, [closeComponent, dispatch, isComponentOpen, windowToOpen]);

  return <ClassFieldFormWrapper mode={FormMode.Create} />;
};