import useData from "hooks/useData";
import { useEffect } from "react";
import { generatePath } from "react-router-dom";
import { DOCUMENT_TEMPLATE_USAGE } from "utils/endpoints";
import { DocumentTemplateUsage } from "../types";

export const useDocumentTemplateUsageData = (id?: string, objectClassID?: string) => {
    const [data, { loading, error, fetchData }] = useData<DocumentTemplateUsage>(
        id !== undefined && objectClassID !== undefined
            ? generatePath(DOCUMENT_TEMPLATE_USAGE, {
                id,
                objectClassID,
            })
            : '',
        {
            fetchOnLoad: false,
        }
    );

    useEffect(() => {
        if (id !== undefined && objectClassID !== undefined) fetchData();
    }, [fetchData, id, objectClassID]);

    return { error, loading, data };
};