import { AvatarItem } from 'components/lib/Avatar/types';
import { useState } from 'react';
import { apiCall } from 'utils/api';
import errorToast from 'utils/functions/errorToast';
import { showGroupsSuccessfulyAssignedToast } from './utils/showGroupsSuccessfulyAssignedToast';
import axios from 'axios';
import { isObjectNotExistsError } from 'utils/functions/isObjectNotExistsError';
import { showSomeGroupsHaveBeenDeletedToast } from './utils/showSomeGroupsHaveBeenDeletedToast';
import { showGroupAssigneesLimitExceededToast } from './utils/showGroupAssigneesLimitExceededToast';
import { isNoPermissionsToResourceError } from 'utils/functions/isNoPermissionsToResourceError';
import { showPermissionsToSomeGroupsRevokedToast } from './utils/showPermissionsToSomeGroupsRevokedToast';
import { isLimitExceededError } from 'utils/functions/isLimitExceededError';

/**
 * Handles the logic for adding user groups to a given resource.
 */
export const useAddGroupAssignees = (
  selectedGroups: AvatarItem[],
  postEndpoint: string,
  resourceName: string,
  groupsLimit: number | undefined,
  changePanelView: () => void,
  refetchGroupAssignees: () => Promise<void>
) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async () => {
    try {
      setIsSubmitting(true);

      const selectedGroupIds = selectedGroups.map(({ id }) => {
        return id;
      });

      await apiCall.post<unknown[]>(postEndpoint, selectedGroupIds);

      await refetchGroupAssignees();

      changePanelView();
      showGroupsSuccessfulyAssignedToast(resourceName, selectedGroupIds.length);
    } catch (error) {
      if (!axios.isAxiosError(error)) {
        errorToast();
        return;
      }

      // Checks whether some groups have been deleted while adding assignees.
      if (isObjectNotExistsError(error)) {
        changePanelView();
        showSomeGroupsHaveBeenDeletedToast(resourceName);
        return;
      }

      // Checks whether permissions to some groups have been revoked while adding assignees.
      if (isNoPermissionsToResourceError(error)) {
        changePanelView();
        showPermissionsToSomeGroupsRevokedToast(resourceName);
        return;
      }

      // Checks whether group assignees limit has been exceeded.
      if (isLimitExceededError(error)) {
        await refetchGroupAssignees();

        changePanelView();
        showGroupAssigneesLimitExceededToast(groupsLimit);
        return;
      }

      errorToast();
    } finally {
      setIsSubmitting(false);
    }
  };

  return { isSubmitting, handleSubmit };
};
