import React from 'react';
import { UsersGroupsListItemSelectAndDeselect } from '../../../../../components/UsersGroupsListItemSelectAndDeselect';
import { ExpandablePickerUserGroupItem } from './components/ExpandablePickerUserGroupItem';
import { ExpandablePickerItemListProps } from './ExpandablePickerItemList.types';
import { UserOrGroupSelectOptionType } from 'utils/types/selectInput.types';
import { useUsersAndGroupsExpandablePickerContext } from '../../../../contexts/UsersAndGroupsExpandablePickerContext/UsersAndGroupsExpandablePickerContext';
import { MaxUsersSelectedTooltip } from './components/MaxUsersSelectedTooltip';

/**
 * Shows a combined list of users and user groups.
 * Users are directly selectable as a toggle.
 * Groups are expandable with a click to its user list and additional UX options.
 */
export const ExpandablePickerItemList = ({
  items,
  onExpandedPanelOpen,
}: ExpandablePickerItemListProps) => {
  const {
    selectedItems,
    toggleItemSelectionState,
    isMaxUsersLimitReached,
    searchText,
  } = useUsersAndGroupsExpandablePickerContext();

  return (
    <>
      {items.map(item => {
        const { id } = item;
        const isItemSelected = selectedItems.users.get(id) !== undefined;
        if (item.type === UserOrGroupSelectOptionType.User) {
          return (
            <MaxUsersSelectedTooltip
              isItemSelected={isItemSelected}
              key={`user-${id}`}
            >
              <UsersGroupsListItemSelectAndDeselect
                item={item}
                handleClick={toggleItemSelectionState}
                limitReached={isMaxUsersLimitReached}
                selected={isItemSelected}
                searchText={searchText}
              />
            </MaxUsersSelectedTooltip>
          );
        }

        return (
          <ExpandablePickerUserGroupItem
            key={`group-${id}`}
            item={item}
            limitReached={false}
            onPopupOpen={onExpandedPanelOpen}
          />
        );
      })}
    </>
  );
};
