import TooltipString from 'components/TooltipString';
import UserAvatar from 'components/UserAvatar';
import React from 'react';
import { useIntl } from 'react-intl';
import { CONTENT_ID, TABLE_BODY_ID } from 'utils/elementsIds';
import useUserCellStyles from './styles';
import { UserCellProps } from './types';

const UserCell = ({
  user: {
    first_name: firstName,
    last_name: lastName,
    id,
    is_deleted: isDeleted,
  },
}: UserCellProps) => {
  const intl = useIntl();
  const classes = useUserCellStyles({});
  const getPopupContainer = () =>
    document.getElementById(CONTENT_ID) || document.body;

  const displayName = isDeleted
    ? intl.formatMessage(
        {
          id: 'misc.deletedUser',
          defaultMessage: 'Deleted user {id}',
        },
        {
          id,
        }
      )
    : `${firstName} ${lastName}`;

  return (
    <div className={classes.wrapper}>
      <UserAvatar
        id={id}
        firstName={firstName}
        lastName={lastName}
        getPopupContainer={getPopupContainer}
        isDeleted={isDeleted}
        size='small'
        closeOnElementScroll={TABLE_BODY_ID}
      />
      <span className={classes.text}>
        <TooltipString text={displayName} />
      </span>
    </div>
  );
};

export default UserCell;
