import { toast } from 'components/lib/toast';
import GlobalIntlSingleton from 'providers/IntlProviderWrapper/globalIntlSingleton';

export const showTaskCreatedToast = () => {
  toast({
    title: GlobalIntlSingleton.intl.formatMessage({
      id: 'misc.success',
      defaultMessage: 'Success!',
    }),
    subtitle: GlobalIntlSingleton.intl.formatMessage({
      id: 'tasks.taskCreated',
      defaultMessage: 'Task has been created',
    }),
  });
};
