import { mapColumnRatiosToColumnSize } from 'components/formBuilder/formBuilder/Section/SectionPreferences/GeneralSectionParameters/utils';
import useDefaultSchema from 'components/formBuilder/formBuilder/FormBuilderContext/defaultSchemHooks';
import { FormTypes } from 'components/formBuilder/formBuilder/FormBuilder/types';
import { useObjectClassContext } from '../../../contexts/ObjectClassContext';
import { UseInitialSchemasParams } from '../types';
import { NEW_SECTION_1_ID } from 'components/formBuilder/formBuilder/consts';
import { defaultLandingPageSpacing } from 'components/formBuilder/formBuilder/Section/SectionPreferences/GeneralSectionParameters/consts';

export const useInitialSchemas = ({
  uiSchema,
  schema,
}: UseInitialSchemasParams) => {
  const { formType } = useObjectClassContext();
  const {
    DEFAULT_INITIAL_UI_SCHEMA,
    DEFAULT_INITIAL_SCHEMA,
  } = useDefaultSchema();

  const getInitialSchema = () => {
    if (!!schema) return JSON.stringify(schema);

    return JSON.stringify(
      {
        [FormTypes.RecordListing]: {
          ...DEFAULT_INITIAL_SCHEMA,
          properties: {
            ...DEFAULT_INITIAL_SCHEMA.properties,
            [NEW_SECTION_1_ID]: {
              ...(DEFAULT_INITIAL_SCHEMA.properties?.[NEW_SECTION_1_ID] || {}),
              title: '',
              properties: {},
            },
          },
        },
        [FormTypes.RecordView]: DEFAULT_INITIAL_SCHEMA,
        [FormTypes.RecordSummary]: DEFAULT_INITIAL_SCHEMA,
        [FormTypes.TaskTemplates]: DEFAULT_INITIAL_SCHEMA,
        [FormTypes.TaskTemplateWithRecordFields]: DEFAULT_INITIAL_SCHEMA,
        [FormTypes.LandingPage]: DEFAULT_INITIAL_SCHEMA,
      }[formType]
    );
  };

  const getInitialUiSchema = () => {
    if (!!uiSchema) return JSON.stringify(uiSchema);

    const [firstCol, secondCol] = mapColumnRatiosToColumnSize('50:50');
    const parsedUiSchema = uiSchema || DEFAULT_INITIAL_UI_SCHEMA;

    const newColumns = [
      { components: [], columnSize: firstCol },
      {
        components: [],
        columnSize: secondCol,
      },
    ];

    return JSON.stringify(
      {
        [FormTypes.RecordListing]: {
          ...parsedUiSchema,
          [NEW_SECTION_1_ID]: {
            ...parsedUiSchema[NEW_SECTION_1_ID],
            'ui:columns': newColumns,
          },
        },
        [FormTypes.RecordView]: DEFAULT_INITIAL_UI_SCHEMA,
        [FormTypes.RecordSummary]: DEFAULT_INITIAL_UI_SCHEMA,
        [FormTypes.TaskTemplates]: DEFAULT_INITIAL_UI_SCHEMA,
        [FormTypes.TaskTemplateWithRecordFields]: DEFAULT_INITIAL_UI_SCHEMA,
        [FormTypes.LandingPage]: {
          ...parsedUiSchema,
          [NEW_SECTION_1_ID]: {
            ...parsedUiSchema[NEW_SECTION_1_ID],
            'ui:spacingAround': defaultLandingPageSpacing,
            'ui:spacingWithin': defaultLandingPageSpacing,
          },
          'ui:spacingAround': defaultLandingPageSpacing,
          'ui:spacingWithin': defaultLandingPageSpacing,
        },
      }[formType]
    );
  };

  return {
    initialSchema: getInitialSchema(),
    initialUiSchema: getInitialUiSchema(),
  };
};
