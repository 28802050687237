import { DraggingStyle } from 'react-beautiful-dnd';
import { CustomColumnProperties } from '../../Table.types';
import { generateDraggableTransform } from 'utils/functions/generateDraggableTransform';
import clsx from 'clsx';
import {
  TABLE_SORTABLE_TABLE_HEADER_TESTID,
  REMOVE_COLUMN_TESTID,
} from 'utils/testIds';
import TooltipString from 'components/TooltipString';
import SearchInput from 'components/SearchInput';
import Sorter from '../Sorter';
import { ButtonTransparent } from 'components/lib/Button';
import { CloseIcon } from 'components/Icon';
import React from 'react';
import useButtonStyles from 'components/lib/Button/styles';
import { useHeaderCellStyles } from './HeaderCell.styles';
import { HeaderCellProps } from './HeaderCell.types';
import { useTableHeadersContext } from 'components/Table/contexts/TableHeadersContext';

export const HeaderCell = ({
  column,
  provided,
  snapshot,
  tableHeaderRowOffsets,
  indexToAddColumns,
  disabledEdition,
  handleSearchChange,
  preventInteractionWithColumns,
  withoutSort,
  unhidenableColumns,
  addColumnToHidden,
  getItemStyle,
}: HeaderCellProps) => {
  const buttonStyles = useButtonStyles();
  const styles = useHeaderCellStyles();

  const { isEditMode, columnResizing } = useTableHeadersContext();

  const {
    searchKey,
    isSorted,
    isSortedDesc,
  } = column as CustomColumnProperties;

  return (
    <div
      {...(!isEditMode && column.canSort
        ? column.getSortByToggleProps({
            title: undefined,
          })
        : {})}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      ref={provided.innerRef}
      style={{
        ...getItemStyle(snapshot, provided.draggableProps),
        ...(snapshot.isDragging
          ? generateDraggableTransform(
              snapshot.isDragging,
              provided.draggableProps.style as DraggingStyle,
              tableHeaderRowOffsets?.top,
              tableHeaderRowOffsets?.left,
              0
            )
          : {}),
      }}
      className={
        column.canSort && !columnResizing.isResizingColumn
          ? clsx([
              styles.sortableTableHeader,
              {
                disabled: disabledEdition || preventInteractionWithColumns,
              },
            ])
          : ''
      }
      data-testid={`${TABLE_SORTABLE_TABLE_HEADER_TESTID}${column.id}`}
    >
      <span
        className={clsx(styles.ellipsis, {
          [styles.searchWrapper]: !!searchKey,
        })}
      >
        <div
          className={clsx({
            [styles.labelWithSearch]: !!searchKey,
          })}
        >
          <TooltipString text={column.render('Header') as string}>
            {column.render('Header')}
          </TooltipString>
        </div>
        {!!searchKey && (
          <SearchInput
            {...{
              searchKey,
              handleSearchChange,
            }}
          />
        )}
      </span>
      <Sorter
        {...{ isSorted, isSortedDesc }}
        isVisible={!withoutSort && column.canSort && !isEditMode}
      />
      {isEditMode &&
        indexToAddColumns === undefined &&
        !unhidenableColumns.includes(column.id) && (
          <ButtonTransparent
            onClick={() => addColumnToHidden(column.id)}
            className={`${styles.removeColumnButton} ${buttonStyles.buttonTransparent}`}
            data-testid={REMOVE_COLUMN_TESTID}
          >
            <CloseIcon size={18} />
          </ButtonTransparent>
        )}
    </div>
  );
};
