import { CustomCellProps } from 'components/CustomCell/CustomCell.types';
import useDateFormat from 'hooks/useDateFormat';
import moment from 'moment';
import React from 'react';

export const TaskDueDateCell = ({ row }: CustomCellProps) => {
  const { dateFormat } = useDateFormat();

  const { due_date: dueDate } = row;
  const formattedDate = dueDate ? moment(dueDate).format(dateFormat) : null;

  return <>{formattedDate}</>;
};
