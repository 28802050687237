import { createContext, useContext } from 'react';
import { ObjectClassContextType } from './ObjectClassContext.types';
import { FormTypes } from 'components/formBuilder/formBuilder/FormBuilder/types';
import { noop } from 'lodash';
import { FormMode } from 'utils/Enums/FormMode';
import { ObjectClassPermissionsTabs } from '../components/ObjectClassPermissions/types';

export const ObjectClassContext = createContext<ObjectClassContextType>({
  mode: FormMode.View,
  data: undefined,
  readOnly: false,
  options: undefined,
  validators: undefined,
  formType: FormTypes.RecordView,
  setFormType: noop,
  activeKey: undefined,
  setActiveKey: noop,
  activePermissionSetTab: ObjectClassPermissionsTabs.ClassPermissions,
  setActivePermissionSetTab: noop,
  hasUnsavedChangesInCurrentTab: false,
  setHasUnsavedChangesInCurrentTab: noop,
  blockedTabName: undefined,
  setBlockedTabName: noop,
  blockedSubTabName: undefined,
  setBlockedSubTabName: noop,
  name: '',
  setName: noop,
  setInitialValues: noop,
  selectedDisplayTabIndex: undefined,
  setSelectedDisplayTabIndex: noop,
});

export const ObjectClassContextProvider = ObjectClassContext.Provider;
export const useObjectClassContext = () => useContext(ObjectClassContext);