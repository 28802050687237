export const TOAST_AUTO_CLOSE_TIME = 3000;
export const REQUEST_SOURCE_NAME = 'web FE';
export const SIDEBAR_EXPANDED_WIDTH = 273;
export const SIDEBAR_COLLAPSED_WIDTH = 50;
export const FORM_BUILDER_MODAL_WIDTH = 790;
export const FORM_BUILDER_SUPPORTED_SCHEMA_VERSION = 6;
export const MENU_INLINE_INDENT = 27;
export const EMAIL_REGEX = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
export const TIME_REGEX = /\d{2}:\d{2}/;
export const ALL_SPACES_REGEX = /\s/g;
export const URL_REGEX = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,20}\b([-a-zA-Z0-9()@:%_+.~#?&/=]*)/;
export const TRANSFORM_TRANSLATE_REGEX = /(-?[.\d]{1,6})px, (-?[.\d]{1,6})px/;
export const TOP_MENU_HEIGHT = 45;
export const SCROLL_TO_TOP_OPTIONS: ScrollToOptions = {
  top: 0,
  behavior: 'smooth',
};
/*
This regex finds email part of recieved text values
expected values are like "Name Surname (email)"
*/
export const USER_EMAIL_IN_BRACES_REGEX = /\([a-zA-Z0-9+\-_\\.]+@[a-zA-Z0-9]+\.[a-zA-Z0-9]{2,30}\.?[a-zA-Z0-9]{0,30}\.?[a-zA-Z0-9]?\)/;
export const DEFAULT_TIMEZONE = 'Europe/London';
export const PREVIEW_DATE_FORMAT = 'HH:mm, dddd, D MMMM YYYY';

export const OBJECT_NOT_EXISTS_ERROR_REGEX = /Invalid\spk\s"(\d+)"\s-\sobject\sdoes\snot\sexist\./;
export const NO_PERMISSIONS_TO_RESOURCE_ERROR_REGEX = /Invalid\spk\s"(\d+)"\s-\sYou\sdo\snot\shave\spermission\sfor\sthis\s.+\./;

export const FIELD_PREFIX = 'field_';

export const DEFAULT_TABLE_PAGE_SIZES = ['10', '25', '50'];

export const DEFAULT_USER_ASSIGNEES_LIMIT = 100;
export const DEFAULT_GROUP_ASSIGNEES_LIMIT = 10;
