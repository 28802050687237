import { toast } from 'components/lib/toast';
import GlobalIntlSingleton from 'providers/IntlProviderWrapper/globalIntlSingleton';
import ToastType from 'utils/Enums/ToastType';

export const showGroupAssigneesLimitExceededToast = (
  groupsLimit: number | undefined
) => {
  toast(
    {
      title: GlobalIntlSingleton.intl.formatMessage({
        id: 'misc.error',
        defaultMessage: 'Error!',
      }),
      subtitle: GlobalIntlSingleton.intl.formatMessage(
        {
          id: 'misc.maxGroupAssigneesExceeded',
          defaultMessage:
            'Maximum of {limit} user group {limit, plural, one {assignee} other {assignees}} has been exceeded.',
        },
        { limit: groupsLimit }
      ),
    },
    ToastType.Error
  );
};
