import { CustomCellProps } from 'components/CustomCell/CustomCell.types';
import UrlCell from 'components/CustomCell/components/Cells/components/UrlCell';
import React from 'react';

export const PublisherCell = ({ row }: CustomCellProps) => {
  const { publisher, publisher_url } = row ?? {};
  if (publisher_url) {
    return <UrlCell value={publisher_url} label={publisher} />;
  }

  return <>{publisher}</>;
};
