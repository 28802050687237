import { SelectUserOption, SelectUserGroupOption } from '../selectInput.types';

export interface BaseFieldUsage {
  id: number;
  name: string;
}

export interface ObjectClassFieldSequence extends BaseFieldUsage {
  num_of_api_calls: number;
  num_of_message_actors: number;
  num_of_variable_actors: number;
  num_of_set_targets: number;
  num_of_triggers: number;
}

export interface ObjectClassFieldObjectClasses extends BaseFieldUsage {
  num_of_records?: number;
  num_of_records_with_values?: number;
}

export interface ObjectClassCountObjectRecords extends BaseFieldUsage {
  num_of_records?: number;
}

export enum ObjectClassFieldTypes {
  Int = 'int',
  Float = 'float',
  Bool = 'bool',
  Boolean = 'boolean',
  String = 'string',
  Enum = 'enum',
  Set = 'set',
  Email = 'email',
  Phone = 'phone',
  Date = 'date',
  Datetime = 'datetime',
  Time = 'time',
  User = 'user',
  Document = 'document',
  Url = 'url',
  Json = 'json',
  Array = 'array',
}

export interface ObjectClassField {
  id: string;
  type: ObjectClassFieldTypes;
  alias: string;
  label: string;
  is_required: boolean;
  is_unique: boolean;
  is_identifier: boolean;
  order: number;
  default_value?: number | string;
  min_value?: number;
  max_value?: number;
  min_values?: number;
  max_values?: number;
  _meta?: {
    users?: UserMeta[];
    user_groups?: UserGroupMeta[];
  };
}

export type UserMeta = Pick<
  SelectUserOption,
  'id' | 'first_name' | 'last_name' | 'account_type' | 'username'
> & { is_deleted: boolean };

export interface UserGroupMeta extends SelectUserGroupOption {
  is_deleted?: boolean;
  name?: string;
}
export interface ObjectClassFieldUpdated extends ObjectClassField {
  has_duplicates: boolean;
}

export interface ObjectClassFieldReorderData {
  fromId: ObjectClassField['id'];
  toOrder: ObjectClassField['order'];
}

export interface ObjectClassFieldUsage {
  num_of_sequences: number;
  num_of_sequences_with_field: number;
  sequences: ObjectClassFieldSequence[];
  num_of_classes_of_type: number;
  num_of_classes_with_field: number;
  email_collectors: BaseFieldUsage[];
  num_of_dynamic_forms: number;
  num_of_dynamic_forms_with_field: number;
  dynamic_forms: BaseFieldUsage[];
  num_of_truth_tables: number;
  num_of_truth_tables_with_field: number;
  truth_tables: BaseFieldUsage[];
  num_of_object_record_imports: number;
  num_of_object_record_imports_with_field: number;
  object_record_imports: BaseFieldUsage[];
  num_of_message_templates: number;
  num_of_message_templates_with_field: number;
  message_templates: BaseFieldUsage[];
  num_of_document_templates: number;
  num_of_document_templates_with_field: number;
  document_templates: BaseFieldUsage[];
  num_of_records: number;
  num_of_records_with_values: number;
  task_template_forms: BaseFieldUsage[];
}
