import { toast } from 'components/lib/toast';
import { StatusCodes } from 'http-status-codes';
import { useIntl } from 'react-intl';
import { apiCall } from 'utils/api';
import {
  OBJECT_RECORD_CHILDREN_BATCH,
  OBJECT_RECORD_LIST,
} from 'utils/endpoints';
import ToastType from 'utils/Enums/ToastType';
import { RefObject, useEffect, useState } from 'react';
import { FormDataValue } from 'components/FormPreview/types';
import showDefaultErrorToast from 'utils/functions/showDefaultErrorToast';
import useData from 'hooks/useData';
import { GetResponse } from 'utils/types';
import { ObjectRecordDetails } from 'utils/types/api/objectRecords.types';
import { FormPreview2RefProps } from 'components/FormPreview2/types';
import { CustomFileItem } from 'components/lib/FileUpload/types';
import { getFilesTokens } from 'components/FormPreview2/utils';
import { useDispatch, useSelector } from 'react-redux';
import { objectRecordsPermissionListSelector } from 'store/selectors/permissionsSelectors';
import { getPermissions } from 'store/actions/permissionsActions';
import {
  getObjectRecordsError,
  getObjectRecordsLimitItems,
  getObjectRecordsLoading,
} from 'store/selectors/objectRecordsSelectors';
import { generatePath } from 'react-router-dom';
import { DEFAULT_OBJECT_MODEL_ID } from 'components/Table/Table.consts';
import { replaceTreeNodeWithPop } from 'store/actions/nestedObjectRecordsActions';
import {
  nestedRecordByClassIdComparisonFunc,
  selectNoRelationshipCreatedChildren,
  selectTopStackParent,
} from 'store/selectors/nestedObjectRecordsSelctors';
import { useSelectedResourceContext } from 'contexts/SelectedResourceContext';

export const useCreateRecordMaximumLimitExceeded = (classId: string) => {
  const loading = useSelector(getObjectRecordsLoading);
  const limit = useSelector(getObjectRecordsLimitItems);
  const error = useSelector(getObjectRecordsError);
  const [
    recordsData,
    { loading: recordsLoading, error: recordsError, fetchData },
  ] = useData<GetResponse<ObjectRecordDetails>>(
    `${OBJECT_RECORD_LIST}?object_class=${classId}`,
    {
      fetchOnLoad: false,
    }
  );

  const hasListPermission = useSelector(objectRecordsPermissionListSelector);

  useEffect(() => {
    if (hasListPermission) fetchData();
  }, [hasListPermission, fetchData]);

  return {
    loading: loading || recordsLoading,
    error: error || recordsError,
    isLimitExceeded:
      recordsData?.filtered_count !== undefined &&
      limit !== undefined &&
      recordsData.filtered_count >= limit,
  };
};

const useCreateRecordForm = (
  classId: string,
  formRef: RefObject<FormPreview2RefProps>,
  openModal: () => void
) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const {
    setSelectedResource,
    setAdditionalSelectedResource,
  } = useSelectedResourceContext();

  const [isSaving, setIsSaving] = useState(false);
  const [errors, setErrors] = useState<MappedObject<string[]> | undefined>();
  const childrenToAdd = useSelector(
    selectNoRelationshipCreatedChildren(classId),
    nestedRecordByClassIdComparisonFunc
  );
  const parentRecord = useSelector(selectTopStackParent);

  useEffect(() => {
    // we want to close right side panel when we open form in create record mode.
    setSelectedResource(undefined);
  }, [setSelectedResource]);

  const onSubmit = async (
    formData: MappedObject<FormDataValue>,
    filesData: MappedObject<CustomFileItem[]>
  ) => {
    setIsSaving(true);
    setErrors(undefined);
    const parsedData = {
      ...formData,
      object_class: classId,
    };

    const parseFilesFields = getFilesTokens(filesData);

    try {
      const result = await apiCall.post(OBJECT_RECORD_LIST, {
        ...parsedData,
        ...parseFilesFields,
      });

      if (childrenToAdd?.length) {
        //assign children to newly created parent
        await apiCall.post(
          generatePath(OBJECT_RECORD_CHILDREN_BATCH, {
            id: DEFAULT_OBJECT_MODEL_ID,
          }),
          {
            parent: result.data.id,
            object_records: childrenToAdd,
          }
        );
      }

      if (result.status !== StatusCodes.CREATED) {
        showDefaultErrorToast();

        return false;
      }

      if (formRef.current !== null) formRef.current.resetForm(true);

      toast(
        {
          title: intl.formatMessage({
            id: 'misc.success',
            defaultMessage: 'Success',
          }),
          subtitle: intl.formatMessage({
            id: 'objectRecords.recordCreated',
            defaultMessage: 'Record has been created.',
          }),
        },
        ToastType.Success
      );

      if (parentRecord?.isCreated) {
        //assign newly created record to it's parent on the backend - on the frontend it is represented in the tree
        await apiCall.post(
          generatePath(OBJECT_RECORD_CHILDREN_BATCH, {
            id: DEFAULT_OBJECT_MODEL_ID,
          }),
          {
            parent: parentRecord.recordId,
            object_records: [result.data.id],
          }
        );
      }

      if (parentRecord) {
        setAdditionalSelectedResource({
          record: {
            recordId: result.data.id.toString(),
            permissions: result.data._meta.permissions,
            identifier: result.data.identifier,
            isSummaryPanelEnabled: true,
          },
          objectClassId: result.data.object_class.toString(),
        });

        dispatch(
          //update tree with newly created record
          replaceTreeNodeWithPop({
            isCreated: true,
            recordId: result.data.id.toString(),
            nodeId: result.data.id.toString(),
            classId: result.data.object_class.toString(),
            isRelationshipCreated: !!parentRecord?.isCreated,
          })
        );
      }

      if (!parentRecord) {
        //clear panel and children data if this is a root record (after creation everything should be fresh new)
        setAdditionalSelectedResource(undefined);
        dispatch(
          replaceTreeNodeWithPop({
            isCreated: true,
            recordId: result.data.id.toString(),
            nodeId: result.data.id.toString(),
            classId: result.data.object_class.toString(),
            isRelationshipCreated: true,
            children: undefined,
          })
        );
      }

      dispatch(getPermissions());

      return true;
    } catch (error) {
      const data = error?.response?.data || {};
      const errors = data as MappedObject<string[]>;

      setErrors(errors);

      if (
        errors &&
        Object.values(errors).some(item =>
          item.includes('This field must be unique.')
        )
      ) {
        openModal();
      } else if (errors) {
        showDefaultErrorToast();
      }

      return false;
    } finally {
      setIsSaving(false);
    }
  };

  return { onSubmit, isSaving, errors };
};

export default useCreateRecordForm;
