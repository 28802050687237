import { useEffect, useCallback, useState } from 'react';
import { FIELD_PREFIX } from 'utils/consts';
import { useDispatch, useSelector } from 'react-redux';
import { setObjectRecordsSelectedColumns } from 'store/actions/objectRecordsActions';
import { getCurrentTable } from 'store/selectors/filtersSelectors';
import { getObjectRecordsSelectedColumns } from 'store/selectors/objectRecordsSelectors';
import { getSelectedObjectClassId } from 'store/selectors/preferencesSelectors';
import TablesType from 'utils/Enums/TablesType';
import { UseAddColumnsParams } from '../../../../../hooks/types';
import showDefaultErrorToast from 'utils/functions/showDefaultErrorToast';
import { apiCall } from 'utils/api';
import { generatePath } from 'react-router-dom';
import { OBJECT_CLASS_DETAILS_FIELDS } from 'utils/endpoints';
import { ClassFieldApiResponse } from 'components/AddColumn/types';

export const useAddColumns = ({
  setHiddenColumns,
  hiddenColumns,
  visibleColumns,
  setColumnOrder,
}: UseAddColumnsParams) => {
  const [indexToAddColumns, setIndexToAddColumns] = useState<
    number | undefined
  >();
  const dispatch = useDispatch();
  const selectedClassId = useSelector(getSelectedObjectClassId);
  const currentTableName = useSelector(getCurrentTable);
  const selectedColumns = useSelector(getObjectRecordsSelectedColumns);

  const [totalFields, setTotalFields] = useState(-1);

  //get number of fields for the object class
  useEffect(() => {
    (async () => {
      if (totalFields === -1 && currentTableName === TablesType.ObjectRecords) {
        // fetch the total number of object class fields
        try {
          const { data, status } = await apiCall.get<ClassFieldApiResponse>(
            generatePath(OBJECT_CLASS_DETAILS_FIELDS, { id: selectedClassId }),
            {
              params: {
                offset: 0,
                limit: 0,
              },
            }
          );
          if (status === 200) {
            setTotalFields(data.total_count);
          }
        } catch (error) {
          showDefaultErrorToast();
        }
      }
    })();
  }, [totalFields, currentTableName, selectedClassId, hiddenColumns]);

  const shouldBeVisible = useCallback(
    (index: number) => {
      if (currentTableName !== TablesType.ObjectRecords) {
        //in ObjectRecords there are additional fields, which are coming from the API, and have to be handled differently than normal properties
        if ((hiddenColumns?.length ?? 0) > 0) {
          return true;
        }
        return !!(indexToAddColumns && indexToAddColumns === index);
      }

      if (selectedColumns?.length === totalFields || !hiddenColumns?.length) {
        return false;
      }
      if (indexToAddColumns !== undefined) {
        return indexToAddColumns === index;
      }
      return true;
    },
    [
      totalFields,
      selectedColumns,
      hiddenColumns,
      indexToAddColumns,
      currentTableName,
    ]
  );

  const onTogglePopover = useCallback((index: number | undefined) => {
    setIndexToAddColumns(index);
  }, []);

  const addColumnToHidden = useCallback(
    (name: string) => {
      if (!name.startsWith(FIELD_PREFIX)) {
        setHiddenColumns(hiddenColumns ? [...hiddenColumns, name] : [name]);
      }

      if (selectedClassId && currentTableName === TablesType.ObjectRecords) {
        dispatch(
          setObjectRecordsSelectedColumns({
            selectedClassId,
            selectedColumns:
              selectedColumns?.filter(col => col.alias !== name) ?? [],
          })
        );
      }
      setColumnOrder(prev => prev.filter(value => value !== name));
    },
    [
      setHiddenColumns,
      hiddenColumns,
      selectedClassId,
      currentTableName,
      dispatch,
      selectedColumns,
      setColumnOrder,
    ]
  );

  const onAddNewColumns = useCallback(
    (columnsToAdd: string[], destinationIndex: number) => {
      const colOrder = visibleColumns.map(column => column.id);
      columnsToAdd.forEach((col, index) =>
        colOrder.splice(destinationIndex + 1 + index, 0, col)
      );

      if (hiddenColumns) {
        const newHiddenColumns = hiddenColumns.filter(
          col =>
            !columnsToAdd
              .filter(colToAdd => !colToAdd.startsWith('field'))
              .includes(col)
        );

        setColumnOrder(colOrder);
        setHiddenColumns(newHiddenColumns);
      }
      // setting to undefined - if the current column is deleted and indexToAddColumns still points to it, the addCols + signs do not show
      setIndexToAddColumns(undefined);
    },
    [hiddenColumns, setColumnOrder, setHiddenColumns, visibleColumns]
  );

  return {
    onAddNewColumns,
    addColumnToHidden,
    onTogglePopover,
    shouldBeVisible,
    indexToAddColumns,
  };
};
