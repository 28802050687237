import { toast } from 'components/lib/toast';
import GlobalIntlSingleton from 'providers/IntlProviderWrapper/globalIntlSingleton';
import ToastType from 'utils/Enums/ToastType';

export const showUsersSuccessfulyAssignedToast = (
  resourceName: string,
  assignedUsersCount: number
) => {
  toast(
    {
      title: GlobalIntlSingleton.intl.formatMessage({
        id: 'misc.success',
        defaultMessage: 'Success!',
      }),
      subtitle: GlobalIntlSingleton.intl.formatMessage(
        {
          id: 'misc.successfulyAssigedUsersTo',
          defaultMessage:
            '{count, plural, one {# user has} other {# users have}} been assigned to {name}.',
        },
        { count: assignedUsersCount, name: resourceName }
      ),
    },
    ToastType.Success
  );
};
