import clsx from 'clsx';
import BoldedPartOfText from 'components/CustomCell/components/Cells/components/BoldedPartOfText';
import { CustomCellProps } from 'components/CustomCell/CustomCell.types';
import CustomTag from 'components/CustomTag';
import { useTableContext } from 'contexts/TableContext';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useObjectClassFieldLabelCellStyles } from './ObjectClassFieldLabelCell.styles';

export const ObjectClassFieldLabelCell = ({ row }: CustomCellProps) => {
  const { searchValue } = useTableContext();
  const styles = useObjectClassFieldLabelCellStyles();

  const { label = '', is_identifier: isIdentifier = false } = row;

  return (
    <>
      <BoldedPartOfText value={label} boldPhrase={searchValue?.value} />
      {isIdentifier && (
        <CustomTag className={clsx([styles.tag, styles.identifier])}>
          <FormattedMessage id='enums.identifier' defaultMessage='Identifier' />
        </CustomTag>
      )}
    </>
  );
};
