import { createUseStyles } from 'react-jss';

const useTreeOfStringsDisplayStyles = createUseStyles({
  list: {
    paddingLeft: '1.375rem',
  },
  listItem: {
    listStyleType: 'disc',
  },
  listItemStyle: ({ depth }: { depth: number }) => {
    if (depth > 0) {
      return { fontStyle: 'italic' };
    } else {
      return {};
    }
  },
});

export default useTreeOfStringsDisplayStyles;
