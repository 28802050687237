import { FormValue } from 'alx-dynamic-form';
import { UsersAndGroupsFormValue } from 'components/FormPreview2/widgets/inPlaceEdit/AdaptedInPlaceEditUser/AdaptedInPlaceEditUser.types';
import {
  useUserFieldMinErrorMessages,
  useValidateFulfillment,
} from 'components/UsersAndGroupsSelection/hooks';
import { useRevalidate } from 'hooks/useRevalidate';
import { useCallback } from 'react';
import {
  SelectUserAndGroupOption,
  UsersAndGroupsSelectLimits,
} from 'utils/types/selectInput.types';

export const useCustomUserWidgetValidation = (
  formValue: UsersAndGroupsFormValue | null | undefined,
  fieldLabel: string,
  errors: string[],
  required: boolean,
  limits: UsersAndGroupsSelectLimits,
  formValidationFunction: () => void
) => {
  const { minGroupsError, minUsersError } = useUserFieldMinErrorMessages(
    fieldLabel,
    limits.selectionCountLimits?.minUsers,
    limits.selectionCountLimits?.minGroups
  );

  const {
    tooltip,
    fulfillmentErrors,
    validateFulfillment,
  } = useValidateFulfillment(false, minUsersError);

  const { scheduleRevalidation } = useRevalidate(
    formValue as FormValue,
    formValidationFunction
  );

  const validateFulfillmentPossibility = useCallback(
    (options: SelectUserAndGroupOption) =>
      validateFulfillment({
        availableUsersAmount: options.users.length,
        availableGroupsAmount: options.groups.length,
        isRequired: required,
        limits,
      }),
    [limits, required, validateFulfillment]
  );

  return {
    validateFulfillmentPossibility,
    scheduleRevalidation,
    minUsersError,
    minGroupsError,
    tooltip,
    errors: [...fulfillmentErrors, ...errors],
    shouldDisableDueToValidation: fulfillmentErrors.length > 0,
  };
};
