import useData from "hooks/useData";
import { generatePath } from "react-router-dom";
import { OBJECT_CLASS_FIELD_USAGE } from "utils/endpoints";
import { ObjectClassFieldUsage } from "utils/types/api/objectClassesFields.types";

export const useObjectClassFieldUsageData = (id?: number, fieldId?: number) => {
    const [data, { error, loading, fetchData }] = useData<ObjectClassFieldUsage>(
        id !== undefined && fieldId !== undefined
            ? generatePath(OBJECT_CLASS_FIELD_USAGE, {
                id,
                fieldId,
            })
            : '',
        {
            fetchOnLoad: false,
        }
    );

    return { error, loading, fetchData, data };
};