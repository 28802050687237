import { useMemo } from "react";
import { useIntl } from "react-intl";
import ErrorIllustration from 'img/illustrations/error.svg';

export const useNoExistsFieldModal = (fieldLabel: string) => {
    const intl = useIntl();

    const noExistsFieldModalProps = useMemo(
        () => ({
            confirmationButtonLabel: intl.formatMessage({
                id: 'misc.ok',
                defaultMessage: 'Ok',
            }),
            image: ErrorIllustration,
            title: intl.formatMessage(
                {
                    id: 'misc.fieldNoLongerExists',
                    defaultMessage: 'Field "{fieldLabel}" no longer exists',
                },
                {
                    fieldLabel,
                }
            ),
            subtitle: intl.formatMessage({
                id: 'misc.yourChangesCannotBeSaved',
                defaultMessage: 'Your changes cannot be saved.',
            }),
        }),
        [intl, fieldLabel]
    );

    return {
        noExistsFieldModalProps,
    };
};