import { UsersAndGroupsFormValue } from 'components/FormPreview2/widgets/inPlaceEdit/AdaptedInPlaceEditUser/AdaptedInPlaceEditUser.types';
import { useCancelToken } from 'hooks/useCancelToken';
import { useState } from 'react';
import { patchUserFieldUsersAndGroups } from '../utils';
import axios from 'axios';
import { ObjectRecordDetails } from 'utils/types/api/objectRecords.types';

export const usePatchObjectRecordUserField = (
  patchUrl: string | undefined,
  objectClassId: number | string | undefined,
  objectClassFieldAlias: string
) => {
  const { createCancelToken, cancelOngoingRequests } = useCancelToken();

  const [isSaving, setIsSaving] = useState(false);
  const [errors, setErrors] = useState<string[]>([]);

  const patchUserField = async (
    patchData: UsersAndGroupsFormValue | null | undefined,
    onPatchSuccess: (data: ObjectRecordDetails) => void
  ) => {
    if (!patchUrl || !objectClassId) {
      return;
    }

    try {
      setIsSaving(true);
      setErrors([]);

      cancelOngoingRequests();
      const cancelToken = createCancelToken();

      const result = await patchUserFieldUsersAndGroups(
        patchUrl,
        objectClassId,
        objectClassFieldAlias,
        patchData ?? null,
        cancelToken
      );

      setIsSaving(false);
      onPatchSuccess(result.data);
    } catch (error) {
      if (axios.isCancel(error)) {
        return;
      }

      if (!axios.isAxiosError(error)) {
        throw error;
      }

      setErrors([error.response?.data[objectClassFieldAlias]]);
      setIsSaving(false);
    }
  };

  return { isSaving, errors, patchUserField };
};
