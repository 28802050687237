import { useFormikContext } from "formik";
import { ClassFieldFormFields } from "pages/ObjectClasses/enums";
import { useMemo, useEffect } from "react";
import { ObjectClassFieldTypes } from "utils/types/api/objectClassesFields.types";
import { ClassFieldForm } from "../../../../../types";

export const useGroupMinMaxDisplay = () => {
    const {
        values: { user_groups: userGroups = [], allow_sync: allowSync, type },
        setFieldValue,
    } = useFormikContext<ClassFieldForm>();
    const showGroupsMinMax = useMemo(() => {
        if (
            type !== ObjectClassFieldTypes.User ||
            !userGroups.length ||
            !allowSync
        ) {
            return false;
        }

        return true;
    }, [userGroups, allowSync, type]);

    useEffect(() => {
        if (type !== ObjectClassFieldTypes.User) {
            return;
        }
        if (!showGroupsMinMax) {
            setFieldValue(ClassFieldFormFields.MinGroupsValues, undefined);
            setFieldValue(ClassFieldFormFields.MaxGroupsValues, undefined);
        }
    }, [showGroupsMinMax, setFieldValue, type]);
    return showGroupsMinMax;
};