import CommaSeparatedString from 'components/CustomCell/components/Cells/components/CommaSeparatedString';
import { CustomCellProps } from 'components/CustomCell/CustomCell.types';
import React from 'react';
import CustomCells from 'utils/Enums/CustomCells';

export const TaskGroupsCell = ({ row }: CustomCellProps) => {
  const { task_group_templates = [] } = row;

  return (
    <CommaSeparatedString
      values={task_group_templates}
      highlightedColumnKey={CustomCells.TaskGroups}
    />
  );
};
