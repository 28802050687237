import { useCallback, useState } from 'react';
import { useUserValidationMessages } from './hooks';
import { FulfillmentValidationParameters } from './types';

/**
 * Validates if user type field is possible to fulfill based on it's constraints like min/max values etc. based on received
 * users and groups.
 */
export const useValidateFulfillment = (
  isEditMode: boolean,
  minUsersError: string
) => {
  const [tooltip, setTooltip] = useState('');
  const [fulfillmentErrors, setFulfillmentErrors] = useState<string[]>([]);

  const {
    noUsersToSelect,
    noUsersToSelectError,
    notEnoughUsersToSelect,
    notEnoughUsersToSelectError,
  } = useUserValidationMessages();

  const validateCollection = useCallback(
    (
      {
        availableUsersAmount,
        availableGroupsAmount,
        isRequired = false,
        limits,
      }: FulfillmentValidationParameters,
      noElementsToSelectMessage: string,
      noElementsToSelectError: string,
      notEnoughElementsToSelectMessage: string,
      notEnoughElementsToSelectError: string
    ) => {
      const minUsers = limits.selectionCountLimits?.minUsers ?? 0;

      // If I'll no longer be available in the project and this mess below will somewhat still stay here just know this:
      // We were supposed to properly validate if a user type field can be fulfilled on the backend. It should
      // return the information about users or groups fulfillment in the _meta of the response as simple bools.
      // If that didn't happen yet, ask the backend team to do it - validating this on the frontend would require a multitude
      // of API calls to fetch each individual group members and deduplicating them just to find out how many users are available
      // while backend only returns 50 group members maximum at once.

      // Business decision: Until the final validation from backend won't be finished.
      // We pass the validation if individual members are allowed to be selected and there is any group available.
      const individualMembersOverrideValid =
        limits.isAllowedToSelectIndividualMembers && availableGroupsAmount > 0;

      const minUsersValid =
        availableUsersAmount >= minUsers || individualMembersOverrideValid;

      // This is another temporary edge case that needs to allow fulfillment passing (until the final validation gets done).
      // When there is a possibility of selecting a group but there are no users or members selection allowed
      // We still pass the isRequired constraint since a group can be selected.
      const canFillFieldRequiredWithGroupSync =
        availableGroupsAmount > 0 && limits.isAllowedToSyncGroupMembers;

      const isRequiredValid =
        !isRequired ||
        availableUsersAmount > 0 ||
        individualMembersOverrideValid ||
        canFillFieldRequiredWithGroupSync;

      /* 
      There are a few cases to consider here.
      1. When the field is required only, we need to check if any user can be chosen. If not, we display both tooltip
      and error.
      2. When the field is not required, but it has min values required that are not met. We display only toolip as the field
      can still be submitted as empty.
      3. When the field is both required and has min values required, we display the tooltip and error if either of the requirements
      is not met. The minimum requirement won't allow us to set any value for the field to fulfill the required so it has to error out.
      */
      if (minUsersValid && isRequiredValid) {
        return;
      }

      const message =
        availableUsersAmount === 0
          ? noElementsToSelectMessage
          : notEnoughElementsToSelectMessage;

      setTooltip(message);

      /* 
      This is stupid. We want to display just a tooltip in create record mode to allow empty field submission but we do not allow editing
      the field to empty it in edit mode. Why was it like this in previous version? Hell if I know...
      */
      if (!isRequired && !isEditMode) {
        return;
      }

      const error =
        availableUsersAmount === 0
          ? noElementsToSelectError
          : notEnoughElementsToSelectError;

      setFulfillmentErrors([isEditMode ? `${minUsersError}. ${error}` : error]);
    },
    [isEditMode, minUsersError]
  );

  const validateFulfillment = useCallback(
    (usersRequirements: FulfillmentValidationParameters) => {
      validateCollection(
        usersRequirements,
        noUsersToSelect,
        noUsersToSelectError,
        notEnoughUsersToSelect,
        notEnoughUsersToSelectError
      );
    },
    [
      noUsersToSelect,
      noUsersToSelectError,
      notEnoughUsersToSelect,
      notEnoughUsersToSelectError,
      validateCollection,
    ]
  );

  return { validateFulfillment, tooltip, fulfillmentErrors };
};
