import React from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { useFilterEditor } from './hooks';
import { useFiltersPanelStyles } from './FiltersPanel.styles';
import useAppliedFilters from 'hooks/useAppliedFilters';
import LoaderOverlay from 'components/LoaderOverlay';
import FlexContentWrapper from 'components/wrappers/FlexContentWrapper';
import { FILTERS_WRAPPER, SCROLLABLE_CONTAINER } from 'utils/elementsIds';
import NoDataFoundMessage from 'components/NoDataFoundMessage';
import { ButtonPrimary, ButtonOutlined } from 'components/lib/Button';
import { TrashIcon } from 'components/Icon';
import { REMOVE_ALL_FILTERS_TESTID, APPLY_FILTERS_TESTID } from 'utils/testIds';
import ConfirmationModal from 'components/ConfirmationModal';
import TrashIllustration from 'img/illustrations/trash-illustration.svg';
import { CheckOutlined } from '@ant-design/icons';
import { FilterItem } from './components/FilterItem';
import { ColumnSelectPure } from './components/ColumnSelect';
import { AddFilterButton } from './components/AddFilterButton';
import { useIsCurrentTableFilterable } from 'hooks/useIsCurrentTableFilterable';
import { useValidateCurrentTableFilters } from 'hooks/useValidateCurrentTableFilters';

export const FiltersPanel = () => {
  const intl = useIntl();
  const styles = useFiltersPanelStyles();

  const appliedFilters = useAppliedFilters();
  const { isFilterable } = useIsCurrentTableFilterable();
  const { areFiltersValid } = useValidateCurrentTableFilters(true);
  const {
    filters,
    onApplyFilters,
    onCreateFilter,
    filterableColumns,
    onClearFilter,
    usedColumns,
    showClearFilterModal,
    setShowClearFilterModal,
    isFiltersLimitReached,
    onRemoveFilter,
    onClickClearFilters,
    currentTable,
    tmpPredicateSet,
    onPredicateSetChange,
  } = useFilterEditor();

  if (!currentTable || Object.keys(filterableColumns).length === 0) {
    return <LoaderOverlay />;
  }

  if (!isFilterable) {
    return (
      <FlexContentWrapper id={FILTERS_WRAPPER}>
        <NoDataFoundMessage
          title={intl.formatMessage({
            id: 'misc.cannotAddFilters',
            defaultMessage: 'You cannot add any filters',
          })}
          subtitle={intl.formatMessage({
            id: 'misc.noDataToFilter',
            defaultMessage:
              'There is no data on this page that can be filtered',
          })}
        />
      </FlexContentWrapper>
    );
  }

  return (
    <div id={FILTERS_WRAPPER} className={styles.panelWrapper}>
      {!isFiltersLimitReached && <AddFilterButton onClick={onCreateFilter} />}

      <div id={SCROLLABLE_CONTAINER} className={styles.filtersWrapper}>
        {filters.length === 0 && (
          <div className={styles.singleFilter}>
            <label>
              <FormattedMessage id='misc.field' defaultMessage='Field' />
            </label>

            <ColumnSelectPure
              columns={filterableColumns}
              predicateSet={tmpPredicateSet}
              onChange={onCreateFilter}
              onPredicateSetChange={onPredicateSetChange}
            />
          </div>
        )}

        {filters.map(filter => (
          <FilterItem
            key={filter.column}
            columns={filterableColumns}
            filter={filter}
            usedColumns={usedColumns}
            onRemoveFilter={onRemoveFilter}
          />
        ))}
      </div>

      <footer className={styles.footer}>
        {filters.length > 0 && (
          <ButtonOutlined
            icon={<TrashIcon size={14} />}
            data-testid={REMOVE_ALL_FILTERS_TESTID}
            onClick={onClickClearFilters}
          >
            <FormattedMessage id='misc.removeAll' defaultMessage='Remove all' />
          </ButtonOutlined>
        )}

        <ButtonPrimary
          icon={<CheckOutlined size={15} />}
          disabled={!areFiltersValid()}
          data-testid={APPLY_FILTERS_TESTID}
          onClick={onApplyFilters}
        >
          <FormattedMessage
            id='misc.applyFilter'
            defaultMessage='Apply filter'
          />
        </ButtonPrimary>
      </footer>

      <ConfirmationModal
        onConfirm={onClearFilter}
        onCancel={() => setShowClearFilterModal(false)}
        visible={showClearFilterModal}
        title={intl.formatMessage({
          id: 'misc.doYouWantToRemoveAllConditions',
          defaultMessage: 'Do you want to remove all conditions?',
        })}
        subtitle={
          appliedFilters
            ? intl.formatMessage({
                id: 'misc.filterWillBeReset',
                defaultMessage:
                  'The filter will become empty. This action cannot be undone.',
              })
            : intl.formatMessage({
                id: 'misc.filterWillBecomeEmpty',
                defaultMessage: 'The filter will become empty.',
              })
        }
        cancelButtonLabel={intl.formatMessage({
          id: 'misc.cancel',
          defaultMessage: 'Cancel',
        })}
        confirmationButtonLabel={intl.formatMessage({
          id: 'misc.remove',
          defaultMessage: 'Remove',
        })}
        image={TrashIllustration}
        isDangerAction
        confirmationButtonIcon={<TrashIcon size={12} />}
      />
    </div>
  );
};
