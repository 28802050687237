import axios from 'axios';
import { useTasksPanelContext } from 'components/SidePanels/TasksPanel/contexts/TasksPanelContext';
import { useCancelToken } from 'hooks/useCancelToken';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { generatePath } from 'react-router-dom';
import { getUserDetails } from 'store/selectors/authSelectors';
import { apiCall } from 'utils/api';
import { TASK_USER_ASSIGNEES } from 'utils/endpoints';
import errorToast from 'utils/functions/errorToast';
import { AssigneeToDelete } from 'utils/types/assigneeToDelete';

/**
 * Handles logic responsible for unassigning a user from a task.
 */
export const useUnassignTaskUserAssignee = () => {
  const userId = useSelector(getUserDetails)?.id;

  const { task, refetchTaskDetails } = useTasksPanelContext();
  const { createCancelToken, cancelOngoingRequests } = useCancelToken();

  const [userToUnassign, setUserToUnassign] = useState<AssigneeToDelete>();
  const [isLoading, setIsLoading] = useState(false);

  const isUnassigningHimself =
    userId?.toString() === userToUnassign?.id?.toString();

  const cancelUnassigning = () => {
    cancelOngoingRequests();
    setUserToUnassign(undefined);
    setIsLoading(false);
  };

  const unassignUserFromTask = async () => {
    if (!userToUnassign) {
      return;
    }

    try {
      cancelOngoingRequests();
      setIsLoading(true);

      const cancelToken = createCancelToken();

      const endpoint = generatePath(TASK_USER_ASSIGNEES, {
        id: task?.id,
      });

      await apiCall.delete(endpoint, {
        data: [userToUnassign.id],
        cancelToken,
      });

      refetchTaskDetails();
      setUserToUnassign(undefined);
      setIsLoading(false);
    } catch (error) {
      if (axios.isCancel(error)) {
        return;
      }

      errorToast();
      setIsLoading(false);
    }
  };

  return {
    userToUnassign,
    isUnassigningHimself,
    isUnassigning: isLoading,
    cancelUnassigning,
    setUserToUnassign,
    unassignUserFromTask,
  };
};
