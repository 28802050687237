import CustomCells from 'utils/Enums/CustomCells';
import TablesType from 'utils/Enums/TablesType';
import { TableCellMapping } from './CustomCell.types';
import { ApiActorCell } from './components/Cells/ApiActorCell';
import { CheckIconCell } from './components/Cells/CheckIconCell';
import { ClassFormEnabledCell } from './components/Cells/ClassFormEnabledCell';
import { MembershipCell } from './components/Cells/MembershipCell';
import { ObjectClassFieldLabelCell } from './components/Cells/ObjectClassFieldLabelCell';
import { ObjectClassNameCell } from './components/Cells/ObjectClassNameCell';
import { ObjectClassOwnersCell } from './components/Cells/ObjectClassOwnersCell';
import { ObjectClassRelationsCell } from './components/Cells/ObjectClassRelationsCell';
import { ObjectNameCell } from './components/Cells/ObjectNameCell';
import { ObjectRecordCell } from './components/Cells/ObjectRecordCell';
import { ObjectRecordGroupsCell } from './components/Cells/ObjectRecordGroupsCell';
import { PublisherCell } from './components/Cells/PublisherCell';
import { StateCell } from './components/Cells/StateCell';
import { TaskDueDateCell } from './components/Cells/TaskDueDateCell';
import { TaskGroupsCell } from './components/Cells/TaskGroupsCell';
import { TaskStatusCell } from './components/Cells/TaskStatusCell';
import { TaskTemplateStatusCell } from './components/Cells/TaskTemplateStatusCell';
import { TemplateFileCell } from './components/Cells/TemplateFileCell';
import { TemplateStatusCell } from './components/Cells/TemplateStatusCell';
import { UserCustomCell } from './components/Cells/UserCustomCell';
import { UserRolesCell } from './components/Cells/UserRolesCell';
import { UuidCell } from './components/Cells/UuidCell';

// Creates a mapping of table and column combination to the destination cell component.
export const TABLE_CELL_COMPONENT_MAPPING: TableCellMapping[] = [
  {
    tables: [TablesType.Members],
    columns: [CustomCells.Membership],
    component: MembershipCell,
  },
  {
    tables: [TablesType.TaskTemplates],
    columns: [CustomCells.Status],
    component: TaskTemplateStatusCell,
  },
  {
    tables: [TablesType.Tasks],
    columns: [CustomCells.Status],
    component: TaskStatusCell,
  },
  {
    tables: [TablesType.Tasks],
    columns: [CustomCells.DueDate],
    component: TaskDueDateCell,
  },
  {
    tables: [TablesType.ObjectClassesFields],
    columns: [CustomCells.Label],
    component: ObjectClassFieldLabelCell,
  },
  {
    tables: [
      TablesType.ObjectClassesFields,
      TablesType.SingleSignOn,
      TablesType.CustomComponents,
      TablesType.DocumentTemplates,
    ],
    columns: [
      CustomCells.Required,
      CustomCells.Unique,
      CustomCells.AutoCreateUse,
      CustomCells.Enabled,
      CustomCells.AddInEnabled,
      CustomCells.ManualDocumentGeneration,
    ],
    component: CheckIconCell,
  },
  {
    tables: [TablesType.TaskTemplates, TablesType.Sequences],
    columns: [CustomCells.TaskGroups],
    component: TaskGroupsCell,
  },
  {
    tables: [
      TablesType.TaskGroups,
      TablesType.Roles,
      TablesType.EmailCollectors,
    ],
    columns: [CustomCells.ObjectRecordGroups],
    component: ObjectRecordGroupsCell,
  },
  {
    tables: [TablesType.Sequences],
    columns: [CustomCells.State],
    component: StateCell,
  },
  {
    tables: [TablesType.Sequences],
    columns: [CustomCells.APIActorsNames],
    component: ApiActorCell,
  },
  {
    tables: [TablesType.ObjectClasses],
    columns: [CustomCells.Relations],
    component: ObjectClassRelationsCell,
  },
  {
    tables: [TablesType.ObjectClasses],
    columns: [CustomCells.Owners],
    component: ObjectClassOwnersCell,
  },
  {
    tables: [TablesType.Users],
    columns: [CustomCells.Roles],
    component: UserRolesCell,
  },
  {
    tables: [TablesType.Members],
    columns: [CustomCells.Name],
    component: UserCustomCell,
  },
  {
    tables: [],
    columns: [CustomCells.ObjectClass],
    component: ObjectClassNameCell,
  },
  {
    tables: [],
    columns: [CustomCells.ObjectRecord],
    component: ObjectRecordCell,
  },
  {
    tables: [],
    columns: [CustomCells.ObjectName],
    component: ObjectNameCell,
  },
  {
    tables: [],
    columns: [CustomCells.Uuid],
    component: UuidCell,
  },
  {
    tables: [],
    columns: [CustomCells.TemplateFileName],
    component: TemplateFileCell,
  },
  {
    tables: [],
    columns: [CustomCells.GeneratedDocument],
    component: TemplateStatusCell,
  },
  {
    tables: [TablesType.ClassForms],
    columns: [CustomCells.Enabled],
    component: ClassFormEnabledCell,
  },
  {
    tables: [],
    columns: [CustomCells.Publisher],
    component: PublisherCell,
  },
];
