import React from 'react';
import { ExpandedGroupMembersListProps } from './ExpandedGroupMembersList.types';
import { MaxUsersSelectedTooltip } from '../../../../../../../MaxUsersSelectedTooltip';
import { MemoizedUsersGroupsListItemSelectAndDeselect } from 'components/UsersAndGroupsSelection/components/UsersGroupsListItemSelectAndDeselect';
import { useUsersAndGroupsExpandablePickerContext } from 'components/UsersAndGroupsSelection/UsersAndGroupsExpandableSelect/contexts/UsersAndGroupsExpandablePickerContext';
import { UserGroupMembership } from 'utils/types/api/userGroups.types';

/**
 * Displays the selectable list of group members when there is any on the list.
 */
export const ExpandedGroupMembersList = ({
  isSyncGroupMembers,
  items,
  textToHighlight,
}: ExpandedGroupMembersListProps) => {
  const {
    selectedItems,
    toggleItemSelectionState,
    limits,
    isMaxUsersLimitReached,
  } = useUsersAndGroupsExpandablePickerContext();

  return (
    <>
      {items.map(({ domId, item: user }) => {
        const isSelected =
          !!selectedItems.users.get(user.id) || isSyncGroupMembers;

        return (
          <MaxUsersSelectedTooltip isItemSelected={isSelected} key={user.id}>
            <MemoizedUsersGroupsListItemSelectAndDeselect
              id={domId}
              item={user}
              handleClick={toggleItemSelectionState}
              selected={isSelected}
              isDisabled={
                isSyncGroupMembers ||
                !limits.isAllowedToSelectIndividualMembers ||
                (!isSelected && isMaxUsersLimitReached)
              }
              searchText={textToHighlight}
              limitReached={false}
              additionalText={
                user.membership === UserGroupMembership.Owner
                  ? 'Group owner'
                  : undefined
              }
            />
          </MaxUsersSelectedTooltip>
        );
      })}
    </>
  );
};
