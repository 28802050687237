export enum ClassFieldFormFields {
    Label = 'label',
    Alias = 'alias',
    Type = 'type',
    DefaultValue = 'default_value',
    SelectionOptions = 'options',
    Users = 'users',
    Groups = 'user_groups',
    MaxLength = 'max_length',
    MaxValue = 'max_value',
    MinValue = 'min_value',
    MaxValues = 'max_values',
    MinValues = 'min_values',
    Required = 'is_required',
    Identifier = 'is_identifier',
    Unique = 'is_unique',
    Order = 'order',
    Duplicates = 'has_duplicates',
    MaxFiles = 'max_num_of_files',
    MinUsersValues = 'min_users_values',
    MaxUsersValues = 'max_users_values',
    MinGroupsValues = 'min_groups_values',
    MaxGroupsValues = 'max_groups_values',
    AllowSync = 'allow_sync',
    AllowMembersSelection = 'allow_members_selection',
}