import { toast } from 'components/lib/toast';
import GlobalIntlSingleton from 'providers/IntlProviderWrapper/globalIntlSingleton';
import ToastType from 'utils/Enums/ToastType';

export const showPermissionsToSomeGroupsRevokedToast = (
  resourceName: string
) => {
  toast(
    {
      title: GlobalIntlSingleton.intl.formatMessage({
        id: 'misc.error',
        defaultMessage: 'Error!',
      }),
      subtitle: GlobalIntlSingleton.intl.formatMessage(
        {
          id: 'misc.permissionsToSomeGroupsHaveBeenRevokedToast',
          defaultMessage:
            'None of the selected user groups could be assigned to {name} due to insufficient permissions.',
        },
        {
          name: resourceName,
        }
      ),
    },
    ToastType.Error
  );
};
