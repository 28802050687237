import React, { useMemo } from 'react';
import FormField from 'pages/TaskTemplates/components/FormField';
import { Col } from 'components/lib/Grid';
import FormLabel from 'pages/TaskTemplates/components/FormLabel';
import { FormattedMessage } from 'react-intl';
import { FormikSwitch } from 'components/lib/Switch';
import clsx from 'clsx';
import { FormikInputNumber } from 'components/lib/InputNumber';
import FlexLayoutWindows from 'utils/Enums/FlexLayoutWindows';
import { useFlexLayoutContext } from 'components/lib/FlexLayout/FlexLayoutContext';
import Select from 'components/Inputs/Select';
import { useFormikContext } from 'formik';
import { OBJECT_CLASS_FIELD_DEFAULT_VALUE } from 'utils/testIds';
import { ObjectClassFieldTypes } from 'utils/types/api/objectClassesFields.types';
import { useSelectOptions } from './hooks';
import { useClassFieldFormContext } from 'pages/ObjectClasses/components/ObjectClassForm/contexts/ClassFieldFormContext';
import { useClassFieldPropertiesStyles } from 'styles/classFieldPropertiesStyles';
import { FieldDefaultValueProps } from './types/FieldDefaultValueProps';
import { ClassFieldForm } from '../../../../types';
import { ClassFieldFormFields } from 'pages/ObjectClasses/enums';

export const FieldDefaultValue: React.FC<FieldDefaultValueProps> = ({
  type: parentType,
  shouldBeDisabled,
  parsedTypes,
}) => {
  const styles = useClassFieldPropertiesStyles();
  const { readOnly } = useClassFieldFormContext();
  const {
    externalWindowsMethods: { checkIfExternalWindowExists = () => false },
  } = useFlexLayoutContext();
  const {
    values: {
      max_value: maxValue,
      min_value: minValue,
      options: selectionOptions,
    },
  } = useFormikContext<ClassFieldForm>();

  const { type } = useMemo(() => parsedTypes[parentType]?.default_value || {}, [
    parentType,
    parsedTypes,
  ]);

  const isExternalWindowOpen =
    checkIfExternalWindowExists(FlexLayoutWindows.ObjectClassEditField) ||
    checkIfExternalWindowExists(FlexLayoutWindows.ObjectClassAddField);

  const selectOptions = useSelectOptions(selectionOptions || []);

  if (!type) return null;

  if (type === ObjectClassFieldTypes.Bool) {
    return (
      <FormField gutter={0}>
        <Col span={24}>
          <>
            <FormikSwitch
              disabled={readOnly || shouldBeDisabled}
              name={ClassFieldFormFields.DefaultValue}
              data-testid={OBJECT_CLASS_FIELD_DEFAULT_VALUE}
            />
            <FormLabel inline className={styles.defaultBoolLabel}>
              <FormattedMessage
                id='objectClasses.fields.defaultValue'
                defaultMessage='Default value'
              />
            </FormLabel>
          </>
        </Col>
      </FormField>
    );
  }

  return (
    <FormField gutter={0}>
      <Col span={24}>
        <FormLabel>
          <FormattedMessage
            id='objectClasses.fields.defaultValue'
            defaultMessage='Default value'
          />
        </FormLabel>
        {(type === ObjectClassFieldTypes.Int ||
          type === ObjectClassFieldTypes.Float) && (
            <div
              className={clsx(styles.numericInputWrapper, {
                [styles.inputNumberWrapperForExternal]: isExternalWindowOpen,
              })}
            >
              <FormikInputNumber<ClassFieldForm>
                name={ClassFieldFormFields.DefaultValue}
                isDecimal={type === ObjectClassFieldTypes.Float}
                minimum={minValue === null ? undefined : minValue}
                maximum={maxValue === null ? undefined : maxValue}
                disabled={readOnly || shouldBeDisabled}
                data-testid={OBJECT_CLASS_FIELD_DEFAULT_VALUE}
              />
            </div>
          )}
        {parentType === ObjectClassFieldTypes.Enum && (
          <Select
            name={ClassFieldFormFields.DefaultValue}
            options={selectOptions}
            disabled={!selectionOptions || readOnly || shouldBeDisabled}
            allowClear
            data-testid={OBJECT_CLASS_FIELD_DEFAULT_VALUE}
          />
        )}
      </Col>
    </FormField>
  );
};