import React, { useMemo } from 'react';
import { UsersAndGroupsExpandablePickerContext } from './UsersAndGroupsExpandablePickerContext';
import { UsersAndGroupsExpandablePickerContextProps } from './UsersAndGroupsExpandablePickerContextProvider.types';
import { useToggleSelection } from './hooks';
import { isNil } from 'lodash';
import { UsersAndGroupsExpandablePickerContextType } from './UsersAndGroupsExpandablePickerContext.types';
import { USERS_AND_GROUPS_MAXIMUM_USERS_SELECTION } from './UsersAndGroupsExpandablePickerContextProvider.consts';

export const UsersAndGroupsExpandablePickerContextProvider = ({
  children,
  selectedItems,
  setSelectedItems,
  searchFilter,
  limits,
  errors,
  getPopupContainer,
  editModeOptions,
  minMaxInfoErrorPairs,
  onGroupMembersLoad,
}: UsersAndGroupsExpandablePickerContextProps) => {
  const { toggleItemSelectionState } = useToggleSelection(setSelectedItems);

  const maxUsersLimitReached = useMemo(() => {
    const countLimits = limits.selectionCountLimits;
    return (
      !isNil(countLimits) &&
      !isNil(countLimits.maxUsers) &&
      countLimits.maxUsers <= selectedItems.users.size
    );
  }, [limits.selectionCountLimits, selectedItems.users.size]);

  const absoluteMaxUsersLimitReached = useMemo(
    () => selectedItems.users.size >= USERS_AND_GROUPS_MAXIMUM_USERS_SELECTION,
    [selectedItems.users.size]
  );

  const contextValue: UsersAndGroupsExpandablePickerContextType = useMemo(
    () => ({
      searchText: searchFilter ?? '',
      limits: limits,
      selectedItems: selectedItems,
      errors: errors,
      toggleItemSelectionState: toggleItemSelectionState,
      getPopupContainer,
      editModeOptions,
      isMaxUsersLimitReached:
        maxUsersLimitReached || absoluteMaxUsersLimitReached,
      minMaxInfoErrorPairs,
      onGroupMembersLoad,
    }),
    [
      searchFilter,
      limits,
      selectedItems,
      errors,
      toggleItemSelectionState,
      getPopupContainer,
      editModeOptions,
      maxUsersLimitReached,
      absoluteMaxUsersLimitReached,
      minMaxInfoErrorPairs,
      onGroupMembersLoad,
    ]
  );

  return (
    <UsersAndGroupsExpandablePickerContext.Provider value={contextValue}>
      {children}
    </UsersAndGroupsExpandablePickerContext.Provider>
  );
};
