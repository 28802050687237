import useFlexLayoutWindow from 'hooks/useFlexLayoutWindow';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import {
  getDocumentTemplatesSelectedRow,
  getDocumnetTemplateRow,
} from 'store/selectors/documentTemplatesSelectors';
import FlexLayoutWindows from 'utils/Enums/FlexLayoutWindows';
import React, { useEffect } from 'react';
import ErrorComponent from 'components/ErrorComponent';
import { useParams } from 'react-router-dom';
import { ViewFieldUsageWindow } from 'components/ViewFieldUsageWindow';
import { useDocumentTemplateUsage } from './hooks';

export const DocumentTemplateViewUsagePanel = () => {
  const intl = useIntl();
  const { closeComponent, isExternal } = useFlexLayoutWindow(
    FlexLayoutWindows.DocumentTemplateViewUsage
  );
  const { id: objectClassID } = useParams<{ id: string }>();

  const selectedRowId = useSelector(getDocumentTemplatesSelectedRow);
  const { name = '', id } =
    useSelector(getDocumnetTemplateRow(selectedRowId)) || {};

  const { displayConfig, loading, error } = useDocumentTemplateUsage(
    id,
    objectClassID
  );

  useEffect(() => {
    if (!selectedRowId)
      closeComponent(FlexLayoutWindows.DocumentTemplateViewUsage);
  }, [closeComponent, selectedRowId]);

  if (error) return <ErrorComponent error={error?.status} />;

  return (
    <ViewFieldUsageWindow
      {...{ displayConfig, isExternal }}
      usageLabel={intl.formatMessage({
        id: 'documentTemplates.areasExplanationGroupScope',
        defaultMessage:
          'This document template is used in the following sequences:',
      })}
      noUsageLabel={intl.formatMessage({
        id: 'documentTemplates.noUsageGroupScope',
        defaultMessage: 'This document template is not used in any sequences.',
      })}
      id={selectedRowId}
      label={name}
      isLoading={loading}
      isLight
    />
  );
};