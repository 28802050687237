import { ViewUsageConfig } from 'components/ViewUsage/types';
import useData from 'hooks/useData';
import { useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { generatePath } from 'react-router-dom';
import { TASK_TEMPLATES_USAGE } from 'utils/endpoints';
import { TaskTemplateUsage } from 'utils/types/api/tasks.types';

const useTaskTemplatesViewUsage = (id?: string) => {
  const intl = useIntl();
  const {
    loading,
    error,
    data: {
      sequences = [],
      task_group_templates: taskGroupTemplates = [],
    } = {},
  } = useObjectClassViewUsageData(id);

  const displayConfig = useMemo<ViewUsageConfig[]>(
    () => [
      {
        usage: taskGroupTemplates,
        title: intl.formatMessage({
          id: 'misc.taskGroups',
          defaultMessage: 'Task group templates',
        }),
      },
      {
        usage: sequences,
        title: intl.formatMessage({
          id: 'misc.sequences',
          defaultMessage: 'Sequences',
        }),
      },
    ],
    [intl, sequences, taskGroupTemplates]
  );

  return { loading, error, displayConfig };
};

const useObjectClassViewUsageData = (id?: string) => {
  const [data, { loading, error, fetchData }] = useData<TaskTemplateUsage>(
    id !== undefined
      ? generatePath(TASK_TEMPLATES_USAGE, {
          id,
        })
      : '',
    {
      fetchOnLoad: false,
    }
  );

  useEffect(() => {
    if (id !== undefined) fetchData();
  }, [fetchData, id]);

  return { error, loading, data };
};

export default useTaskTemplatesViewUsage;
