import { CustomCellProps } from 'components/CustomCell/CustomCell.types';
import DocumentTemplateStatus from 'components/CustomCell/components/Cells/components/DocumentTemplateStatus';
import React from 'react';

export const TemplateStatusCell = ({ row }: CustomCellProps) => {
  const { id, generated_document } = row;
  return (
    <DocumentTemplateStatus id={id} generated_document={generated_document} />
  );
};
