import { CustomCellProps } from 'components/CustomCell/CustomCell.types';
import SingleUserAvatarWithCounter from 'components/CustomCell/components/Cells/components/UserCell/SingleUserAvatarWithCounter';
import React from 'react';
import { ApiUserAvatar } from 'utils/types/api/users.types';

export const ObjectClassOwnersCell = ({ row }: CustomCellProps) => {
  const { owners: { total_number: total = 0, first = {} } = {} } = row;

  if (!first) {
    return null;
  }

  const {
    first_name: firstName,
    last_name: lastName,
    id,
  } = first as ApiUserAvatar;

  return (
    <SingleUserAvatarWithCounter {...{ total, firstName, lastName, id }} />
  );
};
