import { useMemo } from "react";
import { useSelector } from "react-redux";
import { getSidebarData } from "store/selectors/flexLayoutSelectors";
import FlexLayoutWindows from "utils/Enums/FlexLayoutWindows";
import { StoredData } from "../types";

export const useStoredData = <T>(panelId: FlexLayoutWindows) => {
    const savedData = (useSelector(getSidebarData) as unknown) as MappedObject<
        StoredData<T>,
        FlexLayoutWindows
    >;

    return useMemo(() => {
        const { values: storedValues, ...rest } = savedData[panelId] || {};

        return { storedValues, allStoredData: { ...rest, values: storedValues } };
    }, [panelId, savedData]);
};