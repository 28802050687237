import { UseCurrentTableReturnType } from 'hooks/useCurrentTable/types';
import {
  ActionCellProps,
  BulkSelectionActionProps,
  CustomOverLimitStatus,
  PaginationGoToFirst,
  PaginationGoToLast,
  PaginationGoToPage,
  PaginationOnChange,
  PaginationOnNext,
  PaginationOnPrevious,
  RowsDisplay,
} from 'components/Table/Table.types';
import {
  LifecycleSubTabsType,
  SelectedOption,
} from 'pages/TaskTemplates/types';
import { FormMode } from 'utils/Enums/FormMode';
import { TaskTemplatesOptions } from 'utils/types/api/tasks.types';
import { FlatValidatorsObject } from 'utils/types';
import { RootState } from 'store/reducers';
import { TaskGroupTemplateOptions } from 'pages/TaskGroups/components/TaskGroupTemplateForm/types';
import { TaskGroupDetails } from 'utils/types/api/taskGroups.types';
import { ConvertedTaskTemplateDetails } from 'pages/TaskTemplates/components/TaskForm/hooks/useTaskTemplateDetailsData/types';
import { ErrorStatus } from 'utils/types/errorResponse';
import React, { Dispatch, SetStateAction } from 'react';
import FlexLayoutWindows from '../utils/Enums/FlexLayoutWindows';
import { ObjectClassField } from '../utils/types/api/objectClassesFields.types';
import { SelectOptionItem } from 'utils/types/selectInput.types';
import { SearchDataParams } from 'components/SearchInput/types';
import { ObjectRecordPermissions } from 'utils/types/api/objectRecords.types';
import { AxiosError } from 'axios';
import { Input } from 'antd';

export interface ContentWrapperContextType {
  toggleDisabledEdition: (value: boolean) => void;
  disabledEdition: boolean;
}

export interface InitialSortBy {
  id: string;
  desc: boolean;
}

export interface ActionConfirmationData extends Partial<ActionCellProps> {
  relatedUsers?: number;
  fieldLabel?: string;
  selectedField?: ObjectClassField;
}

export enum CustomTableGroupKeys {
  Default = 'default',
  ObjectClassFields = 'objectClassfields',
}

export type CustomDataGroupsType = MappedObject<
  {
    label: string;
    addQueryParameters?: (visibleColumns: string[]) => string;
  },
  CustomTableGroupKeys
>;

export type EmptyTableRenderProp = (props: {
  tableWidth: number | string;
}) => JSX.Element;

export type RenderHeaderLimit = () => JSX.Element;

export interface PaginationRender {
  lastPage: number;
  isInfinite: boolean;
  loading: boolean | undefined;
  currentPage: number;
  goToFirst: PaginationGoToFirst;
  goToLast: PaginationGoToLast;
  goToPage: PaginationGoToPage;
  onNext: PaginationOnNext;
  onChange: PaginationOnChange;
  onPrevious: PaginationOnPrevious;
  inputRef: React.RefObject<Input>;
  className: string | undefined;
}

export interface TableContextType
  extends Omit<UseCurrentTableReturnType<any>, 'currentColumns' | 'density'> {
  additionalHeaderActions?: JSX.Element;
  withFilters: boolean;
  sortParams: InitialSortBy;
  preventFromHideColumns: string[];
  newButtonLabel?: string;
  onNewClick?: () => void;
  newButtonDisabled?: boolean;
  isDraggingEnabled?: boolean;
  withColumnConfiguration?: boolean;
  filterSelectOptions?: SelectOptionItem[];
  getEmptyMessageDescription?: (
    defaultMessage: string,
    value?: SearchDataParams,
    showDefaultMessage?: boolean
  ) => string;
  isVisibleBulkSelectionColumn: boolean;
  setIsVisibleBulkSelectionColumn: Dispatch<SetStateAction<boolean>>;
  onBulkSelectionRemoveAction?: (
    props: BulkSelectionActionProps
  ) => Promise<void>;
  onBulkSelectionAddAction?: (props: BulkSelectionActionProps) => Promise<void>;
  hasListPermissions: boolean;
  resetCustomOffset?: VoidFunction;
  customDataGroups?: CustomDataGroupsType;
  preferencesId?: string | number;
  columnsConfigurationLoading?: boolean;
  withoutSort?: boolean;
  isPageSizeDisabled?: boolean;
  pageSizes?: string[];
  rowsDisplay: RowsDisplay;
  preventInteractionWithColumns: boolean;
  preventOverflow: boolean;
  paginationRender?: React.FC<PaginationRender>;
  withBordersAroundTable: boolean;
  renderEmptyTable?: EmptyTableRenderProp;
  fullWidth?: boolean;
  renderHeaderLimit?: RenderHeaderLimit;
  alwaysDisplayPagination?: boolean;
  customTableId: string;
  customOverLimit?: CustomOverLimitStatus;
}

export enum ActionConfirmationType {
  Delete = 'delete',
  Remove = 'remove',
  Deprecate = 'deprecate',
  RemoveChild = 'removeChild',
}

export interface LifecycleTab {
  activeSubTab: LifecycleSubTabsType;
  onChange: (
    name: LifecycleSubTabsType,
    event?: React.MouseEvent<HTMLElement>
  ) => void;
  selectedOption?: SelectedOption;
  onOptionChange: (id: SelectedOption) => void;
}

export interface TaskViewContextType {
  mode: FormMode;
  options?: TaskTemplatesOptions;
  lifecycle: LifecycleTab;
  readOnly: boolean;
  validators?: FlatValidatorsObject;
  data?: ConvertedTaskTemplateDetails;
  skipSubmitRedirect?: boolean;
  setSkipSubmitRedirect: (val: boolean) => void;
  objectClassName: string | undefined;
  setObjectClassName: React.Dispatch<React.SetStateAction<string | undefined>>;
  userOwnsAnyClass: boolean | 'fetching';
}

export interface TaskGroupTemplateContextType {
  mode: FormMode;
  options?: TaskGroupTemplateOptions;
  readOnly: boolean;
  validators?: FlatValidatorsObject;
  data?: TaskGroupDetails;
}

export interface ErrorStatusContextType {
  errorStatus: ErrorStatus | undefined;
  setErrorStatus: React.Dispatch<React.SetStateAction<ErrorStatus>>;
}

export interface HelmetContextType {
  pageTitle: string;
  setPageTitle: React.Dispatch<React.SetStateAction<string>>;
}

export type ConfirmationModalID = FlexLayoutWindows | 'task-complete-form';

export interface ConfirmationModalContextType {
  storedModalFunctions?: MappedObjectToggleModalCallback;
  setShouldBeDisplayed: (id: ConfirmationModalID, value: boolean) => void;
  shouldBeDisplayed?: MappedObjectShouldBeDisplayed;
  setStoredModalFunctions: (
    id: ConfirmationModalID,
    value: ToggleModalCallback | undefined
  ) => void;
  windowToOpen?: FlexLayoutWindows;
  setWindowToOpen: React.Dispatch<
    React.SetStateAction<FlexLayoutWindows | undefined>
  >;
  redirectId: string | number | undefined;
  setRedirectId: React.Dispatch<
    React.SetStateAction<string | number | undefined>
  >;
}

export type MappedObjectShouldBeDisplayed = MappedObject<
  boolean,
  ConfirmationModalID
>;

export type MappedObjectToggleModalCallback = MappedObject<
  ToggleModalCallback,
  ConfirmationModalID
>;

export type ToggleModalCallback = {
  callback?: (value?: any) => void;
  onSubmit?: (value?: any) => void;
  preventCloseTab?: (value?: any) => void;
};

export interface TableRowContextType
  extends Pick<UseCurrentTableReturnType<any>, 'currentColumns' | 'density'> {
  customCells: string[] | undefined;
  setSelectedRow: (data: string | undefined) => void;
  getSelectedRow: (state: RootState) => string | undefined;
  onDoubleClick?: (value: ActionCellProps) => void;
  onSingleClick?: (value: ActionCellProps) => void;
  onActionCellClick?: (props: ActionCellProps) => void;
  // ref to prev hovered elem during dragging
  prevHoveredElem?: React.RefObject<HTMLDivElement | null>;
  draggedId?: React.RefObject<number | undefined>;
}

export interface RecordItem {
  permissions?: Partial<ObjectRecordPermissions>;
  recordId: string;
  identifier: string;
  /**
   * True forces summary panel to display itself, then verification of permissions and object class settings occurs
   */
  isSummaryPanelEnabled?: boolean;
  isAccessedFromTask?: boolean;
}

export interface SelectedItem {
  record?: RecordItem;
  objectClassId?: string;
}

export interface SelectedResourceContextType {
  setSelectedResource: (data?: SelectedItem) => void;
  selectedResource?: SelectedItem;
  setAdditionalSelectedResource: (data?: SelectedItem) => void;
  additionalSelectedResource?: SelectedItem;
}

export interface TableViewTypeProviderContextType {
  isCardsView?: boolean;
  toggle: () => void;
}

export enum ViewMode {
  Cards = 'card',
  List = 'table',
}

export interface AutoSaveErrorModalContextType {
  showErrorModal?: (error: AxiosError) => void;
  isVisibleErrorModal: boolean;
  setTitle: React.Dispatch<SetStateAction<string>>;
}
export interface LoadingContextType {
  updateLoadedModule: (name: string, loading: boolean) => void;
}

export interface InPlaceFilesSavingContextType {
  updateSavingFiles: (id: string) => void;
  savingFilesInProgress: string[];
}

export interface RefreshResourceEntry {
  refreshFunction: (fetchFnData?: MappedObject<unknown>) => void;
  componentName: string;
}

export type RefetchResourceState = MappedObject<
  RefreshResourceEntry[],
  RefetchResources
>;

export interface RefetchOptions {
  skipComponents?: string | string[];
  refetchFnData?: MappedObject<unknown>;
}

export interface RefetchResourceContextType {
  refetchResourcesRef: React.MutableRefObject<RefetchResourceState>;
  refetchData: (
    resourceName: RefetchResources,
    refetchOptions?: RefetchOptions
  ) => void;
}

export enum RefetchResources {
  Records = 'records',
  UserGroups = 'userGroups',
  ObjectClasses = 'objectClasses',
  RecordAccess = 'recordAccess',
}
