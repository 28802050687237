import React from 'react';
import { useIntl } from 'react-intl';
import { ButtonOutlined } from 'components/lib/Button';
import { PlusIcon } from 'components/Icon';
import { ADD_CONDITION_BUTTON_TESTID } from 'utils/testIds';
import { useAddFilterButtonStyles } from './AddFilterButton.styles';
import { useSelector } from 'react-redux';
import { getCurrentTableFilters } from 'store/selectors/filtersSelectors';
import { AddFilterButtonProps } from './AddFilterButton.types';
import Tooltip from 'components/lib/Tooltip';
import { useValidateCurrentTableFilters } from 'hooks/useValidateCurrentTableFilters';

export const AddFilterButton = ({ onClick }: AddFilterButtonProps) => {
  const intl = useIntl();
  const styles = useAddFilterButtonStyles();

  const filters = useSelector(getCurrentTableFilters);
  const { areFiltersValid } = useValidateCurrentTableFilters();

  const isDisabled = !areFiltersValid() || filters.length === 0;

  return (
    <Tooltip
      className={styles.tooltip}
      shouldBeRendered={!isDisabled}
      title={intl.formatMessage({
        id: 'misc.addCondition',
        defaultMessage: 'Add condition',
      })}
    >
      <ButtonOutlined
        className={styles.addFilterBtn}
        icon={<PlusIcon size={14} />}
        disabled={isDisabled}
        data-testid={ADD_CONDITION_BUTTON_TESTID}
        onClick={onClick}
      />
    </Tooltip>
  );
};
