import {
  COMFORTABLE_DENSITY_ROW,
  COMPACT_DENSITY_ROW,
  DEFAULT_DENSITY_ROW,
} from 'components/Table/Table.consts';
import { TableDensity } from 'components/Table/enums';

const useDensity = (density: TableDensity | undefined) => {
  switch (density) {
    case TableDensity.Comfortable:
      return COMFORTABLE_DENSITY_ROW;
    case TableDensity.Default:
      return DEFAULT_DENSITY_ROW;
    case TableDensity.Compact:
      return COMPACT_DENSITY_ROW;
    default:
      return COMFORTABLE_DENSITY_ROW;
  }
};

export default useDensity;
