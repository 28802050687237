import React, { useMemo } from 'react';
import { TableHeadersContextProviderProps } from './TableHeadersContextProvider.types';
import { TableHeadersContext } from './TableHeadersContext';
import { useDragAndDrop } from '../components/TableHeaderGroups/hooks';
import { TableHeadersContextType } from './TableHeadersContext.types';

export const TableHeadersContextProvider = ({
  children,
  columnResizing,
  headerGroups,
  headerWrapperRef,
  hiddenColumns,
  isEditMode,
  rows,
  setColumnOrder,
  setHiddenColumns,
  totalColumnsWidth,
  visibleColumns,
}: TableHeadersContextProviderProps) => {
  const {
    onColumnDragStart,
    onDragUpdate,
    onDragEnd,
    getItemStyle,
    isDraggingColumn,
  } = useDragAndDrop({
    setColumnOrder,
    visibleColumns,
    editMode: isEditMode,
  });

  const contextValue: TableHeadersContextType = useMemo(
    () => ({
      rows,
      totalColumnsWidth,
      columnResizing,
      headerGroups,
      headerWrapperRef,
      hiddenColumns,
      isEditMode,
      setColumnOrder,
      setHiddenColumns,
      visibleColumns,
      dragAndDrop: {
        onColumnDragStart,
        onDragUpdate,
        onDragEnd,
        getItemStyle,
        isDraggingColumn,
      },
    }),
    [
      columnResizing,
      getItemStyle,
      headerGroups,
      headerWrapperRef,
      hiddenColumns,
      isDraggingColumn,
      isEditMode,
      onColumnDragStart,
      onDragEnd,
      onDragUpdate,
      rows,
      setColumnOrder,
      setHiddenColumns,
      totalColumnsWidth,
      visibleColumns,
    ]
  );

  return (
    <TableHeadersContext.Provider value={contextValue}>
      {children}
    </TableHeadersContext.Provider>
  );
};
