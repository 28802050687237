import React, { useState, useEffect } from 'react';
import { Protocol } from './enums';
import { Input } from 'components/lib/Input';
import { Select } from 'components/lib/Select';
import { PROTOCOL_SELECT_OPTIONS } from './consts';
import { UseURLFieldProps } from './types';
import { URL_INPUT_PROTOCOL, URL_INPUT_URL } from 'utils/testIds';

export const useURLField = ({
  onChange,
  reValidateField,
  value,
  disabled,
  fieldAlias,
}: UseURLFieldProps) => {
  const [protocol, setProtocol] = useState<string | null>(Protocol.HTTPS);
  const [url, setUrl] = useState<string | null>(null);
  useEffect(() => {
    if (value) {
      const protocol = (value as string).match(/^http(s?):\/\//i)?.[0];
      const url = (value as string).replace(/^http(s?):\/\//i, '');
      setProtocol(protocol ?? Protocol.HTTPS);
      setUrl(url ?? null);
    } else {
      setUrl(null);
    }
  }, [value]);

  const handleSetProtocol = (v: string) => {
    if (url) {
      onChange(v + url.trim());
    } else {
      setProtocol(v);
    }
    reValidateField();
  };

  const handleChangeURL = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value) {
      setUrl(e.target.value.trim().replace(/^http(s?):\/\//i, ''));
    } else {
      setUrl(null);
    }
  };
  const handleSetURL = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value) {
      onChange(protocol + e.target.value.trim());
      reValidateField();
    } else {
      onChange(null);
      reValidateField();
    }
  };
  const protocolInput = (
    <Select
      dropdownMatchSelectWidth={true}
      options={PROTOCOL_SELECT_OPTIONS}
      value={protocol}
      onChange={handleSetProtocol}
      getPopupContainer={(trigger: HTMLElement) => trigger.parentElement ?? document.body}
      {...{ disabled }}
      data-testid={`${URL_INPUT_PROTOCOL}-${fieldAlias}`}
    />
  );
  const urlInput = (
    <Input
      onChange={handleChangeURL}
      value={url as string}
      onBlur={handleSetURL}
      data-testid={`${URL_INPUT_URL}-${fieldAlias}`}
      {...{ disabled }}
    />
  );

  return {
    protocolInput,
    urlInput,
  };
};
