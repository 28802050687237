import React from 'react';
import ChildClassControls from './components/ChildClassControls';
import ChildClassTableFormBuilder from './components/ChildClassTableFormBuilder';
import { Widget } from 'alx-dynamic-form';
import { FormBuilderChildObjectClass } from 'components/formBuilder/formBuilder/FormBuilderContext/types';
import {
  useChildClassWidgetStyles,
  useChildCFormBuilderlassComponentStyles,
} from './styles';
import { ChildClassFormBuilderProps } from './components/ChildClassTableFormBuilder/types';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { selectChildClassById } from 'store/selectors/childClassesSelectors';
import ChildClassFormBuilderNoPermission from './components/ChildClassFormBuilderNoPremissionChildClass';
import ChildClassTableWrapper from 'components/ChildClassTableWrapper';
import { DEFAULT_COLUMNS_WIDTHS } from 'components/Table/Table.consts';
import ChildclassWidgetNoPermission from './components/ChildClassWidgetNoPermission';
import ChildClassLabel from './components/ChildClassLabel';
import ChildClassPreviewComponent from './components/ChildClassPreviewComponent';
import { PassThroughParams } from 'components/formBuilder/formBuilder/types';
import { ChildClassMultiplicity } from 'utils/types/api/objectClassModels.types';
import { calcUIState } from 'components/ChildClassTableWrapper/components/ChildClassTable/hooks';
import { childClassUICOmponentStates } from 'components/ChildClassTableWrapper/components/ChildClassTable/enums';

export const ChildClassStandardWidget: Widget = ({
  additionalFieldProps,
  passThrough,
}) => {
  const widgetClasses = useChildClassWidgetStyles({});

  if (!additionalFieldProps.childClasses) {
    return <>-</>;
  }

  const childClassData = additionalFieldProps.childClasses.find(
    (childClass: FormBuilderChildObjectClass) =>
      childClass.id.toString() ===
      passThrough?.childClassParams?.childClassID.toString()
  );

  const uiPermissionsConfig = calcUIState(
    additionalFieldProps.readOnly,
    childClassData
  );

  if (uiPermissionsConfig.list !== childClassUICOmponentStates.ENABLED) {
    return (
      <ChildclassWidgetNoPermission
        childClassLabel={childClassData?.label ?? ''}
      />
    );
  }

  if (additionalFieldProps.skipUploading) {
    //skipUploading here indicates that the component is being rendered in preview drawer (where we display a mock of a table)
    return (
      <div className={widgetClasses.container}>
        <div className={widgetClasses.header}>
          <ChildClassLabel
            label={childClassData.label}
            overlayContainerClassName={widgetClasses.overlayContainer}
            inputLabelClassName={widgetClasses.inputLabel}
          />
          {childClassData?.multiplicity ===
            ChildClassMultiplicity.ZERO_OR_MORE && (
            <span className={widgetClasses.resultsContainer}>
              <FormattedMessage
                id='formBuilder.childClassComponent.results'
                defaultMessage='0 results'
              />
            </span>
          )}
        </div>

        <ChildClassPreviewComponent
          passThrough={passThrough?.childClassParams}
        />
      </div>
    );
  }

  return (
    <div className={widgetClasses.container}>
      <ChildClassTableWrapper
        label={childClassData.label}
        readOnly={additionalFieldProps.readOnly}
        recordId={additionalFieldProps.recordId}
        hasCreateEditViewEnabled={childClassData.hasCreateEditViewEnabled}
        classId={childClassData.id.toString()}
        multiplicity={childClassData.multiplicity}
        identifier={additionalFieldProps.identifier}
        config={
          passThrough?.childClassParams?.childClassColumns ?? [
            { id: 'id', width: DEFAULT_COLUMNS_WIDTHS.width },
          ]
        }
      />
    </div>
  );
};

export const InPlaceChildClassWidget: Widget = ({
  additionalFieldProps,
  passThrough,
}) => {
  const widgetClasses = useChildClassWidgetStyles({});

  if (!additionalFieldProps.childClasses) {
    return <>-</>;
  }

  const childClassData = additionalFieldProps.childClasses.find(
    (childClass: FormBuilderChildObjectClass) =>
      childClass.id.toString() ===
      passThrough?.childClassParams?.childClassID.toString()
  );

  const uiPermissionsConfig = calcUIState(
    additionalFieldProps.readOnly,
    childClassData
  );

  if (uiPermissionsConfig.list !== childClassUICOmponentStates.ENABLED) {
    return (
      <ChildclassWidgetNoPermission
        childClassLabel={childClassData?.label ?? ''}
      />
    );
  }

  return (
    <div className={widgetClasses.container}>
      <ChildClassTableWrapper
        label={childClassData.label}
        hasCreateEditViewEnabled={childClassData.hasCreateEditViewEnabled}
        readOnly={additionalFieldProps.readOnly}
        recordId={additionalFieldProps.recordId}
        classId={childClassData.id.toString()}
        multiplicity={childClassData.multiplicity}
        identifier={additionalFieldProps.identifier}
        config={
          passThrough?.childClassParams?.childClassColumns ?? [
            { id: 'id', width: DEFAULT_COLUMNS_WIDTHS.width },
          ]
        }
      />
    </div>
  );
};

export const ChildClassFormBuilder = ({
  componentProps,
  onChange,
}: ChildClassFormBuilderProps) => {
  const styles = useChildCFormBuilderlassComponentStyles({});
  const passThrough =
    (componentProps?.passThrough as PassThroughParams).childClassParams ?? {};
  const childClassID = passThrough.childClassID as string;
  const childClass = useSelector(selectChildClassById(childClassID));

  if (!childClass?.hasViewPermission) {
    return <ChildClassFormBuilderNoPermission />;
  }

  return (
    <div className={styles.container}>
      <ChildClassControls readOnly={false} childClassId={childClassID} />
      <ChildClassTableFormBuilder
        onChange={onChange}
        componentProps={componentProps}
      />
    </div>
  );
};
