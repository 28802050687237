import { createUseStyles } from 'react-jss';
import { ThemeState } from 'store/reducers/types/theme.types';

const useLimitAlertStyles = createUseStyles((theme: ThemeState) => {
  return {
    alert: {
      border: `1px solid ${theme['primary-color']}`,
      borderRadius: 2,
      padding: [0, 10],
      margin: [4, 0],
      display: 'flex',
      alignItems: 'center',
      backgroundColor: '#fff !important',
      '& svg': {
        position: 'initial',
        height: 18,
        marginRight: 10,
        color: `${theme['primary-color']} !important`,
      },
      '& span': {
        fontSize: '11px !important',
        fontWeight: 400,
        lineHeight: 1.3,
      },
    },
  };
});

export default useLimitAlertStyles;
