import { createUseStyles } from 'react-jss';
import { ThemeState } from 'store/reducers/types/theme.types';
import { TableDensity } from './enums';
import { detect } from 'detect-browser';
import { getBodyRowHeight } from 'pages/MyAccount/components/MyAccountSections/components/PreferencesTab/components/RowSettings/components/styles';
import { getHeaderCellHeight } from './utils';

const HORIZONTAL_SCROLL_HEIGHT = 12;

const getCheckboxStyles = (theme: ThemeState) => ({
  '& .ant-checkbox input, .ant-checkbox-inner': {
    width: 16,
    height: 16,
    borderColor: theme['checkbox-border-color'],
    borderWidth: '0.09375rem',
    backgroundColor: 'transparent',
  },
  '& .ant-checkbox-inner::after': {
    left: '22%',
    top: '40%',
    borderWidth: 1,
  },
  '&:hover .ant-checkbox::after, & .ant-checkbox:hover::after': {
    visibility: 'hidden',
  },
});

type UseTableWrapperStylesProps = {
  ACTIONS_COLUMN_WIDTH?: number;
  isInfinite?: boolean;
  emptyTable?: {
    sidebarWidth: number;
    totalColumnsWidth: number;
    ignoreTotalColumnsWidth: boolean;
  };
};

const useTableWrapperStyles = createUseStyles((theme: ThemeState) => {
  return {
    loaderWrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      flexGrow: 1,
    },
    moveableColum: {
      maxWidth: '30px',
    },
    filtersBar: {
      width: '100%',
      padding: '0 0 10px 15px',
    },
    maxTooltip: {
      paddingRight: 30,
      paddingBottom: 0,
      backgroundColor: '#202134',
      fontFamily: 'inherit',
      fontWeight: 600,
      fontSize: 12,
      borderRadius: 2,
      '& .ant-tooltip-inner': {
        backgroundColor: 'inherit',
        padding: '7px 6px',
      },
    },
    actionsFilters: {
      display: 'flex',
      justifyContent: 'space-between',
      minWidth: 300, // should be updated if new items will appear
    },
    tableContainer: {
      display: 'inline-flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
    tableCell: ({ ACTIONS_COLUMN_WIDTH }: UseTableWrapperStylesProps = {}) => ({
      width: ACTIONS_COLUMN_WIDTH || 100,
    }),
    flexAlignCenter: {
      display: 'flex',
      alignItems: 'center',
    },
    emptyTableWrapper: {
      marginTop: 90,
      position: 'sticky',
      left: 0,
      display: 'block',
      width: '100%',
      maxWidth: ({ emptyTable }: UseTableWrapperStylesProps) => {
        if (!emptyTable || emptyTable.ignoreTotalColumnsWidth) {
          return '100%';
        }

        if (
          window.document.body.offsetWidth - emptyTable.sidebarWidth <
          emptyTable.totalColumnsWidth
        ) {
          return '100%';
        }

        return emptyTable.totalColumnsWidth;
      },
    },
    infinityTable: { overflow: 'visible' },
    paginationWrapper: {
      color: theme['secondary-color'],
      marginLeft: 17,
      alignItems: 'flex-end',
    },
    spaceWrapper: {
      marginLeft: 10,
      marginRight: 10,
    },
    leftSpaceWrapper: {
      marginLeft: 10,
    },
    buttonsWrapper: {
      '& > .ant-space-item:not(:last-child)': {
        marginRight: '5px !important',
      },
    },
    headerSpace: {
      alignItems: 'flex-end',
      '& > .ant-space-item:not(:last-child)': {
        marginRight: '10px !important',
      },
    },
    input: { width: 65 },
    lastPageWrapper: { display: 'block', whiteSpace: 'nowrap' },
    perPageWrapper: { minWidth: 60, display: 'block', whiteSpace: 'nowrap' },
    resizer: {
      cursor: 'col-resize',
      padding: '0 2px',
      '&:after': {
        display: 'block',
        content: '""',
        width: 2,
        height: '100%',
        position: 'absolute',
        left: '50%',
        top: 0,
        background: '#ced5da',
        transform: 'translateX(-50%)',
      },
      '&.disabled': {
        cursor: 'default!important',
      },
    },
    sorterWrapper: {
      display: 'flex',
      flexDirection: 'column',
      marginLeft: 10,
    },
    upperArrow: {
      marginBottom: 3,
    },
    active: {
      color: theme['primary-color'],
    },
    buttonNoPointerEvents: {
      cursor: 'not-allowed',
      '& button': {
        pointerEvents: 'none',
      },
    },
    quickFilterSelect: {
      width: 200,
      '& .ant-select-selector': {
        height: '35px !important',
      },
    },
  };
});

type UseTableStylesProps = {
  preventOverflow?: boolean;
  emptyTable?: boolean;
  withBordersAroundTable?: boolean;
  hasScrollY?: boolean;
  fullWidth?: boolean;
  density?: TableDensity;
  isInitialLoading?: boolean;
};

export const useTableStyles = createUseStyles((theme: ThemeState) => {
  return {
    isEmpty: {
      display: 'none',
    },
    tableContainer: {
      overflow: ({ preventOverflow }: UseTableStylesProps) =>
        preventOverflow ? 'auto' : 'initial',
    },
    filledTable: {
      display: 'flex',
      visibility: ({ emptyTable }: UseTableStylesProps) =>
        emptyTable ? 'hidden' : 'initial',
    },
    // styles below were made to create a border for tables inside formbuilder (childClasses/nestedTables) - withBordersAroundTable property
    tableWithHeaderWrapper: { marginLeft: 15, display: 'flex', marginTop: 8 },
    tableWithHeaderContainer: {
      overflow: 'auto',
      overflowY: 'hidden',
      borderTop: '1px solid #D2D9E7',
      borderBottom: '1px solid #D2D9E7',
      width: '100%',
    },
    leftBorder: { borderLeft: '1px solid #D2D9E7' },
    rightBorder: { borderLeft: '1px solid #D2D9E7' },
    // styles above were made to create a border for tables inside formbuilder (childClasses/nestedTables) - withBordersAroundTable property
    table: {
      margin: ({ withBordersAroundTable }: UseTableStylesProps) =>
        withBordersAroundTable ? '0 0 0 0' : '0 0 0 15px',
      maxHeight: '75vh',
      borderTop: 10,
      '& .resizer': {
        display: 'inline-block',
        height: '100%',
        position: 'absolute',
        right: 0,
        top: 0,
        zIndex: 1,
        touchAction: 'none',
        cursor: 'col-resize',
      },
    },
    tableHeader: {
      borderRadius: 3,
      position: 'sticky',
      top: 0,
      zIndex: 2,
      display: 'block',
      paddingTop: ({ withBordersAroundTable }: UseTableStylesProps) =>
        withBordersAroundTable ? 0 : 8,
      paddingRight: ({ hasScrollY }: UseTableStylesProps) =>
        hasScrollY ? 10 : 0,
      backgroundColor: 'white',
      touchAction: 'none',
    },
    tableHeaderWrapper: {
      overflow: ({ preventOverflow }: UseTableStylesProps) =>
        preventOverflow ? 'initial' : 'auto',
    },
    tableHead: ({ fullWidth }: UseTableStylesProps) => ({
      textTransform: 'uppercase',
      borderRadius: 3,
      fontWeight: 700,

      width: fullWidth
        ? 'max(var(--table-row-width), 100%) !important'
        : 'var(--table-row-width) !important',
      minWidth: fullWidth ? '100%' : 'initial',
      backgroundColor: theme['dropdown-active-gray'],
    }),
    tableBulkSelectionHead: {
      justifyContent: 'space-between',
      fontWeight: 700,
      backgroundColor: theme['border-color-light-blue'],
      '& > div > div, & > div > div > div:first-child': {
        backgroundColor: theme['border-color-light-blue'],
      },
    },
    tableCell: {
      padding: '0 15px',
      color: '#2E3F57',
      fontSize: 13,
    },
    tableCellCentered: {
      justifyContent: 'center',
      alignItems: 'center',
    },
    tableHeadCell: {
      backgroundColor: '#EAEDEF',
      position: 'sticky',
      display: 'flex',
      alignItems: 'center',
      top: 0,
      color: '#2E3F57',
      fontSize: 13,
      '& > div:first-child': {
        padding: '0 15px',
        height: ({ density }: UseTableStylesProps) =>
          getHeaderCellHeight(density),
        backgroundColor: '#EAEDEF',
      },
      '&:first-of-type': {
        borderRadius: '3px 0 0 3px',
      },
      '&:last-of-type': {
        borderRadius: '0 3px 3px 0',
      },
    },
    tableHeadCellDragging: {
      backgroundColor: theme['primary-color'],
    },
    tablePagination: {
      width: '100%',
    },
    sortableTableHeader: {
      cursor: 'pointer',
      '&.disabled': {
        cursor: 'default',
      },
    },
    ellipsis: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    removeColumnButton: {
      transition: 'background-color 0.2s ease-in-out',
      borderRadius: 3,
      height: 22,
      width: 22,
      '&:hover': {
        backgroundColor: '#D5DADE',
      },
    },
    noScrollbar: {
      '&::-webkit-scrollbar': {
        display: 'none',
        width: 0,
        background: 'transparent',
      },
      'scrollbar-width': 'none',
      '8-ms-overflow-style': 'none',
    },
    searchWrapper: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      '& input': {
        maxWidth: 256,
        margin: 2,
      },
    },
    labelWithSearch: {
      display: 'flex',
      overflow: 'initial',
      marginRight: 20,
    },
    checkbox: {
      ...getCheckboxStyles(theme),
      '& .ant-checkbox-indeterminate .ant-checkbox-inner': {
        backgroundColor: theme['primary-color'],
        borderColor: theme['primary-color'],
        '&::after': {
          top: '50%',
          backgroundColor: 'white',
          left: '50%',
          height: 2,
        },
      },
    },
    draggableTablePaginationFiller: {
      height: '32px',
    },
    loader: {
      minHeight: (props: UseTableStylesProps) =>
        props?.isInitialLoading ? '400px' : 'auto',
    },
  };
});

type UseTableBodyStylesProps = {
  loading?: boolean;
  addColumnMode?: boolean;
  isInitialLoading?: boolean;
  isNoData?: boolean;
  preventOverflow?: boolean;
  tableBodyTopOffset?: number;
};

export const useTableBodyStyles = createUseStyles({
  tableBody: {
    position: 'relative',
    pointerEvents: ({ loading, addColumnMode }: UseTableBodyStylesProps) =>
      loading || addColumnMode ? 'none' : 'auto',
    overflow: ({
      addColumnMode,
      isInitialLoading,
      isNoData,
      preventOverflow,
    }: UseTableBodyStylesProps) => {
      if (preventOverflow) {
        return 'none';
      } else if (addColumnMode || isInitialLoading || isNoData) {
        return 'hidden';
      } else {
        return 'auto';
      }
    },
    maxHeight: ({
      tableBodyTopOffset,
      preventOverflow,
    }: UseTableBodyStylesProps) => {
      return preventOverflow
        ? '100%'
        : `calc(100vh - ${
            (tableBodyTopOffset || 0) + HORIZONTAL_SCROLL_HEIGHT
          }px)`;
    },
    height: '100%',
    width: '100%',
    paddingRight:
      detect()?.name === 'safari' || detect()?.name === 'firefox' ? 15 : 0,
  },
});

type UseRowStylesProps = {
  density?: TableDensity;
};

export const useRowStyles = createUseStyles((theme: ThemeState) => {
  return {
    tr: {
      border: `1px solid transparent`,
      cursor: 'pointer',
      backgroundColor: theme['table-row-color'],
      '&:focus': {
        outline: 'none',
        border: `1px dotted ${theme['table-row-selected']}`,
      },
      '&:hover': {
        border: `1px solid ${theme['table-row-selected']}`,
      },
      width: 'var(--table-row-width) !important',
      height: (props: UseRowStylesProps) =>
        `${getBodyRowHeight(props?.density)}px`,
      lineHeight: (props: UseRowStylesProps) =>
        `${getBodyRowHeight(props?.density)}px`,
      '& .ant-spin-container': {
        lineHeight: '25px',
      },
    },
    fullRowWidth: {
      minWidth: '100% !important',
    },
    alternateRow: {
      backgroundColor: theme['table-row-color-alternate'],
    },
    tbodyTd: {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      display: 'flex',
      alignItems: 'center',
    },
    moveableIcon: {
      marginRight: '3px',
    },
    moveableCell: {
      padding: '0 10px',
      fontSize: 13,
      height: '100%',
    },
    tableCell: {
      padding: '0 15px',
      color: '#2E3F57',
      fontSize: 13,
      height: '100%',
    },
    cellWrapper: {
      display: 'flex',
      alignItems: 'center',
      height: '100%',
      width: '100%',
    },
    tableCellCentered: {
      justifyContent: 'center',
      alignItems: 'center',
    },
    numericColumns: {
      justifyContent: 'flex-end',
    },
    selected: {
      backgroundColor: `${theme['primary-color-light']}`,
      alignItems: 'center',
    },
    checkbox: {
      ...getCheckboxStyles(theme),
      '& .ant-checkbox-inner::after': {
        left: '22%',
        top: '45%',
        borderWidth: 1,
      },
    },
  };
});

type UseEmptyTableRowStylesProps = {
  index: number;
  density?: TableDensity;
};

export const useEmptyTableRowStyles = createUseStyles((theme: ThemeState) => ({
  tr: {
    border: `1px solid transparent`,

    position: 'absolute',
    top: (props: UseEmptyTableRowStylesProps) =>
      `${props.index * getBodyRowHeight(props?.density)}px`,
    width: 'var(--table-row-width) !important',
    height: (props: UseEmptyTableRowStylesProps) =>
      `${getBodyRowHeight(props?.density)}px`,
    lineHeight: (props: UseEmptyTableRowStylesProps) =>
      `${getBodyRowHeight(props?.density)}px`,
    '& .ant-spin-container': {
      lineHeight: '25px',
    },
  },
  alternateRow: {
    backgroundColor: theme['dropdown-hover-gray'],
  },
}));
export default useTableWrapperStyles;
