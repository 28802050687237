import { CustomCellProps } from 'components/CustomCell/CustomCell.types';
import { CheckIcon } from 'components/Icon';
import React from 'react';
import { useCheckIconCellStyles } from './CheckIconCell.styles';

export const CheckIconCell = ({ row, column }: CustomCellProps) => {
  const styles = useCheckIconCellStyles();

  return (
    <>
      {row[column] && <CheckIcon className={styles.booleanCheck} size={12} />}
    </>
  );
};
