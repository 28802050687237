import {
  ViewUsageConfig,
  ViewUsageDisplayType,
} from 'components/ViewUsage/types';
import useData from 'hooks/useData';
import { useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { generatePath } from 'react-router-dom';
import { CUSTOM_COMPONENT_USAGE } from 'utils/endpoints';
import {
  CustomComponentObjectClassStandaloneFormUsageItem,
  CustomComponentUsage,
} from 'utils/types/api/customComponents.types';
import { TreeOfStrings } from 'components/ViewUsage/TreeOfStringsDisplay/types';

const transformObjectClassStandaloneForms = (
  objectClassStandaloneForms: any[]
) => {
  const usageMap = objectClassStandaloneForms.reduce(
    (acc, entry: CustomComponentObjectClassStandaloneFormUsageItem) => {
      if (acc[entry.name]) {
        return {
          ...acc,
          [entry.name]: [...acc[entry.name], entry.standalone_form_name],
        };
      } else {
        return {
          ...acc,
          [entry.name]: [entry.standalone_form_name],
        };
      }
    },
    []
  );
  return Object.keys(usageMap).reduce((acc, key): TreeOfStrings[] => {
    return [
      ...acc,
      {
        id: -1,
        name: key,
        children: usageMap[key].map(
          (item: string): TreeOfStrings => {
            return {
              id: -1,
              name: item,
              children: [],
            } as TreeOfStrings;
          }
        ),
      },
    ];
  }, [] as TreeOfStrings[]);
};

const useCustomComponentsViewUsage = (id?: string) => {
  const intl = useIntl();
  const {
    loading,
    error,
    data: {
      object_class_summary_forms: objectClassSummaryForms = [],
      object_class_create_forms: objectClassCreateForms = [],
      num_of_landing_pages: numOfLandingPages = 0,
      object_class_standalone_forms: objectClassStandaloneForms = [],
    } = {},
  } = useCustomComponentsViewUsageData(id);

  const displayConfig = useMemo<ViewUsageConfig[]>(() => {
    const landingPageName = intl.formatMessage({
      id: 'misc.landingPage',
      defaultMessage: 'Landing page',
    });
    const landingPages = numOfLandingPages
      ? [{ id: 1, name: landingPageName }]
      : [];
    return [
      {
        usage: landingPages,
        title: landingPageName,
      },
      {
        usage: objectClassCreateForms.map(item => {
          return {
            name: item.name,
            id: item.object_class_id,
          };
        }),
        title: intl.formatMessage({
          id: 'customComponents.enabledForObjectClassCreateViewForm',
          defaultMessage: 'Object class "Create/edit view" display forms',
        }),
      },
      {
        usage: objectClassSummaryForms.map(item => {
          return {
            name: item.name,
            id: item.object_class_id,
          };
        }),
        title: intl.formatMessage({
          id: 'customComponents.enabledForObjectClassSummaryForm',
          defaultMessage: 'Object class "Summary view" display forms',
        }),
      },
      {
        usage: transformObjectClassStandaloneForms(objectClassStandaloneForms),
        title: intl.formatMessage({
          id: 'customComponents.enabledObjectClassStandaloneForm',
          defaultMessage: 'Object class Standalone forms',
        }),
        type: ViewUsageDisplayType.TreeOfStrings,
      },
    ];
  }, [
    intl,
    objectClassCreateForms,
    objectClassSummaryForms,
    numOfLandingPages,
    objectClassStandaloneForms,
  ]);

  return { loading, error, displayConfig };
};

const useCustomComponentsViewUsageData = (id?: string) => {
  const [data, { loading, error, fetchData }] = useData<CustomComponentUsage>(
    id !== undefined
      ? generatePath(CUSTOM_COMPONENT_USAGE, {
          id,
        })
      : '',
    {
      fetchOnLoad: false,
    }
  );

  useEffect(() => {
    if (id !== undefined) fetchData();
  }, [fetchData, id]);

  return { error, loading, data };
};

export default useCustomComponentsViewUsage;
