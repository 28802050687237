import { createUseStyles } from 'react-jss';
import { ThemeState } from 'store/reducers/types/theme.types';

export const useHeaderCellStyles = createUseStyles((theme: ThemeState) => {
  return {
    labelWithSearch: {
      display: 'flex',
      overflow: 'initial',
      marginRight: 20,
    },
    sortableTableHeader: {
      cursor: 'pointer',
      '&.disabled': {
        cursor: 'default',
      },
    },
    ellipsis: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    searchWrapper: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      '& input': {
        maxWidth: 256,
        margin: 2,
      },
    },
    removeColumnButton: {
      transition: 'background-color 0.2s ease-in-out',
      borderRadius: 3,
      height: 22,
      width: 22,
      '&:hover': {
        backgroundColor: '#D5DADE',
      },
    },
  };
});
