import {
  ActionCellProps,
  ActionConfirmationModalData,
} from 'components/Table/Table.types';
import { useSelectedRow } from 'components/ActionMenu/ActionMenuList/hooks';
import { RowActions, UseChildClassTableActionsParams } from './types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import GTag from 'providers/IntlProviderWrapper/gTag';
import { GTagEventButtonName } from 'providers/IntlProviderWrapper/gTag/types';
import { ActionConfirmationData, ActionConfirmationType } from 'contexts/types';
import { apiCall } from 'utils/api';
import { generatePath } from 'react-router-dom';
import { DELETE_OBJECT_CLASS_MODEL_CHILDREN } from 'utils/endpoints';
import { toast } from 'components/lib/toast';
import { useIntl } from 'react-intl';
import ToastType from 'utils/Enums/ToastType';
import showDefaultErrorToast from 'utils/functions/showDefaultErrorToast';
import { DEFAULT_OBJECT_MODEL_ID } from 'components/Table/Table.consts';
import { useDispatch, useSelector } from 'react-redux';
import {
  pushStackWithTreeUpdate,
  removeChildFromTopOfStack,
  setNestedObjectRecordsSelectedRow,
} from 'store/actions/nestedObjectRecordsActions';
import { useSelectedResourceContext } from 'contexts/SelectedResourceContext';
import { getNestedObjectRecordsSelectedRow } from 'store/selectors/nestedObjectRecordsSelctors';

export const useChildClassTableActions = ({
  identifier,
  recordId: parentID,
  classId,
  label,
  clearSelectedForFiltering,
  selectedForFiltering,
  hasCreateEditViewEnabled,
}: UseChildClassTableActionsParams) => {
  const [modalData, setModalData] = useState<
    ActionConfirmationModalData | undefined
  >(undefined);
  const intl = useIntl();
  const dispatch = useDispatch();
  const {
    setAdditionalSelectedResource,
    additionalSelectedResource,
  } = useSelectedResourceContext();
  const selectedRow = useSelector(getNestedObjectRecordsSelectedRow);

  //synchronise selected row with additional data (additional data might be updated on record creation)
  //selectedRow is cleaned on table unmount, so it has to be set when new table is mounted
  useEffect(() => {
    if (selectedRow !== additionalSelectedResource?.record?.recordId)
      dispatch(
        setNestedObjectRecordsSelectedRow(
          additionalSelectedResource?.record?.recordId
        )
      );
  }, [additionalSelectedResource, dispatch, selectedRow]);

  const onDeleteClick = ({ id, object_name }: ActionCellProps) => {
    GTag.pushGtagButtonEvent(GTagEventButtonName.Remove);

    setModalData({
      data: {
        id,
        name: object_name,
      },
      type: ActionConfirmationType.RemoveChild,
    });
  };

  const onOpenClick = ({ id, object_name }: ActionCellProps) => {
    if (!label) {
      return;
    }
    if (!hasCreateEditViewEnabled) {
      return;
    }

    setAdditionalSelectedResource(undefined);

    dispatch(
      pushStackWithTreeUpdate({
        recordId: id.toString(),
        recordIdentifier: object_name,
        classId,
        formData: undefined,
        className: label,
      })
    );
  };

  const actions: MappedObject<(data: ActionCellProps) => void, RowActions> = {
    [RowActions.RemoveChild]: onDeleteClick,
    [RowActions.Open]: onOpenClick,
  };

  const onRemoveChild = useCallback(
    async (params?: ActionConfirmationData) => {
      if (!parentID) {
        dispatch(removeChildFromTopOfStack(params?.id?.toString() ?? ''));
        clearSelectedForFiltering();
        return;
      }
      try {
        await apiCall.delete(
          generatePath(DELETE_OBJECT_CLASS_MODEL_CHILDREN, {
            id: DEFAULT_OBJECT_MODEL_ID,
            parentId: parentID,
            childId: params?.id,
          })
        );

        toast(
          {
            title: intl.formatMessage({
              id: 'misc.success',
              defaultMessage: 'Success!',
            }),
            subtitle: intl.formatMessage(
              {
                id: 'misc.childRemoved',
                defaultMessage: `<b>{name}</b> has been removed.`,
              },
              { b: (...chunks) => <b>{chunks}</b>, name: params?.name }
            ),
          },
          ToastType.Success
        );
        clearSelectedForFiltering();
      } catch {
        showDefaultErrorToast();
      }
    },
    [clearSelectedForFiltering, parentID, dispatch, intl]
  );

  const selectedRowItems = useSelectedRow<RowActions, ActionCellProps>(actions);

  const confirmationData = useMemo(
    () => ({
      [ActionConfirmationType.RemoveChild]: {
        title: intl.formatMessage(
          {
            id: 'misc.areYouSureToRemoveChildRecordTitle',
            defaultMessage: 'Are you sure you want to remove {name}?',
          },
          { name: modalData?.data.name }
        ),
        getSubtitle: () =>
          intl.formatMessage(
            {
              id: 'misc.areYouSureToRemoveChildRecordSubtitle',
              defaultMessage: 'They will no longer be related with {parentID}.',
            },
            {
              parentID:
                identifier ??
                parentID ??
                intl.formatMessage({
                  id: 'nestedObjectRecords.thisRecord',
                  defaultMessage: 'this record',
                }),
            }
          ),
        confirmActionOnItemMethod: (params?: ActionConfirmationData) =>
          onRemoveChild(params),
        shouldRefetchOnConfirm: !!parentID && !selectedForFiltering,
      },
    }),
    [intl, modalData, parentID, selectedForFiltering, identifier, onRemoveChild]
  );

  return {
    confirmationData,
    modalData,
    onOpenClick,
    setModalData,
    ...selectedRowItems,
  };
};
