import { Tooltip } from 'antd';
import { CustomCellProps } from 'components/CustomCell/CustomCell.types';
import TooltipString from 'components/TooltipString';
import React from 'react';
import { useIntl } from 'react-intl';

export const ObjectNameCell = ({ row }: CustomCellProps) => {
  const intl = useIntl();

  const objectName = row.object_name;

  return objectName === null ? (
    <Tooltip
      title={intl.formatMessage({
        id: 'misc.detailsRestricted',
        defaultMessage: 'Details restricted due to permissions',
      })}
    >
      ***
    </Tooltip>
  ) : (
    <TooltipString text={objectName}>{objectName}</TooltipString>
  );
};
