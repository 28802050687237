import { toast } from 'components/lib/toast';
import GlobalIntlSingleton from 'providers/IntlProviderWrapper/globalIntlSingleton';
import ToastType from 'utils/Enums/ToastType';

export const showSomeGroupsHaveBeenDeletedToast = () => {
  toast(
    {
      title: GlobalIntlSingleton.intl.formatMessage({
        id: 'misc.warning',
        defaultMessage: 'Warning!',
      }),
      subtitle: GlobalIntlSingleton.intl.formatMessage({
        id: 'tasksPanel.someGroupsHaveBeenDeleted',
        defaultMessage:
          'None of the selected user groups could be assigned to the task because some of them have been deleted.',
      }),
    },
    ToastType.Warning
  );
};
