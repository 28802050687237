import React from 'react';
import CustomCells from 'utils/Enums/CustomCells';
import { CustomCellProps } from './CustomCell.types';
import { TABLE_CELL_COMPONENT_MAPPING } from './CustomCell.consts';

export const CustomCell = ({ table, column, row }: CustomCellProps) => {
  for (const mapping of TABLE_CELL_COMPONENT_MAPPING) {
    const tableMatches =
      mapping.tables.length === 0 || mapping.tables.includes(table);
    const columnMatches = mapping.columns.includes(column as CustomCells);

    if (!tableMatches || !columnMatches) {
      continue;
    }

    const ValidCellComponent = mapping.component;
    return <ValidCellComponent table={table} column={column} row={row} />;
  }

  return null;
};
