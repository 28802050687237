import { CustomCellProps } from 'components/CustomCell/CustomCell.types';
import FileLink from 'components/FileLink';
import React from 'react';

export const TemplateFileCell = ({ row }: CustomCellProps) => {
  const { template_file_name, id } = row ?? {};
  return (
    <FileLink
      label={template_file_name}
      downloadedFileName={`${template_file_name}.docx`}
      documentTemplateID={id}
    />
  );
};
