import useData from 'hooks/useData';
import { useCallback } from 'react';
import { generatePath } from 'react-router-dom';
import { OBJECT_CLASS_FIELD_DETAILS } from 'utils/endpoints';
import { ObjectClassField } from 'utils/types/api/objectClassesFields.types';

export const useClassFieldData = () => {
  const [data, { error, loading, fetchData }] = useData<ObjectClassField>('', {
    fetchOnLoad: false,
    dataTransformFn: useCallback(
      (data: ObjectClassField) =>
        Object.fromEntries(
          Object.entries(data).map(([key, value]) => [
            key,
            value === null ? undefined : value,
          ])
        ) as ObjectClassField,
      []
    ),
  });

  const getFieldData = useCallback(
    async (classId: string, fieldId: string) => {
      await fetchData(
        generatePath(OBJECT_CLASS_FIELD_DETAILS, { id: classId, fieldId })
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fetchData]
  );

  return { error, loading, data, getFieldData };
};