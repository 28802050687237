import useData from 'hooks/useData';
import { useEffect } from 'react';
import { generatePath } from 'react-router-dom';
import { USER_GROUP_USAGE } from 'utils/endpoints';
import { UserGroupUsage } from 'utils/types/api/userGroups.types';

export const useUserGroupsViewUsageData = (id?: string) => {
  const [data, { loading, error, fetchData }] = useData<UserGroupUsage>(
    id !== undefined
      ? generatePath(USER_GROUP_USAGE, {
          id,
        })
      : '',
    {
      fetchOnLoad: false,
    }
  );

  useEffect(() => {
    if (id !== undefined) fetchData();
  }, [fetchData, id]);

  return { error, loading, data };
};
