import axios from 'axios';
import { toast } from 'components/lib/toast';
import { useSelectedResourceContext } from 'contexts/SelectedResourceContext';
import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { generatePath } from 'react-router-dom';
import { apiCall } from 'utils/api';
import { OBJECT_RECORD_HISTORY_FILES } from 'utils/endpoints';
import ToastType from 'utils/Enums/ToastType';
import showDefaultErrorToast from 'utils/functions/showDefaultErrorToast';

export const useGeneratedDocumentDownload = (
  eventId: string,
  refreshData: () => void
) => {
  const intl = useIntl();
  const { selectedResource } = useSelectedResourceContext();
  const knownErrorCodes = new Set([401, 403, 404]);

  return useCallback(
    (fileName?: string) => async () => {
      const recordId = selectedResource?.record?.recordId;
      if (recordId) {
        try {
          const url = generatePath(OBJECT_RECORD_HISTORY_FILES, {
            recordId,
            eventId,
          });

          const { data } = await apiCall.get(url, { responseType: 'blob' });

          const link = document.createElement('a');
          link.setAttribute('target', '_blank');
          link.href = URL.createObjectURL(data);
          link.download = fileName ?? 'file.docx';

          link.click();
          URL.revokeObjectURL(link.href);
          toast(
            {
              title: intl.formatMessage({
                id: 'misc.success',
                defaultMessage: 'Success!',
              }),
              subtitle: intl.formatMessage({
                id: 'activityLog.theDocumentHasBeenDownloaded',
                defaultMessage: 'The document has been downloaded.',
              }),
            },
            ToastType.Success
          );
        } catch (e) {
          if (axios.isAxiosError(e)) {
            if (knownErrorCodes.has(e.response?.status as number)) {
              refreshData();
              return;
            }
          }
          showDefaultErrorToast();
        }
      }
    },
    [eventId, selectedResource, intl, knownErrorCodes, refreshData]
  );
};
