import React, { useState, useRef, useMemo, useEffect } from 'react';
import clsx from 'clsx';
import { FormattedMessage } from 'react-intl';
import { useChildClassTableStyles } from './styles';
import { useFormBuilderContext } from 'components/formBuilder/formBuilder/FormBuilderContext';
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from 'react-beautiful-dnd';
import { ChildClassFormBuilderProps } from './types';
import { getColumnVarName } from 'components/Table/utils';
import { DEFAULT_COLUMNS_WIDTHS } from 'components/Table/Table.consts';
import { ObjectClassFieldMap } from '../../types';
import {
  ChildClassColumns,
  ChildClassColumn,
  PassThroughParams,
} from 'components/formBuilder/formBuilder/types';
import { useRecordPropFieldsMap, useRecordFieldsMap } from '../../hooks';
import HeaderColumn from './components/HeaderColumn';
import { JSONSchema7 } from 'json-schema';
import { FIELD_PREFIX } from 'utils/consts';
import { COLUMNS_LIMIT } from './consts';
import { isFieldSortable } from '../../utils';
import { TableDensity } from 'components/Table/enums';
import { useSelector } from 'react-redux';
import { getTableDensity } from 'store/selectors/preferencesSelectors';
import { selectChildClassById } from 'store/selectors/childClassesSelectors';
import { ChildClassMultiplicity } from 'utils/types/api/objectClassModels.types';

const ChildClassTableFormBuilder = ({
  componentProps,
  onChange,
}: ChildClassFormBuilderProps) => {
  const passThrough = (componentProps?.passThrough as PassThroughParams) ?? {};
  const childClassPassThrough = passThrough.childClassParams;
  const childClassID = childClassPassThrough?.childClassID as string;
  const childClassColumns = childClassPassThrough?.childClassColumns as ChildClassColumns;
  const childClass = useSelector(selectChildClassById(childClassID));
  const density = useSelector(getTableDensity) ?? TableDensity.Default;

  const [currentAddColsIndex, setCurrentAddColsIndex] = useState<
    number | undefined
  >(undefined);
  const [isDragging, setIsDragging] = useState(false);
  const objectRecrdPropFields = useRecordPropFieldsMap();
  const { setIsDragDisabled, selectedItem } = useFormBuilderContext();
  const defaultColumnns = useMemo(
    () => [{ id: 'id', width: DEFAULT_COLUMNS_WIDTHS.minWidth }],
    []
  );
  const [columns, setColumns] = useState<ChildClassColumns>(
    childClassColumns ?? defaultColumnns
  );

  const {
    selectedObjectrecordFields,
    setSelectedObjectrecordFields,
    totalFieldsUnfiltered,
  } = useRecordFieldsMap(childClassID, columns);

  const columnWidths = useMemo(
    () =>
      Object.fromEntries(
        columns.map(({ id, width }) => [
          getColumnVarName(id),
          `${width ?? DEFAULT_COLUMNS_WIDTHS.minWidth}px`,
        ])
      ),
    [columns]
  );
  useEffect(() => {
    setColumns(childClassColumns ?? defaultColumnns);
  }, [childClassID, childClassColumns, defaultColumnns]);
  const tableWrapperRef = useRef<HTMLTableElement>(null);
  const headerWrapperRef = useRef<HTMLTableElement>(null);

  const styles = useChildClassTableStyles({ density });

  const hasFocus = componentProps.path === selectedItem;

  const { width: tableWidth } =
    tableWrapperRef.current?.getBoundingClientRect() || {};

  const searchInputWidth = useMemo(() => {
    return {
      ['--search-input-width' as any]: `${(tableWidth as number) * 0.7}px`,
    };
  }, [tableWidth]);

  const { top: tableHeadRowTopOffset, left: tableHeadRowLeftOffset } =
    headerWrapperRef.current?.getBoundingClientRect() || {};

  const handleColumnDragStart = () => {
    setIsDragging(true);
  };

  const handleColumOrderChange = (result: DropResult) => {
    const { source, destination } = result;
    setIsDragging(false);
    if (!destination) {
      return;
    }

    if (
      source.droppableId === destination.droppableId &&
      source.index === destination.index
    ) {
      return;
    }

    const newCols = [...columns];
    const sourceCol = newCols[source.index];
    newCols.splice(source.index, 1);
    newCols.splice(destination.index, 0, sourceCol);
    setColumns(newCols);

    onChange({
      ...componentProps,
      passThrough: {
        ...passThrough,
        childClassParams: {
          ...childClassPassThrough,
          childClassColumns: newCols.map(col => {
            return {
              id: col.id,
              width: col.width,
            };
          }),
        },
      },
    } as JSONSchema7);
  };

  const handleColumResize = (columnId: string, width: number) => {
    if (!width) {
      return;
    }
    const newCols = columns.map(col => {
      if (col.id === columnId) {
        return {
          ...col,
          width,
        };
      }
      return { ...col };
    });
    setColumns(newCols);
    onChange({
      ...componentProps,
      passThrough: {
        ...passThrough,
        childClassParams: {
          ...childClassPassThrough,
          childClassColumns: newCols.map(col => {
            return {
              id: col.id,
              width: col.width,
            };
          }),
        },
      },
    } as JSONSchema7);
  };

  const getLabel = (fieldId: string) => {
    if (fieldId.startsWith(FIELD_PREFIX)) {
      return selectedObjectrecordFields[fieldId]?.label;
    } else {
      return objectRecrdPropFields[fieldId]?.label;
    }
  };

  const updateWidgetConfig = (columns: ChildClassColumns) => {
    onChange({
      ...componentProps,
      passThrough: {
        ...passThrough,
        childClassParams: {
          ...childClassPassThrough,
          childClassColumns: columns.map((col: ChildClassColumn) => {
            return {
              id: col.id,
              width: col.width,
            };
          }),
        },
      },
    } as JSONSchema7);
  };

  const onAddNewColumns = (
    columns: ChildClassColumns,
    columnsMeta: ObjectClassFieldMap
  ) => {
    setSelectedObjectrecordFields({
      ...selectedObjectrecordFields,
      ...columnsMeta,
    });
    setColumns(columns);
    updateWidgetConfig(columns);
  };

  const onRemoveColumn = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    const id = e.currentTarget.id;
    const newCols = columns.filter(col => col.id !== id);
    setColumns(newCols);
    updateWidgetConfig(newCols);
  };

  const onMouseEnterHeaderRow = () => {
    setIsDragDisabled(true);
  };

  const onMouseLeaveHeaderRow = () => {
    setIsDragDisabled(false);
  };

  return (
    <div className={styles.flex}>
      <div className={styles.tableBorderLeft}></div>
      <div
        className={styles.tableWrapper}
        ref={tableWrapperRef}
        style={searchInputWidth}
      >
        <div className={styles.table}>
          <div
            className={styles.headerRow}
            onMouseEnter={onMouseEnterHeaderRow}
            onMouseLeave={onMouseLeaveHeaderRow}
            ref={headerWrapperRef}
            style={columnWidths}
          >
            <DragDropContext
              onDragEnd={handleColumOrderChange}
              onDragStart={handleColumnDragStart}
            >
              <Droppable
                droppableId='columns-droppable'
                isDropDisabled={false}
                direction={'horizontal'}
              >
                {provided => (
                  <div
                    {...provided.droppableProps}
                    className={clsx(styles.row, styles.headerRowPlaceHolder)}
                    key={0}
                    ref={provided.innerRef}
                  >
                    {columns.map((column, columnIndex) => (
                      <Draggable
                        key={column.id}
                        draggableId={column.id}
                        index={columnIndex}
                        isDragDisabled={
                          !hasFocus || currentAddColsIndex !== undefined
                        }
                      >
                        {(provided, snapshot) => {
                          const showSorter =
                            !hasFocus &&
                            isFieldSortable(column.id, objectRecrdPropFields);
                          const showRemoveColumn =
                            hasFocus &&
                            column.id !== 'id' &&
                            !currentAddColsIndex;
                          const showResizer =
                            !hasFocus || currentAddColsIndex !== undefined;
                          const showAddColumn =
                            hasFocus &&
                            (currentAddColsIndex === undefined ||
                              currentAddColsIndex === columnIndex) &&
                            !isDragging &&
                            columns.length < COLUMNS_LIMIT &&
                            totalFieldsUnfiltered +
                              Object.keys(objectRecrdPropFields).length >
                              columns.length;
                          return (
                            <HeaderColumn
                              headerWrapperRef={headerWrapperRef}
                              column={column}
                              handleColumResize={handleColumResize}
                              provided={provided}
                              snapshot={snapshot}
                              tableHeadRowTopOffset={tableHeadRowTopOffset}
                              tableHeadRowLeftOffset={tableHeadRowLeftOffset}
                              columnIndex={columnIndex}
                              label={getLabel(column.id)}
                              showResizer={showResizer}
                              showRemoveColumn={showRemoveColumn}
                              showSorter={showSorter}
                              showAddColumn={showAddColumn}
                              childClassID={childClassID}
                              onRemoveColumn={onRemoveColumn}
                              setCurrentAddColsIndex={setCurrentAddColsIndex}
                              onAddNewColumns={onAddNewColumns}
                              columns={columns}
                              objectRecrdPropFields={objectRecrdPropFields}
                            />
                          );
                        }}
                      </Draggable>
                    ))}

                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
            <div className={clsx(styles.tableHeadCell, styles.actionsHeader)}>
              <FormattedMessage
                id='formBuilder.childClassComponent.childClassActions'
                defaultMessage='Actions'
              />
            </div>
          </div>
          <div className={styles.tableBody}></div>
          <div className={styles.tableRows}>
            {childClass?.multiplicity ===
            ChildClassMultiplicity.ZERO_OR_MORE ? (
              <>
                <div>&nbsp;</div>
                <div>&nbsp;</div>
                <div>&nbsp;</div>
                <div>&nbsp;</div>
                <div>&nbsp;</div>
              </>
            ) : (
              <div>&nbsp;</div>
            )}
          </div>
        </div>
      </div>
      <div className={styles.tableBorderRight}></div>
    </div>
  );
};

export default ChildClassTableFormBuilder;
