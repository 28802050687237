import useCurrentTableSupportedColumns from './useCurrentTableSupportedColumns';

/**
 * Checks whether the current table can be filtered, i.e. whether it has at least one column that
 * can be filtered.
 *
 * @returns Whether the current table is filterable.
 */
export const useIsCurrentTableFilterable = () => {
  const columns = useCurrentTableSupportedColumns();

  const hasNoFilters = Object.values(columns).every(
    ({ predicates, search_key }) => !predicates?.length || search_key
  );

  return {
    isFilterable: !hasNoFilters,
  };
};
