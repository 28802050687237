import React, { useCallback, useEffect, useMemo } from 'react';
import useInPlaceEdit from '../useInPlaceEdit';
import InPlaceEditWrapper from '../components/InPlaceEditWrapper';
import { Select } from 'components/lib/Select';
import clsx from 'clsx';
import { IN_PLACE_EDIT_URL } from 'utils/testIds';
import { Input } from 'components/lib/Input';
import { useInplaceEditUrl } from './hooks';
import useInPlaceEditUrlStyles from './styles';
import { useIntl } from 'react-intl';
import { InPlaceEditUrlProps } from './types';
import { URLIcon } from 'components/Icon';
import { URL_REGEX } from 'utils/consts';
import FieldValidationMessage from '../components/FieldValidationMessage';
import { Dropdown } from 'components/lib/Dropdown';
import TooltipString from 'components/TooltipString';

const InPlaceEditUrl = <R extends Object>({
  label,
  required,
  value,
  disabled,
  propertyName,
  patchUrl,
  onSaveSuccess,
  readOnly,
  size,
  withUnderline,
}: InPlaceEditUrlProps<R>) => {
  const {
    isEditMode,
    setTempValue,
    isSaving,
    setIsEditModeOn,
    setIsEditModeOff,
    setErrorMessage,
    inputWrapperRef,
    errorMessage,
    editWrapperRef,
  } = useInPlaceEdit({
    initialValue: value,
    patchUrl,
    propertyName,
    onSaveSuccess,
  });

  const intl = useIntl();
  const reValidateField = useCallback(
    (protocol?: string, url?: string) => {
      if ((!protocol?.length || !url?.length) && required) {
        setErrorMessage(
          intl.formatMessage({
            id: 'errors.ThisFieldIsRequired',
            defaultMessage: 'This field is required',
          })
        );

        return;
      }

      if (url !== '' && !URL_REGEX.test(`${protocol}${url}`)) {
        setErrorMessage(
          intl.formatMessage(
            {
              id: 'errors.UrlIsInvalid',
              defaultMessage: '{label} format is invalid',
            },
            { label }
          )
        );

        return;
      }
      setErrorMessage(undefined);
    },
    [intl, setErrorMessage, label, required]
  );

  const onChange = useCallback(
    (val: string | undefined) => {
      setTempValue(val);
    },
    [setTempValue]
  );

  const hooks = useInplaceEditUrl({
    onChange,
    value,
    reValidateField,
    setIsEditModeOff,
  });

  useEffect(() => {
    if (!isEditMode) return;

    const onKeyPress = (event: KeyboardEvent) => {
      if (event.key !== 'Escape') return;

      hooks.onCancel();
    };

    document.addEventListener('keydown', onKeyPress);

    return () => {
      document.removeEventListener('keydown', onKeyPress);
    };
  }, [hooks, isEditMode, setIsEditModeOff]);

  const styles = useInPlaceEditUrlStyles({ errorMessage: !!errorMessage });

  const onViewClick = (event?: React.MouseEvent) => {
    if (readOnly) {
      // Prevent from opening the link in the same tab when clicked on Summary view

      return;
    }

    if (disabled) return;

    if (event?.ctrlKey || event?.metaKey) {
      //Allow user to open links in new tab with ctrl key
      return;
    }
    if (!!event) event.preventDefault();
    setIsEditModeOn();
  };

  const tooltipText: string = useMemo(() => {
    if (readOnly || !value?.length) {
      return '';
    }

    return intl.formatMessage({
      id: 'misc.linkTooltip',
      defaultMessage: 'Press Ctrl + Click to follow the link',
    });
  }, [readOnly, value, intl]);

  const viewContent = useMemo(() => {
    if (!value?.length) return '-';
    return readOnly ? (
      <TooltipString
        text={value}
        href={value}
        tagName='a'
        target='_blank'
        className={styles.singleLinePreview}
      />
    ) : (
      <a
        href={value}
        target='_blank'
        rel='noopener noreferrer'
        className={styles.singleLinePreview}
      >
        {value}
      </a>
    );
  }, [readOnly, styles.singleLinePreview, value]);

  return (
    <div className={styles.urlInputWrapper}>
      <InPlaceEditWrapper
        {...{
          isEditMode,
          isSaving,
          label,
          required,
          disabled,
          inputWrapperRef,
          onViewClick,
          readOnly,
          size,
          withUnderline,
          allowClickWhenDisabled: true,
          closeIconClassName: styles.closeIcon,
        }}
        onCloseClick={hooks.onCancel}
        tooltipText={tooltipText}
        actionButtonOnTop={isEditMode}
        maxWidth={350}
        editContent={
          <Dropdown
            visible={!isSaving && !!errorMessage}
            getPopupContainer={trigger => trigger.parentElement ?? document.body}
            overlay={<FieldValidationMessage error={errorMessage} />}
          >
            <div
              onBlur={hooks.onChangeWithValues}
              data-testid={IN_PLACE_EDIT_URL}
              className={styles.inPlaceEdit}
            >
              <Input
                className={styles.dropdownWrapper}
                value={hooks.url}
                onChange={hooks.handleChangeURL}
                addonBefore={
                  <Select
                    getPopupContainer={(trigger) => trigger.parentNode}
                    value={hooks.protocol}
                    onChange={hooks.handleSetProtocol}
                    options={hooks.options.map(option => ({
                      value: option,
                      label: option,
                    }))}
                    className={styles.dropdown}
                    dropdownClassName={clsx(
                      styles.dropdownOptionsWrapper,
                      styles.dropdownOverlay,
                      {
                        [styles.dropdownOptionsWithError]:
                          !isSaving && !!errorMessage,
                      }
                    )}
                    dropdownRender={items => (
                      <div ref={editWrapperRef}>{items}</div>
                    )}
                  />
                }
              />
              <URLIcon className={styles.urlIcon} size={20} />
            </div>
          </Dropdown>
        }
        viewContent={viewContent}
      />
    </div>
  );
};

export default InPlaceEditUrl;
