import { Draggable } from 'react-beautiful-dnd';
import React from 'react';
import ResizeProvider from 'components/Table/components/ResizeProvider';
import { DEFAULT_COLUMNS_WIDTHS } from 'components/Table/Table.consts';
import { getColumnVarName } from 'components/Table/utils';
import clsx from 'clsx';
import {
  TABLE_SORTABLE_COLUMN_HEADER_TESTID,
  TABLE_SORTABLE_RESIZER_TESTID,
} from 'utils/testIds';
import { HeaderCell } from 'components/Table/components/HeaderCell/HeaderCell';
import { TableHeaderProps } from './TableHeader.types';
import { useTableContext } from 'contexts/TableContext';
import { useTableStyles } from 'components/Table/Table.styles';
import { useHasVerticalScroll } from 'components/Table/hooks/useHasVerticalScroll';
import { useTableRowContext } from 'contexts/TableRowContext';
import { TableDensity } from 'components/Table/enums';
import { TABLE_HEAD_ROW_ID } from 'utils/elementsIds';
import { useContentWrapperContext } from 'contexts/ContentWrapperContext';
import useGlobalStyle from 'hooks/useGlobalStyle';
import { useAddColumns } from './hooks';
import { useTableHeadersContext } from 'components/Table/contexts/TableHeadersContext';
import AddColumn from 'components/AddColumn';
import Resizer from 'components/Table/components/Resizer';

export const TableHeader = ({
  column,
  headerGroup,
  index,
  getItemStyle,
}: TableHeaderProps) => {
  const {
    setHiddenColumns,
    hiddenColumns,
    visibleColumns,
    setColumnOrder,
    isEditMode,
    headerWrapperRef,
    columnResizing,
    dragAndDrop,
  } = useTableHeadersContext();

  const { isDraggingColumn } = dragAndDrop;

  const {
    withBordersAroundTable,
    onColumnResize,
    fullWidth: isFullWidth,
    handleSearchChange,
    withoutSort,
    preventInteractionWithColumns,
    preventFromHideColumns: unhideableColumns,
  } = useTableContext();

  const { density, onActionCellClick } = useTableRowContext();
  const { disabledEdition } = useContentWrapperContext();

  const hasScrollY = useHasVerticalScroll();

  const globalStyles = useGlobalStyle();
  const styles = useTableStyles({
    withBordersAroundTable,
    hasScrollY,
    fullWidth: isFullWidth,
    density: density ?? TableDensity.Default,
  });

  const {
    onAddNewColumns,
    addColumnToHidden,
    onTogglePopover,
    shouldBeVisible,
    indexToAddColumns,
  } = useAddColumns({
    setHiddenColumns,
    hiddenColumns,
    visibleColumns,
    setColumnOrder,
  });

  const tableHeadRowArea = document.getElementById(TABLE_HEAD_ROW_ID);
  const tableHeaderRowOffsets = tableHeadRowArea?.getBoundingClientRect();

  return (
    <Draggable
      draggableId={column.id}
      index={index}
      isDragDisabled={
        !column.accessor || !isEditMode || indexToAddColumns !== undefined
      }
    >
      {(provided, snapshot) => (
        <ResizeProvider
          onColumnResize={onColumnResize}
          wrapperRef={headerWrapperRef}
          columnKey={column.id}
        >
          {({ cellRef, initDrag }) => (
            <div
              {...column.getHeaderProps({
                style: {
                  minWidth: DEFAULT_COLUMNS_WIDTHS.minWidth,
                  width: `var(${getColumnVarName(column.id)})`,
                },
              })}
              className={clsx(styles.tableHeadCell, {
                [styles.tableHeadCellDragging]: snapshot.isDragging,
              })}
              data-testid={`${TABLE_SORTABLE_COLUMN_HEADER_TESTID}${column.id}`}
              ref={cellRef}
            >
              <HeaderCell
                {...{
                  provided,
                  column,
                  snapshot,
                  tableHeaderRowOffsets,
                  columnResizing,
                  disabledEdition,
                  editMode: isEditMode,
                  indexToAddColumns,
                  addColumnToHidden,
                  handleSearchChange,
                  withoutSort,
                  preventInteractionWithColumns,
                  unhidenableColumns: unhideableColumns,
                  getItemStyle,
                }}
              />
              <>
                {isEditMode && (
                  <AddColumn
                    onAddNewColumns={columns => onAddNewColumns(columns, index)}
                    {...{
                      onTogglePopover,
                      hiddenColumns,
                    }}
                    sourceIndex={index}
                    visible={shouldBeVisible(index)}
                    columnsWidth={headerGroup.headers.map(({ width }) =>
                      width ? Number(width) : 0
                    )}
                    columnsResizing={headerGroup.headers.map(
                      ({ isResizing }) => isResizing
                    )}
                    indexToAdd={indexToAddColumns}
                  />
                )}
                {(onActionCellClick ||
                  index < headerGroup.headers.length - 1) && (
                  <Resizer
                    onMouseDown={initDrag}
                    className={clsx([
                      'resizer',
                      {
                        resizing: column.isResizing,
                        [globalStyles.noPaddingRight]: isDraggingColumn,
                      },
                    ])}
                    data-testid={`${TABLE_SORTABLE_RESIZER_TESTID}${column.id}`}
                  />
                )}
              </>
            </div>
          )}
        </ResizeProvider>
      )}
    </Draggable>
  );
};
