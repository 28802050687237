import { TreeOfStrings } from 'components/ViewUsage/TreeOfStringsDisplay/types';
import { UserGroupObjectClassFieldUsageItem } from 'utils/types/api/userGroups.types';

/**
 * Transforms the API response class fields groups usage to a tree structure to be displayed
 * to the user in a format:
 * ObjectClass:
 *   -- Object type field
 *   -- Object type field
 *
 * All group usages belonging to the same ObjectClass will be grouped together.
 * @param objectClassesFields API response structure array of usages of groups within ObjectClassFields.
 * @returns a displayable tree of strings with ObjectClassFields list grouped under ObjectClasses that show where the groups are used.
 */
export const transformObjectClassFieldUsages = (
  objectClassesFields: UserGroupObjectClassFieldUsageItem[] | undefined
): TreeOfStrings[] => {
  objectClassesFields = objectClassesFields ?? [];
  const parentsMap = new Map<number, TreeOfStrings>();
  const tree: TreeOfStrings[] = [];

  objectClassesFields.forEach(classField => {
    let parentNode = parentsMap.get(classField.object_class_id);

    if (!parentNode) {
      parentNode = {
        id: classField.object_class_id,
        name: classField.object_class_name,
        children: [],
      };

      parentsMap.set(parentNode.id, parentNode);
      tree.push(parentNode);
    }

    if (!parentNode.children) {
      parentNode.children = [];
    }

    parentNode.children.push({
      id: classField.id,
      name: classField.name,
    });
  });

  return tree;
};
