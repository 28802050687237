import { useCallback, useState } from 'react';
import { SelectUserAndGroupOption } from 'utils/types/selectInput.types';
import {
  fetchUserFieldUsersAndGroups,
  prepareUserOptions,
  prepareUserGroupOptions,
} from '../utils';
import { EMPTY_OPTIONS } from '../consts/UserAndGroupOptions';
import { useCancelToken } from 'hooks/useCancelToken';
import axios from 'axios';
import { isNil } from 'lodash';

/**
 * Handles async state of API data for user class field available options for selection.
 */
export const useObjectClassUserFieldOptions = (
  objectClassFieldId: number | null,
  objectClassId: string | number | undefined,
  onOptionsFetched?: (options: SelectUserAndGroupOption) => void
) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [options, setOptions] = useState<SelectUserAndGroupOption | null>(null);

  const { cancelOngoingRequests, createCancelToken } = useCancelToken();

  const fetchOptions = useCallback(async () => {
    if (isNil(objectClassFieldId) || isNil(objectClassId)) {
      setOptions(EMPTY_OPTIONS);
      setIsLoading(false);
      return;
    }

    // Simple cache. If we have the options we do not refetch them. Full browser refresh is required to invalidate this cache.
    if (!isNil(options)) {
      return;
    }

    try {
      setIsLoading(true);
      setOptions(EMPTY_OPTIONS);

      cancelOngoingRequests();
      const cancelToken = createCancelToken();

      const { users, groups } = await fetchUserFieldUsersAndGroups(
        objectClassId,
        objectClassFieldId,
        cancelToken
      );

      const userOptions = prepareUserOptions(Object.values(users));
      const groupOptions = prepareUserGroupOptions(Object.values(groups));

      const newOptions = {
        groups: groupOptions,
        users: userOptions,
      };

      setOptions(newOptions);
      setIsLoading(false);

      if (onOptionsFetched) {
        onOptionsFetched(newOptions);
      }
    } catch (error) {
      if (axios.isCancel(error)) {
        return;
      }

      setOptions(EMPTY_OPTIONS);
      setIsLoading(false);
    }
  }, [
    cancelOngoingRequests,
    createCancelToken,
    objectClassFieldId,
    objectClassId,
    onOptionsFetched,
    options,
  ]);

  return { options: options ?? EMPTY_OPTIONS, isLoading, fetchOptions };
};
