import useData from "hooks/useData";
import { useEffect } from "react";
import { generatePath } from "react-router-dom";
import { OBJECT_CLASS_USAGE } from "utils/endpoints";
import { ObjectClassUsage } from "utils/types/api/objectClasses.types";

export const useObjectClassViewUsageData = (id?: string) => {
    const [data, { loading, error, fetchData }] = useData<ObjectClassUsage>(
        id !== undefined
            ? generatePath(OBJECT_CLASS_USAGE, {
                id,
            })
            : '',
        {
            fetchOnLoad: false,
        }
    );

    useEffect(() => {
        if (id !== undefined) fetchData();
    }, [fetchData, id]);

    return { error, loading, data };
};