import { FIELD_PREFIX } from 'utils/consts';
import { CustomTableGroupKeys } from 'contexts/types';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  getCurrentTable,
  getCurrentTableColumns,
} from 'store/selectors/filtersSelectors';
import TablesType from 'utils/Enums/TablesType';
import { ColumnsMetadata } from 'utils/types/api/table.types';
import { getCurrentTableSelectedColumns } from 'store/selectors/generalSelectors';
import { usePredicates } from './usePredicates';

const useCurrentTableSupportedColumns = (
  classID?: string,
  customTableName?: TablesType
) => {
  const tableColumns = (useSelector(
    getCurrentTableColumns(classID, customTableName)
  ) ?? {}) as MappedObject<ColumnsMetadata>;

  const currentTableNameStore = useSelector(getCurrentTable);
  const currentTableName = customTableName ?? currentTableNameStore;
  const { predicates } = usePredicates();

  const classFieldsColumns = useSelector(
    getCurrentTableSelectedColumns(classID, customTableName)
  );

  const classFields = useMemo(() => {
    if (
      currentTableName !== TablesType.ObjectRecords &&
      currentTableName !== TablesType.NestedTable
    ) {
      return {};
    }

    return [...(classFieldsColumns ?? [])]?.reduce((prev, curr) => {
      if (curr) {
        return {
          ...prev,
          [`${curr?.alias}`]: {
            ...curr,
            groupKey: CustomTableGroupKeys.ObjectClassFields,
          },
        };
      }
      return prev;
    }, {});
  }, [currentTableName, classFieldsColumns]);

  return useMemo(() => {
    const currentColumns = Object.fromEntries(
      Object.entries(tableColumns).filter(
        ([, { type, alias }]) =>
          type && predicates[type] && !alias.startsWith(FIELD_PREFIX)
      )
    );

    const additionalColumns = tableColumns?.configuration?.schema?.schema.reduce(
      (total, curr) => ({
        ...total,
        [curr.alias]: curr,
      }),
      {}
    );

    return {
      ...currentColumns,
      ...additionalColumns,
      ...classFields,
    } as MappedObject<ColumnsMetadata>;
  }, [tableColumns, classFields, predicates]);
};

export default useCurrentTableSupportedColumns;
