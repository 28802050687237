import { usePredicates } from 'hooks/usePredicates';
import { useValidateCurrentTableFilters } from 'hooks/useValidateCurrentTableFilters';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setAppliedFiltersForTable } from 'store/actions/filtersActions';
import { getCurrentTable } from 'store/selectors/filtersSelectors';
import { getCurrentTablePreferenceFilter } from 'store/selectors/preferencesSelectors';
import { parseFilterToQueryParam } from './utils/parseFilterToQueryParam';

/**
 * Allows to convert current table filters to valid query string.
 *
 * @returns Function that converts current table filters to query string.
 */
export const useFiltersToQueryString = () => {
  const dispatch = useDispatch();

  const currentTableFilters = useSelector(getCurrentTablePreferenceFilter);
  const currentTableName = useSelector(getCurrentTable);

  const { areFiltersValid } = useValidateCurrentTableFilters();
  const { predicates } = usePredicates();

  const getFiltersQueryString = useCallback(() => {
    if (!areFiltersValid()) {
      return null;
    }

    const queryParams = currentTableFilters.map(filter => {
      const predicate = predicates[filter.type][filter.value.predicateKey];

      const queryParam = parseFilterToQueryParam(filter, predicate);

      dispatch(
        setAppliedFiltersForTable({ id: currentTableName, value: true })
      );

      return queryParam;
    });

    return queryParams.join('&');
  }, [
    currentTableFilters,
    currentTableName,
    predicates,
    areFiltersValid,
    dispatch,
  ]);

  return { getFiltersQueryString };
};
