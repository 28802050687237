import { useCallback, useEffect, useState } from 'react';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import { FormikHelpers, useFormikContext } from 'formik';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { usePostWithToasts } from '../../hooks/usePostWithToasts';
import { FormMode } from '../../utils/Enums/FormMode';
import {
  ACTIVATE_ACCOUNT,
  ACTIVATE_ACCOUNT_USER,
  RENEW_TOKEN,
} from '../../utils/endpoints';
import useData from '../../hooks/useData';
import { ActivateAccountFormFields } from './enums';
import useValidationSchemaBuilder from '../../hooks/useValidationSchemaBuilder';
import { OptionSelect } from '../../utils/types/selectInput.types';
import { ActivateAccountOptions } from './consts';
import { useActivateAccountContext } from './components/ActivateAccountContext';
import {
  ActivateAccountData,
  ActivateAccountFormValues,
  ActivateAccountParams,
} from './types';
import routes, { nonProtectedRoutes } from '../../utils/routingPaths';
import { getAccessToken } from '../../store/selectors/authSelectors';
import { setTokens } from '../../store/actions/authActions';
import { AuthState } from '../../utils/types/api/auth.types';
import { apiCall } from '../../utils/api';
import { StatusCodes } from 'http-status-codes';
import showDefaultErrorToast from 'utils/functions/showDefaultErrorToast';

export const useActivateAccountFormikContext = () => useFormikContext();

export const useRenewLink = () => {
  const { token } = useParams();
  const [isSuccessRenewLink, setSuccessRenewLink] = useState(false);

  const renewLink = useCallback(async () => {
    try {
      await apiCall.post(generatePath(RENEW_TOKEN, { token }));

      setSuccessRenewLink(true);
    } catch {
      showDefaultErrorToast();
    }
  }, [token]);

  return { isSuccessRenewLink, renewLink };
};

export const useActivateAccountData = () => {
  const { token } = useParams<ActivateAccountParams>();
  const history = useHistory();
  const accessToken = useSelector(getAccessToken);
  const [activateAccountData, { loading, error }] = useData<
    ActivateAccountData
  >(generatePath(ACTIVATE_ACCOUNT, { token: token || !token }), {
    fetchOnLoad: !!token,
  });

  useEffect(() => {
    if (!!accessToken) history.replace(routes.HOMEPAGE);

    if (error?.status === StatusCodes.FORBIDDEN)
      history.replace(nonProtectedRoutes.LOGIN);
  }, [history, accessToken, error]);

  return {
    activateAccountData,
    loadingActivateAccountData: loading,
    error,
  };
};

export const useActivateAccountForm = () => {
  const intl = useIntl();
  const history = useHistory();
  const dispatch = useDispatch();
  const { data } = useActivateAccountContext();
  const { token } = useParams<ActivateAccountParams>();
  const { sendData } = usePostWithToasts<AuthState, AuthState>(FormMode.Create);
  const [isPasswordFulfilled, setIsPasswordFulfilled] = useState(false);
  const validationBuilder = useValidationSchemaBuilder<
    OptionSelect | undefined
  >(ActivateAccountOptions);
  const [initialValues, setInitialValues] = useState({
    [ActivateAccountFormFields.Email]: '',
    [ActivateAccountFormFields.Password]: '',
    [ActivateAccountFormFields.ConfirmPassword]: '',
  });

  useEffect(() => {
    setInitialValues(prev => ({
      ...prev,
      ...data,
    }));
  }, [data]);

  const callback = (data?: AuthState) => {
    if (data) dispatch(setTokens(data));

    history.push(routes.HOMEPAGE);
  };

  const onSubmit = async (
    values: ActivateAccountFormValues,
    {
      setErrors,
      setFieldError,
      setSubmitting,
    }: FormikHelpers<ActivateAccountFormValues>
  ) => {
    if (
      values[ActivateAccountFormFields.Password] !==
      values[ActivateAccountFormFields.ConfirmPassword]
    ) {
      return setFieldError(
        ActivateAccountFormFields.ConfirmPassword,
        intl.formatMessage({
          defaultMessage: 'Passwords do not match',
          id: 'errors.noMatchPassword',
        })
      );
    }

    await sendData({
      url: generatePath(ACTIVATE_ACCOUNT_USER, { token }),
      data: { password: values[ActivateAccountFormFields.Password] },
      fields: ActivateAccountFormFields,
      callback,
      setErrors,
      setSubmitting,
    });
  };

  return {
    initialValues,
    onSubmit,
    setIsPasswordFulfilled,
    isPasswordFulfilled,
    ...validationBuilder,
  };
};
