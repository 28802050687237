import { ParsedTypes, ParsedOptions, TypeValues } from "../types";

export const parseTypes = (values: TypeValues[]) =>
    values.reduce<ParsedTypes>((result, { value: type, options }) => {
        const parseOptionsToObject = Array.isArray(options)
            ? options.reduce<ParsedOptions>(
                (items, { name, ...rest }) => ({ ...items, [name]: rest }),
                {}
            )
            : {};

        return {
            ...result,
            [type]: parseOptionsToObject,
        };
    }, {} as ParsedTypes);