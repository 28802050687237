import React, { useCallback, useMemo } from 'react';
import Pagination from 'components/Table/components/Pagination';
import { FilterEditorButton } from 'components/FilterEditorButton';
import { Space } from 'components/lib/Space';
import Tooltip from 'components/lib/Tooltip';
import { FormattedMessage } from 'react-intl';
import ToolButton from 'components/ToolButton';
import {
  CheckIcon,
  CloseIcon,
  ColumnConfigurationIcon,
  PlusIcon,
  RefreshIcon,
} from 'components/Icon';
import { ButtonOutlined, ButtonPrimary } from 'components/lib/Button';
import useTableWrapperStyles from '../Table.styles';
import { useDispatch, useSelector } from 'react-redux';
import { useContentWrapperContext } from 'contexts/ContentWrapperContext';
import {
  getCurrentTablePreferenceFilter,
  getSelectedColumns,
  getSelectedObjectClassId,
  isSavingPreferences,
} from 'store/selectors/preferencesSelectors';
import { useTableContext } from 'contexts/TableContext';
import { FIELD_PREFIX } from 'utils/consts';
import clsx from 'clsx';
import { CustomOverLimitStatus, PaginationWrapperProps } from '../Table.types';
import { useHistory } from 'react-router-dom';
import { protectedRoutes } from 'utils/routing';
import usePermissions from 'hooks/usePermissions';
import { useMaxItemsMessage } from '../hooks/useMaxItemsMessage';
import { useRedirectFromTableWithParams } from 'hooks/useRedirectFromTableWithParams';
import {
  CANCEL_EDIT_MODE_TESTID,
  COLUMN_CONFIGURATION_BUTTON_TESTID,
  RESET_COLUMNS_TO_DEFAULT_TESTID,
  SAVE_COLUMNS_CHANGES_TESTID,
  TABLE_ACTIONS_BUTTONS_TESTID,
  TABLE_ADD_NEW_ITEM_TESTID,
  TABLE_ADD_NEW_ITEM_TOOLTIP_TESTID,
  TABLE_COLUMN_CONFIGURATION_BUTTONS_TESTID,
  TABLE_SETTINGS_TESTID,
} from 'utils/testIds';
import FilterSelect from 'components/FilterSelect';
import ViewTypeButton from './ViewTypeButton';
import { useFlexLayoutContext } from 'components/lib/FlexLayout/FlexLayoutContext';
import FlexLayoutWindows from 'utils/Enums/FlexLayoutWindows';
import { setAppliedFiltersForTable } from 'store/actions/filtersActions';
import GTag from 'providers/IntlProviderWrapper/gTag';
import { GTagEventButtonName } from 'providers/IntlProviderWrapper/gTag/types';
import { makeKnownColumns } from '../utils';
import { getObjectRecordsSelectedColumns } from 'store/selectors/objectRecordsSelectors';
import { updatePreferences } from 'store/actions/preferencesActions';
import TablesType from 'utils/Enums/TablesType';
import TasksQuickFilters from './QuickFilters/TasksQuickFilters';
import { PreferencesTypes } from 'utils/types/api/preferences.types';

const CREATE_PATH = '/create';

const PaginationWrapper: React.FC<PaginationWrapperProps> = ({
  tableState,
  setCancelConfigModalVisible,
  setShouldShowConfirmation,
  hasChanges,
}) => {
  const {
    total,
    editModeEnabled,
    onSaveTableConfiguration,
    toggleEditMode,
    withFilters,
    setWithFilters,
    additionalHeaderActions,
    filteredCount,
    currentPage,
    onPageChange,
    pageSize,
    tablePageSizes,
    onPageSizeChange,
    isInfinite,
    onNewClick,
    withColumnConfiguration,
    handleSearchChange,
    filterSelectOptions,
    searchValue,
    setIsVisibleBulkSelectionColumn,
    setBulkSelectionList,
    hasListPermissions,
    isFetching: loading,
    setPagination,
    withPaginationControls,
    setWithPaginationControls,
    currentTableName,
    columns,
    alwaysDisplayPagination,
    customOverLimit,
  } = useTableContext();
  const { closePanelIfOpen } = useFlexLayoutContext();
  const dispatch = useDispatch();
  const appliedFilters = useSelector(getCurrentTablePreferenceFilter);
  const redirect = useRedirectFromTableWithParams();
  const history = useHistory();
  const newButtonMessage = {
    id: 'misc.create',
    defaultMessage: 'Create',
  };

  const styles = useTableWrapperStyles({
    isInfinite,
  });
  const savingPreferences = useSelector(isSavingPreferences);
  const { disabledEdition } = useContentWrapperContext();
  const { hasPermissionToRoute } = usePermissions();
  const { limitItems, maxItemsMessage } = useMaxItemsMessage();
  const selectedColumns = useSelector(getObjectRecordsSelectedColumns);
  const existingSelectedColumns = useSelector(getSelectedColumns);
  const selectedClassID = useSelector(getSelectedObjectClassId);
  const {
    location: { pathname },
  } = history;
  const CREATE_ROUTE = `${pathname}${CREATE_PATH}`;

  const onClickSavePreferences = useCallback(() => {
    if (selectedClassID && currentTableName === TablesType.ObjectRecords) {
      dispatch(
        updatePreferences(PreferencesTypes.TableLayoutPreferences, {
          selectedColumns: {
            ...(existingSelectedColumns ?? {}),
            [selectedClassID]: selectedColumns?.length
              ? selectedColumns?.map(({ id }) => id.toString())
              : undefined,
          },
        })
      );
    }

    if (tableState) {
      onSaveTableConfiguration({
        ...tableState,
        knownColumns: makeKnownColumns(columns),
        hiddenColumns: tableState.hiddenColumns?.filter(
          col => !col.startsWith(FIELD_PREFIX)
        ),
      });
    }
  }, [
    selectedClassID,
    tableState,
    dispatch,
    existingSelectedColumns,
    selectedColumns,
    currentTableName,
    onSaveTableConfiguration,
    columns,
  ]);

  const onCancelConfigurationMode = useCallback(() => {
    if (!hasChanges) {
      toggleEditMode();

      return;
    }

    setCancelConfigModalVisible(true);
  }, [hasChanges, setCancelConfigModalVisible, toggleEditMode]);

  const isCreateRouteExists = useMemo(
    () =>
      protectedRoutes.find(
        ({ path }) => path === `${pathname}${CREATE_PATH}`
      ) !== undefined,
    [pathname]
  );

  const defaultOnNewClick = useCallback(() => {
    redirect(`${pathname}${CREATE_PATH}`);
  }, [redirect, pathname]);

  const onCreateClick = () => {
    GTag.pushGtagButtonEvent(GTagEventButtonName.Create);

    if (onNewClick) {
      onNewClick();

      return;
    }

    defaultOnNewClick();
  };

  const onChangeFilterSelect = (value: string) => {
    GTag.pushGtagButtonEvent(value);
    const {
      searchKey,
      isVisibleBulkSelection,
      areVisibleFilters = true,
      areVisiblePaginationControls = true,
    } = filterSelectOptions?.find(option => option.value === value) || {};

    if (searchKey) {
      const isNotFirstPaginationPage = currentPage !== 1;

      setWithFilters(areVisibleFilters);

      if (!areVisibleFilters) {
        closePanelIfOpen(FlexLayoutWindows.FilterEditor);
      }

      if (appliedFilters.length > 0) {
        dispatch(
          setAppliedFiltersForTable({
            id: currentTableName,
            value: areVisibleFilters,
          })
        );
      }

      setWithPaginationControls(areVisiblePaginationControls);

      handleSearchChange({ value, searchKey }, isNotFirstPaginationPage);
      setBulkSelectionList([]);
      setIsVisibleBulkSelectionColumn(!!isVisibleBulkSelection);

      // resetting pagination to initial page + side effect will be forcing fetchData with new pagination state
      if (isNotFirstPaginationPage) setPagination(1, pageSize);
    }
  };

  const onColumnConfigurationClick = () => {
    GTag.pushGtagButtonEvent(GTagEventButtonName.ColumnConfiguration);
    toggleEditMode();
  };

  const isDisabled = savingPreferences || disabledEdition || !hasChanges;

  if (!editModeEnabled) {
    return (
      <div
        className={clsx(styles.actionsFilters, styles.filtersBar)}
        data-testid={TABLE_SETTINGS_TESTID}
      >
        <Space
          data-testid={TABLE_ACTIONS_BUTTONS_TESTID}
          className={styles.headerSpace}
        >
          <>
            {((isCreateRouteExists &&
              hasPermissionToRoute(CREATE_ROUTE) &&
              (limitItems !== undefined || customOverLimit !== undefined)) ||
              onNewClick) && (
              <>
                {(!!customOverLimit &&
                  customOverLimit === CustomOverLimitStatus.OVER) ||
                (!customOverLimit && (limitItems as number) <= total) ? (
                  <Tooltip
                    title={maxItemsMessage}
                    overlayClassName={styles.maxTooltip}
                  >
                    <div className={styles.buttonNoPointerEvents}>
                      <ButtonPrimary
                        icon={<PlusIcon size={10} />}
                        onClick={onCreateClick}
                        data-testid={TABLE_ADD_NEW_ITEM_TOOLTIP_TESTID}
                        disabled
                      >
                        <FormattedMessage {...newButtonMessage} />
                      </ButtonPrimary>
                    </div>
                  </Tooltip>
                ) : (
                  <ButtonPrimary
                    icon={<PlusIcon size={10} />}
                    onClick={onCreateClick}
                    data-testid={TABLE_ADD_NEW_ITEM_TESTID}
                  >
                    <FormattedMessage {...newButtonMessage} />
                  </ButtonPrimary>
                )}
              </>
            )}
            {!!additionalHeaderActions && additionalHeaderActions}
            <Space className={styles.buttonsWrapper}>
              {withFilters && <FilterEditorButton />}
              <ViewTypeButton />
              {!!filterSelectOptions && (
                <FilterSelect
                  options={filterSelectOptions.map(({ key, value, label }) => ({
                    key,
                    value,
                    label,
                  }))}
                  value={searchValue?.value}
                  onChange={onChangeFilterSelect}
                />
              )}
              {withColumnConfiguration && (
                <Tooltip
                  title={
                    <FormattedMessage
                      id='misc.columnConfiguration'
                      defaultMessage='Column configuration'
                    />
                  }
                >
                  <ToolButton
                    icon={<ColumnConfigurationIcon size={15} />}
                    onClick={onColumnConfigurationClick}
                    data-testid={COLUMN_CONFIGURATION_BUTTON_TESTID}
                  />
                </Tooltip>
              )}
            </Space>
            {currentTableName === TablesType.Tasks && <TasksQuickFilters />}
          </>
        </Space>
        {((hasListPermissions && withPaginationControls && !!filteredCount) ||
          alwaysDisplayPagination) && (
          <Pagination
            totalCount={total}
            {...{
              filteredCount,
              currentPage,
              pageSize,
              tablePageSizes,
              isInfinite,
              loading,
            }}
            onPageChange={(page: number) => {
              onPageChange(page);
            }}
            onPageSizeChange={(page: string | number) => {
              onPageSizeChange(page);
            }}
          />
        )}
      </div>
    );
  }

  return (
    <Space
      className={styles.filtersBar}
      data-testid={TABLE_COLUMN_CONFIGURATION_BUTTONS_TESTID}
    >
      <ButtonPrimary
        disabled={isDisabled}
        icon={<CheckIcon size={12} />}
        onClick={onClickSavePreferences}
        loading={savingPreferences}
        data-testid={SAVE_COLUMNS_CHANGES_TESTID}
      >
        <FormattedMessage id='misc.saveChanges' defaultMessage='Save changes' />
      </ButtonPrimary>
      <ButtonOutlined
        icon={<RefreshIcon size={12} />}
        disabled={savingPreferences || disabledEdition}
        onClick={() => setShouldShowConfirmation(true)}
        data-testid={RESET_COLUMNS_TO_DEFAULT_TESTID}
      >
        <FormattedMessage
          id='misc.resetToDefault'
          defaultMessage='Reset to default'
        />
      </ButtonOutlined>
      <ButtonOutlined
        icon={<CloseIcon size={15} />}
        onClick={onCancelConfigurationMode}
        disabled={disabledEdition}
        data-testid={CANCEL_EDIT_MODE_TESTID}
      >
        <FormattedMessage id='misc.cancel' defaultMessage='Cancel' />
      </ButtonOutlined>
    </Space>
  );
};

export default PaginationWrapper;
