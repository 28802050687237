import {
  getCurrentTableFilters,
  getCurrentTable,
} from 'store/selectors/filtersSelectors';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useState, useMemo, useRef, useEffect } from 'react';
import {
  setFilters,
  setApplyFilter,
  FilterValue,
  resetCurrentFilters,
  setAppliedFiltersForTable,
  clearFilters,
  removeFilterColumn,
} from 'store/actions/filtersActions';
import FlexLayoutWindows from 'utils/Enums/FlexLayoutWindows';
import { ColumnsMetadata } from 'utils/types/api/table.types';
import {
  toggleSidebar,
  setSidebarWidth,
} from 'store/actions/flexLayoutActions';
import { FILTERS_WRAPPER } from 'utils/elementsIds';
import useFlexLayoutWindow from 'hooks/useFlexLayoutWindow';
import useAppliedFilters from 'hooks/useAppliedFilters';
import useCurrentTableSupportedColumns from 'hooks/useCurrentTableSupportedColumns';
import isEmpty from 'lodash/isEmpty';
import TablesType from 'utils/Enums/TablesType';
import { PredicateSet } from 'utils/types/predicates.types';
import { withoutEmptyPredicates } from '../utils';
import { usePredicates } from 'hooks/usePredicates';

export const useFilterEditor = (classID?: string) => {
  const dispatch = useDispatch();
  const filters = useSelector(getCurrentTableFilters);
  const prevFilters = useRef(filters).current;
  const appliedFilters = useAppliedFilters();
  const currentTable = useSelector(getCurrentTable);
  const columns = useCurrentTableSupportedColumns(classID);
  const { predicates } = usePredicates();

  const [showClearFilterModal, setShowClearFilterModal] = useState(false);
  const filtersSidebarWidth = useRef<null | DOMRect>(null);
  const { isExternal, onOpenTab } = useFlexLayoutWindow(
    FlexLayoutWindows.FilterEditor
  );

  const [tmpPredicateSet, setTmpPredicateSet] = useState<PredicateSet>();

  const usedColumns = useMemo(() => {
    return filters.map(filter => filter.column);
  }, [filters]);

  const filterableColumns: Record<string, ColumnsMetadata> = useMemo(() => {
    return Object.fromEntries(
      Object.entries(columns).filter(
        ([, { type }]) => !isEmpty(predicates[type])
      )
    );
  }, [columns, predicates]);

  const isFiltersLimitReached = useMemo(() => {
    // Filters in the records table are fetched from two separate endpoints.
    // The data from autocomplete is stored only within it, so it's not possible to calculate the min limit
    if (currentTable === TablesType.ObjectRecords) {
      return usedColumns.length >= 10;
    }

    const filtersLimit = Math.min(
      10,
      Object.keys(columns).filter(key => withoutEmptyPredicates(columns)(key))
        .length
    );

    return filtersLimit === usedColumns.length;
  }, [columns, currentTable, usedColumns.length]);

  const onPredicateSetChange = useCallback(
    (predicateSet: PredicateSet | undefined) =>
      setTmpPredicateSet(predicateSet),
    []
  );

  const onCreateFilter = useCallback(
    data => {
      const columnName = columns[data.value ?? data]?.alias ?? data.key; //verify if selected column exists in already redux-stored columns

      if (currentTable) {
        const newFilters: FilterValue[] = !filters.length
          ? [
              ...filters,
              {
                column: columnName,
                type: columns[columnName]?.type ?? data.field_type, //if selected column isn't in redux store, select type based on incoming data (only data from classfields contains such info)
                predicateSet: tmpPredicateSet,
                label: columns[columnName]?.label ?? data.label,
                predicates: columns[columnName]?.predicates ?? data.predicates,
                values: columns[columnName]?.values ?? data.values,
                value: {
                  predicateKey: '',
                  predicateValues: {},
                  predicateArgs: [],
                },
              },
            ]
          : [
              {
                column: '',
                type: '',
                label: data.label,
                predicateSet: tmpPredicateSet,
                predicates: [],
                value: {
                  predicateKey: '',
                  predicateValues: {},
                  predicateArgs: [],
                },
              },
              ...filters,
            ];

        dispatch(setFilters(currentTable, newFilters));
        setTmpPredicateSet(undefined);
      }
    },
    [columns, currentTable, dispatch, filters, tmpPredicateSet]
  );

  const removeEmptyFilters = useCallback(() => {
    if (currentTable) {
      const allFilters = Object.values(filters);
      const filledFilters = allFilters.filter(filter => filter.column !== '');
      dispatch(setFilters(currentTable, filledFilters));
    }
  }, [currentTable, dispatch, filters]);

  const resetFilters = useCallback(() => {
    dispatch(resetCurrentFilters());
    dispatch(setAppliedFiltersForTable({ id: currentTable, value: false }));
  }, [currentTable, dispatch]);

  const onApplyFilters = useCallback(() => {
    if (filters.length === 0 && prevFilters.length === 0 && !appliedFilters) {
      return;
    }

    if (filters.length === 0 && (prevFilters.length > 0 || appliedFilters)) {
      resetFilters();
      return;
    }

    removeEmptyFilters();
    dispatch(setApplyFilter(true, { sendToApi: true }));
  }, [
    dispatch,
    filters.length,
    prevFilters.length,
    removeEmptyFilters,
    resetFilters,
    appliedFilters,
  ]);

  const onClearFilter = useCallback(() => {
    dispatch(clearFilters());
    resetFilters();
    setShowClearFilterModal(false);
  }, [dispatch, resetFilters]);

  const onClickClearFilters = useCallback(() => {
    if (!isExternal) {
      setShowClearFilterModal(true);
    } else {
      onClearFilter();
    }
  }, [isExternal, onClearFilter, setShowClearFilterModal]);

  const onRemoveFilter = useCallback(
    (columnName: string) => {
      dispatch(removeFilterColumn(currentTable ?? '', columnName));
    },
    [currentTable, dispatch]
  );

  useEffect(() => {
    if (filtersSidebarWidth.current === null) {
      const sidebarElement =
        window.document
          .querySelector(`#${FILTERS_WRAPPER}`)
          ?.getBoundingClientRect() || null;

      filtersSidebarWidth.current = sidebarElement;

      if (sidebarElement) {
        dispatch(toggleSidebar(true));
        dispatch(setSidebarWidth(sidebarElement.width));
      }
    }
  }, [dispatch]);

  return {
    filters,
    onApplyFilters,
    onCreateFilter,
    filterableColumns,
    onClearFilter,
    usedColumns,
    showFilters: onOpenTab,
    showClearFilterModal,
    setShowClearFilterModal,
    isFiltersLimitReached,
    onRemoveFilter,
    onClickClearFilters,
    currentTable,
    tmpPredicateSet,
    onPredicateSetChange,
  };
};
