import { useFormikContext } from "formik";
import { ClassFieldFormFields } from "pages/ObjectClasses/enums";
import { useCallback } from "react";
import { ObjectClassFieldTypes } from "utils/types/api/objectClassesFields.types";
import { ClassFieldForm } from "../../../../../types";

export const useTypeChange = () => {
    const { values, setFieldValue, validateForm } = useFormikContext<
        ClassFieldForm
    >();

    const setDefaultValue = useCallback(
        (value: ObjectClassFieldTypes) => {
            const defaultValue = values[ClassFieldFormFields.DefaultValue];

            if (ObjectClassFieldTypes.Bool === value && !defaultValue) {
                return setFieldValue(ClassFieldFormFields.DefaultValue, false);
            }

            setFieldValue(ClassFieldFormFields.DefaultValue, undefined);
        },
        [setFieldValue, values]
    );

    const setValidation = useCallback(
        (fieldType: ObjectClassFieldTypes) => {
            setFieldValue(ClassFieldFormFields.MaxValue, undefined);
            setFieldValue(ClassFieldFormFields.MinValue, undefined);

            setFieldValue(ClassFieldFormFields.MaxValues, undefined);
            setFieldValue(ClassFieldFormFields.MinValues, undefined);

            setFieldValue(ClassFieldFormFields.MinUsersValues, undefined);
            setFieldValue(ClassFieldFormFields.MaxUsersValues, undefined);

            setFieldValue(ClassFieldFormFields.MinGroupsValues, undefined);
            setFieldValue(ClassFieldFormFields.MaxGroupsValues, undefined);
            setFieldValue(ClassFieldFormFields.AllowSync, undefined);
            setFieldValue(ClassFieldFormFields.AllowMembersSelection, undefined);

            if (fieldType === ObjectClassFieldTypes.Document) {
                setFieldValue(ClassFieldFormFields.MaxFiles, 100);
            } else {
                setFieldValue(ClassFieldFormFields.MaxFiles, undefined);
            }

            // trigger validation to check updated fields
            // without this formik does not validate above values
            setTimeout(() => validateForm());
        },
        [setFieldValue, validateForm]
    );

    return useCallback(
        (value: ObjectClassFieldTypes) => {
            if (!!values[ClassFieldFormFields.MaxLength])
                setFieldValue(ClassFieldFormFields.MaxLength, undefined);

            if (
                ![ObjectClassFieldTypes.Enum, ObjectClassFieldTypes.Set].includes(value)
            ) {
                setFieldValue(ClassFieldFormFields.SelectionOptions, undefined);
            }

            setValidation(value);

            setDefaultValue(value);
        },
        [setDefaultValue, setFieldValue, setValidation, values]
    );
};