import {
  ACTIONS_COLUMN_WIDTH,
  BULK_SELECTION_COLUMN_WIDTH,
} from '../../../Table.consts';

export const calculateMinimumHeaderWidth = (
  totalColumnsWidth: number,
  hasActionCell: boolean,
  isVisibleBulkSelectionColumn: boolean
) => {
  return (
    totalColumnsWidth +
    (hasActionCell ? ACTIONS_COLUMN_WIDTH : 0) +
    (isVisibleBulkSelectionColumn ? BULK_SELECTION_COLUMN_WIDTH : 0)
  );
};
