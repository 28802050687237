import React, { useMemo, useEffect, useState, useCallback } from 'react';
import { Heading } from 'components/lib/Heading';
import { FormattedMessage } from 'react-intl';
import { Space } from 'components/lib/Space';
import { Checkbox, CheckboxGroupCustom } from 'components/lib/Checkbox';
import { Text } from 'components/lib/Text';
import { ButtonPrimary, ButtonOutlined } from 'components/lib/Button';
import { CloseIcon, PlusIcon } from 'components/Icon';
import useAddColumnStyles from './styles';
import { AddColumnListProps } from './types';
import { ObjectClassFieldMap } from 'components/lib/ChildClassComponent/types';
import {
  ADD_COLUMNS_POPOVER_TESTID,
  ADD_COLUMNS_POPOVER_TITLE_TESTID,
  ADD_COLUMNS_TESTID,
  CANCEL_ADDING_COLUMNS_TESTID,
} from 'utils/testIds';
import InfiniteScroll from 'react-infinite-scroll-component';
import take from 'lodash/take';
import drop from 'lodash/drop';
import { generatePath } from 'react-router-dom';
import { apiCall } from 'utils/api';
import { isSuccess } from 'utils/apiUtils';
import { OBJECT_CLASS_DETAILS_FIELDS } from 'utils/endpoints';
import showDefaultErrorToast from 'utils/functions/showDefaultErrorToast';
import { DEFAULT_COLUMNS_WIDTHS } from 'components/Table/Table.consts';
import clsx from 'clsx';
import {
  ChildClassColumns,
  ChildClassColumn,
} from 'components/formBuilder/formBuilder/types';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { FIELD_PREFIX } from 'utils/consts';
import { COLUMNS_LIMIT } from '../../consts';
import MaxColumnsAlert from './ColumnsAmountAlert';
import ClassFieldCheckboxList from '../ClassFieldCheckboxList';
import { ClassFieldApiResponse, ClassField } from 'components/AddColumn/types';

const AddColumnList: React.FC<AddColumnListProps> = ({
  onCancel,
  onAddColumns,
  sourceIndex,
  selectedCols,
  objectRecrdPropFields,
  classID,
}) => {
  const classes = useAddColumnStyles({});

  const initialColsSet = useMemo(
    () => new Set(selectedCols.map(col => col.id)),
    [selectedCols]
  );
  const [colSets, setColsets] = useState({
    addedCols: new Set(),
    deletedCols: new Set(),
    currentVal: new Set(selectedCols.map(col => col.id)),
  });

  const [classFields, setClassFields] = useState<ClassFieldApiResponse>();

  const fetchClassFields = useCallback(
    async (offset = 0, limit = 50) => {
      try {
        const { data, status } = await apiCall.get<ClassFieldApiResponse>(
          generatePath(OBJECT_CLASS_DETAILS_FIELDS, { id: classID }),
          {
            params: {
              offset,
              limit,
            },
          }
        );

        if (isSuccess(status)) {
          setClassFields((prev: ClassFieldApiResponse | undefined) => {
            return {
              ...data,
              results: [
                ...(prev?.results ?? []),
                ...data.results.map(result => {
                  return { ...result, alias: `${FIELD_PREFIX}${result.id}` };
                }),
              ],
            };
          });
        }
      } catch (error) {
        showDefaultErrorToast();
      }
    },
    [classID]
  );

  const fetchNextPage = useCallback(() => {
    if (!!classFields) {
      fetchClassFields(classFields.offset + classFields.limit);
    }
  }, [classFields, fetchClassFields]);

  useEffect(() => {
    fetchClassFields();
  }, [fetchClassFields]);

  const onChange = (e: CheckboxChangeEvent) => {
    const id = (e.target as HTMLInputElement).value;
    const checked = e.target.checked;
    if (checked) {
      colSets.deletedCols.delete(id);
      if (!initialColsSet.has(id)) {
        colSets.addedCols.add(id);
      }
      colSets.currentVal.add(id);
    } else {
      if (initialColsSet.has(id)) {
        colSets.deletedCols.add(id);
      }
      colSets.addedCols.delete(id);
      colSets.currentVal.delete(id);
    }
    setColsets({
      ...colSets,
    });
  };

  const handleAddColumns = () => {
    const firstHalf = take(selectedCols, sourceIndex + 1).filter(
      col => !colSets.deletedCols.has(col.id)
    );
    const secondHalf = drop(selectedCols, sourceIndex + 1).filter(
      col => !colSets.deletedCols.has(col.id)
    );
    const newCols: ChildClassColumns = Array.from(colSets.addedCols).map(
      alias => {
        return {
          id: alias,
          width: DEFAULT_COLUMNS_WIDTHS.width,
        } as ChildClassColumn;
      }
    );
    const newColsMeta = classFields?.results.reduce(
      (acc, field: ClassField) => {
        if (colSets.addedCols.has(field.alias)) {
          return {
            ...acc,
            [field.alias]: field,
          };
        }
        return acc;
      },
      {}
    ) as ObjectClassFieldMap;
    colSets.addedCols.clear();
    colSets.deletedCols.clear();
    onAddColumns([...firstHalf, ...newCols, ...secondHalf], newColsMeta);
  };

  const handleOnCancel = () => {
    colSets.addedCols.clear();
    colSets.deletedCols.clear();
    setColsets({
      addedCols: colSets.addedCols,
      deletedCols: colSets.deletedCols,
      currentVal: new Set(initialColsSet),
    });
    onCancel();
  };

  const currentVal = Array.from(colSets.currentVal.values());

  return (
    <div
      className={clsx(classes.wrapper, 'addColsPopover')}
      data-testid={ADD_COLUMNS_POPOVER_TESTID}
    >
      <MaxColumnsAlert currentAmount={colSets.currentVal.size} />
      <div className={classes.scroll} id='add-columns-list-wrapper'>
        <Heading
          level={5}
          className={classes.heading}
          data-testid={`${ADD_COLUMNS_POPOVER_TITLE_TESTID}-recordProperties`}
        >
          <FormattedMessage
            id='misc.recordProperties'
            defaultMessage='Record Properties'
          />
        </Heading>
        <div className={classes.checkboxList}>
          <CheckboxGroupCustom vertical value={currentVal}>
            {Object.values(objectRecrdPropFields).map(field => {
              const itemDisabled =
                field.alias === 'id' ||
                (colSets.currentVal.size >= COLUMNS_LIMIT &&
                  !colSets.currentVal.has(field.alias));
              return (
                <Checkbox
                  key={field.alias}
                  value={field.alias}
                  onChange={onChange}
                  className={classes.checkbox}
                  disabled={itemDisabled}
                >
                  &nbsp;
                  <Text strong>{field.label}</Text>
                </Checkbox>
              );
            })}
          </CheckboxGroupCustom>
        </div>
        <Heading
          level={5}
          className={classes.heading}
          data-testid={`${ADD_COLUMNS_POPOVER_TITLE_TESTID}-classFields`}
        >
          <FormattedMessage
            id='misc.classFields'
            defaultMessage='Class fields'
          />
        </Heading>
        <InfiniteScroll
          loader={
            <h6>
              <FormattedMessage id='misc.Loading' defaultMessage='Loading..' />
            </h6>
          }
          next={fetchNextPage}
          hasMore={
            (classFields?.results.length as number) <
            (classFields?.total_count as number)
          }
          scrollableTarget='add-columns-list-wrapper'
          dataLength={classFields?.results.length || 0}
        >
          <div className={classes.checkboxList}>
            <CheckboxGroupCustom vertical value={currentVal}>
              <ClassFieldCheckboxList
                classFields={classFields?.results ?? []}
                onChange={onChange}
                colSets={colSets}
              />
            </CheckboxGroupCustom>
          </div>
        </InfiniteScroll>
      </div>
      <div className={classes.buttonsWrapper}>
        <Space>
          <ButtonOutlined
            icon={<CloseIcon size={14} />}
            onClick={handleOnCancel}
            data-testid={CANCEL_ADDING_COLUMNS_TESTID}
          >
            <FormattedMessage id='misc.cancel' />
          </ButtonOutlined>
          <ButtonPrimary
            icon={<PlusIcon size={9} />}
            onClick={handleAddColumns}
            data-testid={ADD_COLUMNS_TESTID}
          >
            <FormattedMessage
              id='misc.addColumns'
              defaultMessage='Add columns'
            />
          </ButtonPrimary>
        </Space>
      </div>
    </div>
  );
};

export default AddColumnList;
