import useFlexLayoutWindow from "hooks/useFlexLayoutWindow";
import { useToggle } from "hooks/useToggle";
import { useEffect, useMemo } from "react";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { getObjectClassesFields } from "store/actions/objectClassesFieldsActions";
import FlexLayoutWindows from "utils/Enums/FlexLayoutWindows";
import ErrorIllustration from 'img/illustrations/error.svg';

export const useLimitExceededModal = (isLimitExceeded?: boolean) => {
    const dispatch = useDispatch();
    const { closeComponent } = useFlexLayoutWindow(
        FlexLayoutWindows.ObjectClassAddField
    );
    const intl = useIntl();
    const [
        isLimitExceededModalOpen,
        { toggleOn: openLimitExceededModal, toggleOff: closeLimitExceededModal },
    ] = useToggle(false);

    useEffect(() => {
        if (!!isLimitExceeded) openLimitExceededModal();
    }, [isLimitExceeded, openLimitExceededModal]);

    const modalProps = useMemo(
        () => ({
            visible: isLimitExceededModalOpen,
            confirmationButtonLabel: intl.formatMessage({
                id: 'misc.ok',
                defaultMessage: 'Ok',
            }),
            image: ErrorIllustration,
            title: intl.formatMessage({
                id: 'objectClasses.fields.theFieldWasNotCreated',
                defaultMessage: 'The field was not created',
            }),
            subtitle: intl.formatMessage({
                id: 'objectClasses.fields.maximumNumberExceeded',
                defaultMessage: 'Maximum number of fields has been reached.',
            }),
        }),
        [intl, isLimitExceededModalOpen]
    );

    const onLimitExceededModalClose = () => {
        closeLimitExceededModal();
        closeComponent(FlexLayoutWindows.ObjectClassAddField);
        dispatch(getObjectClassesFields());
    };

    return {
        onLimitExceededModalClose,
        modalProps,
        isLimitExceededModalOpen,
    };
};