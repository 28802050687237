import { CustomCellProps } from 'components/CustomCell/CustomCell.types';
import TooltipString from 'components/TooltipString';
import React from 'react';

export const ObjectClassNameCell = ({ row }: CustomCellProps) => {
  const objectClassName = row?._meta.labels?.object_class;

  return (
    <TooltipString text={objectClassName}>{objectClassName}</TooltipString>
  );
};
