import {
  ViewUsageConfig,
  ViewUsageDisplayType,
} from 'components/ViewUsage/types';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useUserGroupsViewUsageData } from '.';
import { transformUserGroups } from '../utils';
import { transformObjectClassFieldUsages } from '../utils/transformObjectClassFields';
import { wrapObjectClassNamesForUsageTree } from '../utils/wrapObjectClassFieldName';

export const useUserGroupsViewUsage = (id?: string) => {
  const intl = useIntl();
  const {
    loading,
    error,
    data: {
      sequences = [],
      user_groups: userGroups = [],
      object_classes_fields: objectClassFields = [],
      object_classes_permission_sets: objectClassPermissionSets = [],
    } = {},
  } = useUserGroupsViewUsageData(id);

  const displayConfig = useMemo<ViewUsageConfig[]>(() => {
    return [
      {
        usage: sequences,
        title: intl.formatMessage({
          id: 'misc.sequences',
          defaultMessage: 'Sequences',
        }),
      },
      {
        usage: transformUserGroups(userGroups),
        title: intl.formatMessage({
          id: 'misc.userGroupPermissionSets',
          defaultMessage: 'User group permission sets',
        }),
      },
      {
        usage: wrapObjectClassNamesForUsageTree(
          transformObjectClassFieldUsages(objectClassFields),
          intl
        ),
        title: intl.formatMessage({
          id: 'misc.objectClassUserTypeFields',
          defaultMessage: 'Object class user type fields',
        }),
        headline: intl.formatMessage({
          id: 'misc.objectClassUserTypeFieldsHeadline',
          defaultMessage:
            'This group has been used in the following object class fields:',
        }),
        type: ViewUsageDisplayType.TreeOfStrings,
      },
      {
        usage: objectClassPermissionSets,
        title: intl.formatMessage({
          id: 'misc.objectClassPermissionSets',
          defaultMessage: 'Object class permission sets',
        }),
        type: ViewUsageDisplayType.ObjClassPermissionSet,
      },
    ];
  }, [
    sequences,
    intl,
    userGroups,
    objectClassFields,
    objectClassPermissionSets,
  ]);

  return { loading, error, displayConfig };
};
