import { useCallback, useEffect, useState } from 'react';
import { DEFAULT_COLUMNS_WIDTHS } from '../Table.consts';
import { UseStateReducerParams } from './types';

export const useStateReducer = ({ temporaryState }: UseStateReducerParams) => {
  const [confirmedCancel, setConfirmedCancel] = useState(false);

  useEffect(() => {
    if (confirmedCancel) {
      setConfirmedCancel(false);
    }
  }, [confirmedCancel]);
  //important - workaround for table jumping when user reduces column width
  const stateReducer = useCallback(
    (newState, action, prevState) => {
      const {
        columnResizing: { isResizingColumn: column, columnWidths },
      } = newState;

      const { type: actionType } = action;

      if (column) {
        const currentWidth = (columnWidths as MappedObject<any>)[column];

        if (currentWidth === 0 && actionType === 'columnResizing') {
          return prevState;
        }
      }

      /*
          workaround for handling exiting configuration mode
          table does not automatically resize to default widths when we give empty columnWidths
          thats why we need to manually map these values
          */
      if (confirmedCancel && temporaryState) {
        const oldWidths = Object.keys(
          newState.columnResizing.columnWidths
        ).reduce((widths, key) => {
          const {
            columnResizing: {
              columnWidths: { [key]: initialWidth },
            },
          } = temporaryState;

          return {
            ...widths,
            [key]: initialWidth || DEFAULT_COLUMNS_WIDTHS.width,
          };
        }, {});
        return {
          ...temporaryState,
          columnResizing: { columnWidths: oldWidths },
        };
      }

      return newState;
    },
    [confirmedCancel, temporaryState]
  );

  return { stateReducer, setConfirmedCancel };
};
