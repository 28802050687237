import { RootState } from 'store/reducers';
import { Restrictions } from 'utils/types/api/table.types';
import { INITIAL_TABLE_STATE } from 'store/constants/misc.consts';
import TablesType from 'utils/Enums/TablesType';
import { PreferencesTypes } from 'utils/types/api/preferences.types';

export const isCurrentlyFetchingTableData = (classID?: string) => (
  state: RootState
) => {
  const { currentTable } = state.filters;
  if (currentTable) {
    if (classID && currentTable === TablesType.NestedTable) {
      return state.nestedObjectRecords.data[classID]?.fetching;
    }
    return (state as MappedObject<any>)[currentTable].fetching;
  }
};

export const getCurrentTableColumnMetadata = (state: RootState) => (
  columnName: string
) => {
  const { currentTable } = state.filters;
  if (currentTable) {
    return (state as MappedObject<any>)[currentTable].columns[columnName];
  }
};

export const getCurrentTableCustomOffset = (
  classID?: string,
  customTableName?: TablesType
) => (state: RootState) => {
  const currentTable = customTableName ?? state.filters.currentTable;

  if (currentTable) {
    if (currentTable === TablesType.NestedTable) {
      return state.nestedObjectRecords.data[classID ?? 'NO_ID']?.customOffset;
    }
    return state[currentTable].customOffset;
  }

  return INITIAL_TABLE_STATE.customOffset;
};

export const getCurrentTableTotalCount = (classID?: string) => (
  state: RootState
) => {
  const { currentTable } = state.filters;

  if (currentTable) {
    if (currentTable === TablesType.NestedTable) {
      return state.nestedObjectRecords.data[classID ?? 'NO_ID']?.total;
    }
    return state[currentTable].total;
  }

  return INITIAL_TABLE_STATE.total;
};

export const getCurrentTableFilteredCount = (classID?: string) => (
  state: RootState
) => {
  const { currentTable } = state.filters;

  if (currentTable) {
    if (currentTable === TablesType.NestedTable) {
      return state.nestedObjectRecords.data[classID ?? 'NO_ID']?.filtered;
    }
    return state[currentTable].filtered;
  }

  return INITIAL_TABLE_STATE.filtered;
};

export const getCurrentTableRestrictions = (classID?: string) => (
  state: RootState
): Restrictions => {
  const { currentTable } = state.filters;
  if (currentTable) {
    if (classID && currentTable === TablesType.NestedTable) {
      return state.nestedObjectRecords.data[classID]?.restrictions;
    }
    return (state as MappedObject<any>)[currentTable].restrictions;
  }

  return INITIAL_TABLE_STATE.restrictions;
};

export const getApiVersion = (state: RootState) => state.misc;

export const getCurrentTableSelectedColumns = (
  classID?: string,
  customTableName?: TablesType
) => (state: RootState) => {
  const currentTableName = customTableName || state.filters.currentTable;

  if (currentTableName === TablesType.NestedTable && classID) {
    return state.nestedObjectRecords.data[classID]?.selectedColumns;
  }

  const selectedClassId =
    state.preferences.data[PreferencesTypes.GeneralPreferences].selectedClassId;

  if (selectedClassId && currentTableName === TablesType.ObjectRecords) {
    return state.objectRecords.selectedColumns?.[selectedClassId];
  }
  return undefined;
};
