import React, { useEffect } from 'react';
import FlexLayoutWindows from 'utils/Enums/FlexLayoutWindows';
import { setSidebarData } from 'store/actions/flexLayoutActions';
import { useDispatch } from 'react-redux';
import { useFlexLayoutContext } from 'components/lib/FlexLayout/FlexLayoutContext';
import { useLocation } from 'react-router-dom';
import { useViewUsage } from './hooks';
import { ViewFieldUsageWindow } from 'components/ViewFieldUsageWindow';
import { ClassFieldDetailLocationState } from 'pages/ObjectClasses/components/ClassFieldFormWrapper/types';

export const ObjectClassFieldViewUsage = () => {
  const dispatch = useDispatch();
  const {
    externalWindowsMethods: { checkIfExternalWindowExists },
  } = useFlexLayoutContext();

  const { state: { id, label, className } = {} } = useLocation<
    ClassFieldDetailLocationState
  >();

  const {
    displayConfig,
    fetchUsage,
    usageLabel,
    noUsageLabel,
    loading,
  } = useViewUsage(className);

  useEffect(() => {
    if (id !== undefined && label !== undefined) {
      fetchUsage();
    }
  }, [id, label, fetchUsage]);

  useEffect(() => {
    return () => {
      dispatch(setSidebarData(FlexLayoutWindows.ObjectClassFieldViewUsage, {}));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ViewFieldUsageWindow
      collapsible
      isLight
      {...{
        id,
        displayConfig,
        label,
        isExternal: checkIfExternalWindowExists(
          FlexLayoutWindows.ObjectClassFieldViewUsage
        ),
        usageLabel,
        noUsageLabel,
        isLoading: loading,
      }}
    />
  );
};
