import { AvatarItem } from 'components/lib/Avatar/types';
import { mapBaseUserInfoToAvatarItem } from './mapUserToAvatarItem';
import { UserInfoBase } from 'utils/types/api/UserInfoBase.types';
import { sortUserAvatars } from './sortUserAvatars';

/**
 * Transforms user list into user avatars and then sorts them ascending (a-z) by first name and last name.
 *
 * @param users User list returned from the api.
 *
 * @returns Alphabetically sorted user avatars.
 */
export const getSortedUserAvatars = (users: UserInfoBase[]): AvatarItem[] => {
  const userAvatars = users.map(user => mapBaseUserInfoToAvatarItem(user));

  return sortUserAvatars(userAvatars);
};
