import React from 'react';
import clsx from 'clsx';
import removeArrItem from 'lodash/remove';
import { Checkbox } from 'components/lib/Checkbox';
import { useRowStyles } from 'components/Table/Table.styles';
import { BULK_SELECTION_COLUMN_WIDTH } from 'components/Table/Table.consts';
import { useTableContext } from 'contexts/TableContext';
import {
  TABLE_BULK_SELECTION_ROW_CHECKBOX_TESTID,
  TABLE_BULK_SELECTION_ROW_CHECKBOX_WRAPPER_TESTID,
} from 'utils/testIds';
import { BulkSelectionRowCheckboxProps } from './types';

const BulkSelectionRowCheckbox = ({ rowId }: BulkSelectionRowCheckboxProps) => {
  const classes = useRowStyles({});
  const { setBulkSelectionList, bulkSelectionList } = useTableContext();

  const handleOnChangeRowCheckbox = () =>
    setBulkSelectionList(prev => {
      const isSelectedIdInBulkSelectionList = prev.includes(rowId);

      return isSelectedIdInBulkSelectionList
        ? removeArrItem(prev, bulkSelectionId => bulkSelectionId !== rowId)
        : [...prev, rowId];
    });

  return (
    <div
      className={clsx([
        classes.tbodyTd,
        classes.tableCell,
        classes.tableCellCentered,
      ])}
      style={{ width: BULK_SELECTION_COLUMN_WIDTH }}
      data-testid={TABLE_BULK_SELECTION_ROW_CHECKBOX_WRAPPER_TESTID}
    >
      <Checkbox
        onChange={handleOnChangeRowCheckbox}
        checked={bulkSelectionList.includes(rowId)}
        className={classes.checkbox}
        data-testid={TABLE_BULK_SELECTION_ROW_CHECKBOX_TESTID}
      />
    </div>
  );
};

export default BulkSelectionRowCheckbox;
