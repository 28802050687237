import { createUseStyles } from 'react-jss';
import { UseClassFieldPropertiesStylesProps } from 'styles/classFieldPropertiesStyles';

/**
 * This is a copy of some CSS rules from .\src\pages\ObjectClasses\components\ObjectClassForm\components\NewAddField\styles.ts
 * useClassFieldPropertiesStyles
 *
 * I'm making this override copy because I'm not sure if my changes won't break layout in other parts of the system.
 *
 * Ideally we would like to unify this CSS set and make the app look good.
 *
 * My override is happening because there are unaligned paddings in the "View usage" panel.
 */
export const useViewFieldUsageWindowStyles = createUseStyles(() => ({
  collapseHeader: {
    '& .ant-collapse-header': {
      marginBottom: '0rem !important',
      paddingLeft: '0.5rem !important',
      paddingRight: '0.5rem !important',
    },
  },
  collapseContent: {
    '& .ant-collapse-content-box': {
      paddingBottom: '0rem !important',
    },
  },
  headline: {
    padding: '0 0 0.8rem 0',
  },
  textContainer: {
    textAlign: (props: UseClassFieldPropertiesStylesProps) =>
      props?.isLight ? 'left' : 'inherit',
    marginBottom: '0.375rem',
    lineHeight: 1.5,
    fontSize: 12,
    display: 'flex',
    flexDirection: 'column',
  },
}));
