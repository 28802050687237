import useDynamicIntl from 'hooks/useDynamicIntl';
import { useDispatch, useSelector } from 'react-redux';
import { getCurrentTable } from 'store/selectors/filtersSelectors';
import { useState, useMemo, useCallback, useEffect } from 'react';
import { useTableContext } from 'contexts/TableContext';
import { CustomTableGroupKeys } from 'contexts/types';
import { useIntl } from 'react-intl';
import { COLUMNS_LIMIT } from './consts';
import useCurrentTableSupportedColumns from 'hooks/useCurrentTableSupportedColumns';
import { AddColumnGroups, ClassField, ClassFieldApiResponse } from './types';
import { sortColumnGroups } from './utils';
import { generatePath } from 'react-router-dom';
import { apiCall } from 'utils/api';
import { isSuccess } from 'utils/apiUtils';
import { OBJECT_CLASS_DETAILS_FIELDS } from 'utils/endpoints';
import { getSelectedObjectClassId } from 'store/selectors/preferencesSelectors';
import { setObjectRecordsSelectedColumns } from 'store/actions/objectRecordsActions';
import { FormBuilderField } from 'components/formBuilder/formBuilder/FormBuilderContext/types';
import TablesType from 'utils/Enums/TablesType';
import { FIELD_PREFIX } from 'utils/consts';
import { getObjectRecordsSelectedColumns } from 'store/selectors/objectRecordsSelectors';
import showDefaultErrorToast from 'utils/functions/showDefaultErrorToast';

const useAddColumn = (
  onCancel: VoidFunction,
  onAddColumns: (columns: string[]) => void,
  hiddenColumns?: string[]
) => {
  const intl = useIntl();
  const dynamicIntl = useDynamicIntl();
  const dispatch = useDispatch();
  const { customDataGroups } = useTableContext();
  const currentTableColumns = useCurrentTableSupportedColumns();
  const currentTableName = useSelector(getCurrentTable);
  const selectedClassId = useSelector(getSelectedObjectClassId);
  const alreadySelectedClassFields = useSelector(
    getObjectRecordsSelectedColumns
  );
  const [columnsToShow, setColumnsToShow] = useState<string[]>([]);
  const [selectedClassFields, setSelectedClassFields] = useState<
    ClassField[]
  >();

  const [classFields, setClassFields] = useState<ClassFieldApiResponse>();

  const defaultLabel = intl.formatMessage({
    id: 'misc.addColumns',
    defaultMessage: 'Add columns',
  });

  const onSelectClassFields = useCallback(
    (data: { value: ClassField; checked: boolean }) => {
      const { value, checked } = data;

      if (checked) {
        setSelectedClassFields(prev => [...(prev ?? []), value]);

        return;
      }
      setSelectedClassFields(prev => [
        ...(prev?.filter(classField => classField.id !== value?.id) ?? []),
      ]);
    },
    []
  );

  useEffect(() => {
    setSelectedClassFields(alreadySelectedClassFields);
  }, [alreadySelectedClassFields]);

  const fetchClassFields = useCallback(
    async (offset = 0, limit = 50) => {
      try {
        const { data, status } = await apiCall.get<ClassFieldApiResponse>(
          generatePath(OBJECT_CLASS_DETAILS_FIELDS, { id: selectedClassId }),
          {
            params: {
              offset,
              limit,
              //TODO: add filtering out selected ID's when filter on backend gets a fix - id__in!=[selectedIDS],
            },
          }
        );

        if (isSuccess(status)) {
          setClassFields((prev: ClassFieldApiResponse | undefined) => {
            return {
              ...data,
              results: [...(prev?.results ?? []), ...data.results],
            };
          });
        }
      } catch (error) {
        showDefaultErrorToast();
      }
    },
    [selectedClassId]
  );

  const fetchNextPage = useCallback(() => {
    if (!!classFields) {
      fetchClassFields(classFields.offset + classFields.limit);
    }
  }, [classFields, fetchClassFields]);

  useEffect(() => {
    if (currentTableName === TablesType.ObjectRecords) fetchClassFields();
  }, [currentTableName, fetchClassFields]);

  const hiddenColumnsToGroups = useCallback(
    (alias: string, groups: AddColumnGroups) => {
      const column = currentTableColumns[alias] || {};
      const groupKey = column.groupKey ?? CustomTableGroupKeys.Default;

      const fieldColumn = {
        label:
          groupKey === CustomTableGroupKeys.Default && !column.label
            ? dynamicIntl({ id: `${currentTableName}.${alias}` })
            : column.label || '',
        value: alias,
        order: column.order || 0,
      };

      if (groupKey in groups) {
        groups[groupKey] = [...groups[groupKey], fieldColumn];
      } else {
        groups[groupKey] = [fieldColumn];
      }
    },
    [currentTableColumns, currentTableName, dynamicIntl]
  );

  const mapGroupsToSections = useCallback(
    (groups: AddColumnGroups) =>
      Object.keys(groups).map((key, index) => ({
        id: index,
        groupKey: key || CustomTableGroupKeys.Default,
        sectionLabel:
          customDataGroups?.[key as CustomTableGroupKeys]?.label ??
          defaultLabel,
        fields:
          key === CustomTableGroupKeys.Default
            ? groups[key]
            : classFields?.results,
      })),
    [customDataGroups, defaultLabel, classFields]
  );

  const options = useMemo(() => {
    const groups: AddColumnGroups = {
      [CustomTableGroupKeys.Default]: [],
    };

    if (currentTableName === TablesType.ObjectRecords) {
      groups[CustomTableGroupKeys.ObjectClassFields] = [];
    }

    if (hiddenColumns) {
      hiddenColumns.forEach(column => hiddenColumnsToGroups(column, groups));
    }

    groups[CustomTableGroupKeys.Default].sort(sortColumnGroups);

    Object.keys(groups).forEach(groupKey => {
      groups[groupKey].sort(sortColumnGroups);
    });

    return mapGroupsToSections(groups);
  }, [
    currentTableName,
    hiddenColumns,
    hiddenColumnsToGroups,
    mapGroupsToSections,
  ]);

  const onSelect = useCallback(checkedValues => {
    setColumnsToShow(checkedValues);
  }, []);

  const onClickCancel = useCallback(() => {
    setColumnsToShow([]);
    onCancel();
  }, [onCancel]);

  const onConfirmColumns = useCallback(() => {
    let selectedFields: FormBuilderField[] = [];

    if (selectedClassId && currentTableName === TablesType.ObjectRecords) {
      selectedFields = selectedClassFields ?? [];

      dispatch(
        setObjectRecordsSelectedColumns({
          selectedClassId,
          selectedColumns: [
            ...selectedFields.map(selected => ({
              ...selected,
              alias: selected.alias.startsWith(FIELD_PREFIX)
                ? selected.alias
                : `${FIELD_PREFIX}${selected.alias}`,
            })),
          ],
        })
      );
    }

    onAddColumns([
      ...columnsToShow,
      ...selectedFields
        .filter(
          sel => !alreadySelectedClassFields?.some(field => field === sel)
        )
        .map(col =>
          col.alias.startsWith(FIELD_PREFIX)
            ? col.alias
            : `${FIELD_PREFIX}${col.alias}`
        ),
    ]);

    setColumnsToShow([]);
  }, [
    selectedClassId,
    currentTableName,
    onAddColumns,
    columnsToShow,
    selectedClassFields,
    dispatch,
    alreadySelectedClassFields,
  ]);

  const currentTableAliases = useMemo(() => Object.keys(currentTableColumns), [
    currentTableColumns,
  ]);

  const columnsCount = useMemo(
    () =>
      (selectedClassFields?.length ?? 0) +
      columnsToShow.length +
      currentTableAliases.filter(
        key =>
          !(selectedClassFields ?? []).some(selected => selected.alias === key)
      ).length -
      (hiddenColumns || []).length,
    [
      selectedClassFields,
      columnsToShow.length,
      currentTableAliases,
      hiddenColumns,
    ]
  );

  const isColumnLimitReached = columnsCount >= COLUMNS_LIMIT;

  return {
    currentTableColumns,
    options,
    onSelect,
    fetchNextPage,
    classFields: useMemo(() => classFields?.results, [classFields]),
    selectedClassFields: useMemo(
      () => selectedClassFields?.map(s => s.id.toString()),
      [selectedClassFields]
    ),
    onSelectClassFields,
    hasMorePages:
      !!classFields && classFields.filtered_count > classFields.results.length,
    onClickCancel,
    onConfirmColumns,
    columnsToShow,
    isColumnLimitReached,
  };
};

export default useAddColumn;
