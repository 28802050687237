import { isNumber } from "lodash";
import { Validators } from "utils/types/selectInput.types";

export const useValidators = (validators: Validators[]) => {
    return validators.reduce<MappedObject<number | undefined>>(
      (result, { type: key, length }) => {
        if (Array.isArray(key)) {
          return result;
        } else {
          return {
            ...result,
            [key]: isNumber(length) ? length : undefined,
          };
        }
      },
      {}
    );
  };