import { useCallback, useEffect, useMemo, useState } from 'react';
import { CONTENT_ID, TABLE_HEAD_ID } from 'utils/elementsIds';
import { UseTableTotalWidthOnResizeParams } from './types';

export const useTableTotalWidthOnResize = ({
  sidebarWidth,
  totalColumnsWidth,
}: UseTableTotalWidthOnResizeParams) => {
  const [update, updateState] = useState<undefined | {}>();
  const forceUpdate = useCallback(() => updateState({}), []);

  const onWindowResize = () => {
    forceUpdate();
  };

  useEffect(() => {
    window.addEventListener('resize', onWindowResize);

    return () => window.removeEventListener('resize', onWindowResize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const contentWidth = window.document.querySelector(`#${CONTENT_ID}`)
    ?.clientWidth;
  const tableHeadWidth =
    window.document.querySelector(`#${TABLE_HEAD_ID}`)?.getBoundingClientRect()
      ?.width || totalColumnsWidth;

  return useMemo(() => {
    const bodyWidthWithoutFilters =
      tableHeadWidth < totalColumnsWidth
        ? tableHeadWidth
        : totalColumnsWidth + 115;

    if (
      window.document.body.offsetWidth - sidebarWidth < totalColumnsWidth ||
      (contentWidth && contentWidth < bodyWidthWithoutFilters)
    )
      return '100%';

    return bodyWidthWithoutFilters;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableHeadWidth, sidebarWidth, totalColumnsWidth, update]);
};
