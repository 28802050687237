import clsx from 'clsx';
import { useTableRowContext } from 'contexts/TableRowContext';
import usePreviousState from 'hooks/usePreviousState';
import React, { useCallback, useEffect, useRef } from 'react';
import { DropTargetMonitor, useDrag, useDrop, XYCoord } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { useDispatch } from 'react-redux';
import { DUMMY_ELEM } from 'utils/elementsIds';
import { TableRow } from '../TableRow';
import { useDraggableTableStyles } from './styles';
import { DraggableRowProps, DragItem } from './types';

const DraggableRow = ({
  id,
  index,
  onDragEnd,
  handleDragging,
  currentTableName,
  row,
  canDrag,
  isDraggingActive,
}: DraggableRowProps) => {
  const classes = useDraggableTableStyles({});
  const ref = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();
  const {
    setSelectedRow,
    prevHoveredElem: prevRow,
    draggedId,
  } = useTableRowContext();
  const prevIsDraggingActive = usePreviousState(isDraggingActive);

  const onHover = useCallback(
    (item: DragItem, monitor: DropTargetMonitor) => {
      if (!ref.current || !prevRow) {
        return;
      }

      if (
        prevIsDraggingActive !== isDraggingActive &&
        isDraggingActive &&
        setSelectedRow
      ) {
        dispatch(setSelectedRow(item.id.toString()));
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset() || ({} as XYCoord);

      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      const isOverMiddle = hoverClientY < hoverMiddleY;

      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      const isDraggingDownwards = dragIndex < hoverIndex && isOverMiddle;
      const isDraggingUpwards = dragIndex > hoverIndex && !isOverMiddle;

      // Dragging downwards
      if (isDraggingDownwards) {
        return;
      }

      // Dragging upwards
      if (isDraggingUpwards) {
        return;
      }

      // // Time to actually perform the action
      if (monitor.isOver() && prevRow.current !== ref.current) {
        if (prevRow.current) {
          prevRow.current.style.opacity = '1';
          (prevRow.current.children[0] as HTMLDivElement).style.border =
            '1px solid transparent';
        }

        ref.current.style.opacity = '0.5';
        (ref.current.children[0] as HTMLDivElement).style.border = '1px dashed';

        //@ts-ignore
        prevRow.current = ref.current;
      }

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
    [
      dispatch,
      index,
      isDraggingActive,
      prevIsDraggingActive,
      prevRow,
      setSelectedRow,
    ]
  );

  // any bc there is not supported type for handlerId
  const [{ handlerId }, drop] = useDrop<DragItem, void, { handlerId: any }>({
    accept: 'card',
    collect: monitor => ({
      handlerId: monitor.getHandlerId(),
    }),
    hover: onHover,
    drop: () => {
      const id = draggedId?.current;
      onDragEnd(index, id || undefined);
    },
  });

  const [{ isDragging }, drag, preview] = useDrag<
    DragItem,
    void,
    { isDragging: boolean }
  >({
    item: {
      id,
      type: 'card',
      index,
      rowProps: {
        row,
        currentTableName,
        index,
        showIndicator: true,
      },
    },
    canDrag,
    begin: () => {
      //@ts-ignore
      if (draggedId) draggedId.current = id;

      handleDragging(true);
    },
    collect: monitor => {
      return {
        isDragging: monitor.isDragging(),
      };
    },
    end: () => {
      if (prevRow?.current) {
        prevRow.current.style.opacity = '1';
        (prevRow.current.children[0] as HTMLDivElement).style.border =
          '1px solid transparent';
      }

      handleDragging(false);
    },
  });

  drag(drop(ref));

  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      {...{ ref }}
      data-handler-id={handlerId}
      className={clsx(classes.draggableRow, {
        [classes.activeElem]: isDragging,
      })}
    >
      {!row ? (
        <div id={DUMMY_ELEM} />
      ) : (
        <TableRow
          {...{
            row,
            currentTableName,
            index,
            showIndicator: true,
          }}
        />
      )}
    </div>
  );
};

export default DraggableRow;
