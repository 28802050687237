import { DEFAULT_OBJECT_MODEL_ID } from 'components/Table/Table.consts';
import { CustomDataGroupsType, CustomTableGroupKeys } from 'contexts/types';
import React, { useEffect, useCallback, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath } from 'react-router-dom';
import { setCurrentTable } from 'store/actions/filtersActions';
import {
  addChildToTopOfStack,
  clearNestedObjectRecords,
  getNestedObjectRecords,
  getNestedObjectRecordsColumnConfiguration,
  getSelectedObjectRecords,
  pushStackWithTreeUpdate,
  resetNestedObjectRecords,
  resetNestedObjectRecordsColumns,
} from 'store/actions/nestedObjectRecordsActions';
import {
  getNestedObjectRecordsFiltered,
  selectNestedObjectRecords,
  getNestedObjectRecordsTotal,
  getNestedObjectRecordsLoading,
  selectNestedRecordsTreeFromTopStackByClassID,
  nestedRecordByClassIdComparisonFunc,
} from 'store/selectors/nestedObjectRecordsSelctors';
import { apiCall } from 'utils/api';
import {
  OBJECT_MODEL_DETAILS_OBJECT_RECORDS,
  OBJECT_RECORD_AUTOCOMPLETE,
  OBJECT_RECORD_CHILDREN_AUTOCOMPLETE,
} from 'utils/endpoints';
import DataFetchType from 'utils/Enums/DataFetchType';
import TablesType from 'utils/Enums/TablesType';
import showDefaultErrorToast from 'utils/functions/showDefaultErrorToast';
import { ChildClassTableUIConfig, UsechildClassTableParams } from './types';
import { OptionData } from './components/ChildClassTableAutocompleteSelect/types';
import { toast } from 'components/lib/toast';
import ToastType from 'utils/Enums/ToastType';

import { RecordActionsProps } from 'pages/Records/RecordsListing/components/RecordsTableView/ObjectRecordsTableWrapper/types';
import { useSelectedResourceContext } from 'contexts/SelectedResourceContext';
import useTableHeader from './useTableHeader';
import { ResetPaginationRef } from 'hooks/useTableData/types';
import { childClassUICOmponentStates } from './enums';
import { selectChildClassById } from 'store/selectors/childClassesSelectors';
import { FormBuilderChildObjectClass } from 'components/formBuilder/formBuilder/FormBuilderContext/types';

//Generally this hook works in two ways, there a different but not different enough to have different hooks
//When there is a recordId - parent record of given child class then we are on EditRecordform and the logic is on the BE side
//if there is no recordId - CreateRecordForm - parent doesn't exist so we have to store selected records on our side

/**
 *
 *
 * @param {UsechildClassTableParams} {
 *   classId - classID of a childClass
 *   recordId - ID of a parent record. ID also indicates whether it's a CreateRecordForm or EditRecordForm
 *   config - columnConfiguration setup in ObjectClass FormBuilder
 *   readOnly - whether form is opened in readOnly mode
 *   multiplicity - type of multiplicity relation has ZERO_OR_MORE, ZERO_OR_ONE
 *   identifier - parent record identifier - might be object_name or ID or undefined
 * }
 * @return {*}
 */
const useChildClassTable = ({
  classId,
  recordId,
  config,
  readOnly,
  multiplicity,
  identifier,
  label,
  hasCreateEditViewEnabled,
}: UsechildClassTableParams) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const filteredCount = useSelector(getNestedObjectRecordsFiltered(classId));
  const totalCount = useSelector(getNestedObjectRecordsTotal(classId));
  const isLoadingData = useSelector(getNestedObjectRecordsLoading(classId));

  const {
    setAdditionalSelectedResource,
    setSelectedResource,
  } = useSelectedResourceContext();
  const selectedIds = useSelector(
    selectNestedRecordsTreeFromTopStackByClassID(classId),
    nestedRecordByClassIdComparisonFunc
  );

  const prevSelectedIds = useRef(selectedIds).current;
  const [selectedForFiltering, setSelectedForFiltering] = useState<
    OptionData
  >();
  const prevSelectedForFiltering = useRef(selectedForFiltering).current;

  const resetPaginationRef: ResetPaginationRef = useRef(() => false);
  const uiPermissionsConfig = useChildClassTableUIConfig({
    readOnly,
    childClassId: classId,
  });
  const autocompleteUrl = recordId
    ? generatePath(OBJECT_RECORD_CHILDREN_AUTOCOMPLETE, {
        modelId: DEFAULT_OBJECT_MODEL_ID,
        parentRecordId: recordId,
        objectClassId: classId,
      })
    : generatePath(OBJECT_RECORD_AUTOCOMPLETE, { classId });

  useEffect(() => {
    dispatch(setCurrentTable(TablesType.NestedTable));

    return () => {
      dispatch(setCurrentTable(undefined));
    };
  }, [dispatch]);

  useEffect(() => {
    return () => {
      dispatch(clearNestedObjectRecords(classId));
    };
  }, [dispatch, classId]);

  const onCreateClick = () => {
    setAdditionalSelectedResource();
    setSelectedResource();
    dispatch(
      pushStackWithTreeUpdate({
        classId,
        className: label,
        recordId: undefined,
        formData: undefined,
        recordIdentifier: undefined,
      })
    );
  };
  const clearSelectedForFiltering = useCallback(() => {
    setSelectedForFiltering(undefined);
  }, []);

  const fetchMethod = useCallback(
    (queryParams: string | undefined, fetchType?: DataFetchType) => {
      if (selectedForFiltering) {
        return getSelectedObjectRecords({
          classId,
          queryParams,
          fetchType,
          ids: [Number(selectedForFiltering.value)],
          overrideTotalCount: recordId ? filteredCount : selectedIds?.length,
        });
      }

      if (!recordId) {
        return getSelectedObjectRecords({
          classId,
          queryParams,
          fetchType,
          ids: selectedIds,
        });
      }
      return getNestedObjectRecords({
        classId,
        queryParams,
        fetchType,
        parentRecordId: recordId,
      });
    },
    [selectedForFiltering, recordId, classId, selectedIds, filteredCount]
  );

  const refetchMethodRef = useRef<VoidFunction>();

  const refetchData = useCallback(() => {
    if (!refetchMethodRef.current) return;

    refetchMethodRef.current();
  }, []);

  const successToast = useCallback(
    (option: OptionData) => {
      toast(
        {
          title: intl.formatMessage({
            id: 'misc.success',
            defaultMessage: 'Succeess!',
          }),
          subtitle: intl.formatMessage(
            {
              id: 'nestedTable.childRecordAdded',
              defaultMessage: '<b>{identifier}</b> has been added.',
            },
            { b: (...chunks) => <b>{chunks}</b>, identifier: option.title }
          ),
        },
        ToastType.Success
      );
    },
    [intl]
  );
  useEffect(() => {
    //this is quite dodgy, because table fetches data in its own way in useTableData hook when everythign is synchronised
    //but we need to refetch data on create record form when we select child records to be added, and we select one of them for filtering on both forms
    //this can't be done in useTableData hook, because it's not aware of selectedForFiltering state
    //so we refetch the data only if there are changes in selectedForFiltering or selectedIds, but not in the first render.
    if (
      selectedForFiltering !== prevSelectedForFiltering ||
      selectedIds !== prevSelectedIds
    ) {
      refetchData();
    }
  }, [
    selectedForFiltering,
    selectedIds,
    prevSelectedForFiltering,
    prevSelectedIds,
    refetchData,
  ]);

  const handleAutocompleteOptionChange = useCallback(
    async (option: OptionData | undefined) => {
      if (!option?.value) {
        return;
      }

      if (option.is_child === 'true') {
        resetPaginationRef.current();
        setSelectedForFiltering(option);

        return;
      }

      if (!recordId) {
        clearSelectedForFiltering();
        dispatch(
          addChildToTopOfStack({
            classId,
            recordId: option.value.toString(),
          })
        );

        return;
      }

      try {
        await apiCall.post(
          generatePath(OBJECT_MODEL_DETAILS_OBJECT_RECORDS, {
            modelId: DEFAULT_OBJECT_MODEL_ID,
          }),
          { parent: recordId, object_record: option.value }
        );
        successToast(option);
        if (resetPaginationRef.current) {
          clearSelectedForFiltering();
          const hasPaginationChanged = resetPaginationRef.current(); //if pagination changes then data will refetch automatically, here we cover the scenario when pagination is still the same
          if (!hasPaginationChanged) {
            refetchData();
          }
        }
      } catch (err) {
        showDefaultErrorToast();
      }
    },
    [
      recordId,
      clearSelectedForFiltering,
      dispatch,
      classId,
      successToast,
      refetchData,
    ]
  );

  const dataSelector = useCallback(() => {
    return selectNestedObjectRecords(classId);
  }, [classId]);

  const getColumnsConfiguration = useCallback(() => {
    return getNestedObjectRecordsColumnConfiguration(classId, config ?? []);
  }, [classId, config]);

  const resetData = useCallback(() => {
    return resetNestedObjectRecords(classId);
  }, [classId]);

  const resetColumns = useCallback(() => {
    return resetNestedObjectRecordsColumns(classId);
  }, [classId]);

  const customDataGroups: CustomDataGroupsType = {
    [CustomTableGroupKeys.Default]: {
      label: intl.formatMessage({
        id: 'objectRecords.recordProperties',
        defaultMessage: 'Record properties',
      }),
    },
    [CustomTableGroupKeys.ObjectClassFields]: {
      addQueryParameters: (visibleColumns: string[]) => {
        return `show_fields=${visibleColumns?.join(',')}`;
      },
      label: intl.formatMessage({
        id: 'objectClasses.form.classFields',
        defaultMessage: 'Class fields',
      }),
    },
  };

  const handleSingleClick = useCallback(
    record => {
      const {
        id,
        permissions,
        object_name: identifier = '',
      } = record as RecordActionsProps;

      setAdditionalSelectedResource({
        record: {
          recordId: id.toString(),
          permissions,
          identifier,
          isSummaryPanelEnabled: true,
        },
        objectClassId: classId?.toString(),
      });
    },
    [setAdditionalSelectedResource, classId]
  );

  const {
    tableHeaderResults,
    childClassTableAddtionalHeader,
    limitExceededNotification,
  } = useTableHeader({
    filteredCount,
    selectedIds,
    hasCreateEditViewEnabled,
    multiplicity,
    selectedForFiltering,
    readOnly,
    clearSelectedForFiltering,
    recordId,
    identifier,
    autocompleteUrl,
    handleAutocompleteOptionChange,
    onCreateClick,
    totalCount,
    isLoadingData,
    uiPermissionsConfig,
  });

  return {
    selectedForFiltering,
    clearSelectedForFiltering,
    tableHeaderResults,
    customDataGroups,
    refetchMethodRef,
    resetPaginationRef,
    childClassTableAddtionalHeader,
    limitExceededNotification,
    resetColumns,
    resetData,
    dataSelector,
    handleSingleClick,
    fetchMethod,
    getColumnsConfiguration,
  };
};

export const calcUIState = (
  readOnly: boolean,
  childClass: FormBuilderChildObjectClass | undefined
): ChildClassTableUIConfig => {
  let sum = 0;
  sum += childClass?.hasViewPermission ? 1 : 0;
  sum += childClass?.hasListPermission ? 2 : 0;
  sum += childClass?.hasCreatePermission ? 4 : 0;
  let uiState = {
    list: childClassUICOmponentStates.HIDDEN,
    paging: childClassUICOmponentStates.HIDDEN,
    counter: childClassUICOmponentStates.HIDDEN,
    createButton: childClassUICOmponentStates.HIDDEN,
    recordLookup: childClassUICOmponentStates.HIDDEN,
  };
  switch (sum) {
    case 0:
    case 1:
      break;
    case 3: {
      uiState = {
        list: childClassUICOmponentStates.ENABLED,
        paging: childClassUICOmponentStates.ENABLED,
        counter: childClassUICOmponentStates.ENABLED,
        createButton: childClassUICOmponentStates.DISABLED,
        recordLookup: childClassUICOmponentStates.ENABLED,
      };
      break;
    }
    case 5: {
      uiState = {
        list: childClassUICOmponentStates.ENABLED,
        paging: childClassUICOmponentStates.DISABLED,
        counter: childClassUICOmponentStates.ENABLED,
        createButton: childClassUICOmponentStates.ENABLED,
        recordLookup: childClassUICOmponentStates.HIDDEN,
      };
      break;
    }
    case 7: {
      uiState = {
        list: childClassUICOmponentStates.ENABLED,
        paging: childClassUICOmponentStates.ENABLED,
        counter: childClassUICOmponentStates.ENABLED,
        createButton: childClassUICOmponentStates.ENABLED,
        recordLookup: childClassUICOmponentStates.ENABLED,
      };
      break;
    }
    default: {
      // only other cases are for view class false which are redundent
      break;
    }
  }
  if (readOnly) {
    uiState.createButton = childClassUICOmponentStates.HIDDEN;
    uiState.recordLookup = childClassUICOmponentStates.HIDDEN;
  }
  return uiState;
};

export const useChildClassTableUIConfig = ({
  readOnly,
  childClassId,
}: {
  readOnly: boolean;
  childClassId: string;
}) => {
  const childClass = useSelector(selectChildClassById(childClassId));
  return calcUIState(readOnly, childClass);
};

export default useChildClassTable;
