import { BULK_SELECTION_COLUMN_WIDTH } from 'components/Table/Table.consts';
import useTableWrapperStyles, { useTableStyles } from 'components/Table/Table.styles';
import React from 'react';
import { TABLE_BULK_SELECTION_HEADER_CHECKBOX_TESTID } from 'utils/testIds';
import { Checkbox } from 'components/lib/Checkbox';
import useBulkSelectionHeaderCheckbox from './hooks';
import { useTableRowContext } from 'contexts/TableRowContext';
import { HeaderCheckboxProps } from './types';

const BulkSelectionHeaderCheckbox = (props: HeaderCheckboxProps) => {
  const {
    isSelectedAnyRow,
    isSelectedAllRows,
    onChangeHeaderCheckbox,
  } = useBulkSelectionHeaderCheckbox(props);
  const { density } = useTableRowContext();
  const tableClasses = useTableWrapperStyles({});
  const classes = useTableStyles({ density });

  return (
    <div
      className={classes.tableHeadCell}
      style={{
        width: BULK_SELECTION_COLUMN_WIDTH,
      }}
      data-testid={TABLE_BULK_SELECTION_HEADER_CHECKBOX_TESTID}
    >
      <div className={tableClasses.flexAlignCenter}>
        <Checkbox
          onChange={onChangeHeaderCheckbox}
          checked={isSelectedAllRows}
          indeterminate={isSelectedAnyRow}
          className={classes.checkbox}
        />
      </div>
    </div>
  );
};

export default BulkSelectionHeaderCheckbox;
