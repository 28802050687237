export const fieldTypes = [
    {
        value: 'string',
        text: 'Text',
    },
    {
        value: 'int',
        text: 'Integer',
    },
    {
        value: 'float',
        text: 'Decimal',
    },
    {
        value: 'bool',
        text: 'Checkbox',
    },
    {
        value: 'enum',
        text: 'Single select',
    },
    {
        value: 'set',
        text: 'Multi select',
    },
    {
        value: 'email',
        text: 'Email',
    },
    {
        value: 'phone',
        text: 'Phone',
    },
    {
        value: 'date',
        text: 'Date',
    },
    {
        value: 'datetime',
        text: 'Date/Time',
    },
    {
        value: 'time',
        text: 'Time',
    },
    {
        value: 'document',
        text: 'Document',
    },
    {
        value: 'user',
        text: 'User',
    },
    {
        value: 'url',
        text: 'URL',
    },
    {
        value: 'json',
        text: 'JSON',
    },
];