import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Loader } from 'components/lib/Loader';
import { SelectOption as Option, Select } from 'components/lib/Select';
import { Multiselect } from 'components/lib/Multiselect';
import { useIntl } from 'react-intl';
import BoldNameText from './BoldNameText';
import CustomTag from 'components/CustomTag';
import useAutocompleteSelectStyles from './styles';
import useMultiselectStyles from '../lib/Multiselect/styles';
import clsx from 'clsx';
import { AutocompleteSelectProps, AutocompleteTag } from './types';
import useAutocompleteSelect from './hooks';
import noop from 'lodash/noop';
import NoMatchesFound from 'components/NoMatchesFound';
import BoldedPartOfText from 'components/CustomCell/components/Cells/components/BoldedPartOfText';
import { SearchBoldIcon } from 'components/Icon';

const AutocompleteSelect = ({
  value,
  autocompleteUrl,
  onChange,
  selectMultiple = false,
  disabled,
  placeholder,
  labelInValue = true,
  handleOnBlur = noop,
  filterResults,
  getPopupContainer,
  isLimitExceeded,
  setTotalObjectTypeFields,
  className,
  withBoldedSearchPhrase = false,
  dataTestId,
  withSorting,
  addOptionData = false,
  onClear,
  allowClear,
}: AutocompleteSelectProps) => {
  const {
    fetching,
    data,
    handleChange,
    fetchData,
    isAutocompleteForUsers,
    handleSelectBlur,
    handleDropdownVisibleChange,
    totalCount,
    handleFocus,
  } = useAutocompleteSelect(
    autocompleteUrl,
    onChange,
    handleOnBlur,
    filterResults,
    addOptionData
  );

  const intl = useIntl();
  const classes = useAutocompleteSelectStyles({ disabled });
  const multiselectClasses = useMultiselectStyles({});
  const [searchValue, setSearchValue] = useState<string>('');

  useEffect(() => {
    if (setTotalObjectTypeFields) setTotalObjectTypeFields(totalCount);
  }, [setTotalObjectTypeFields, totalCount]);

  const SelectComponent = useMemo(
    () => (selectMultiple ? Multiselect : Select),
    [selectMultiple]
  );

  const tagRender = useMemo(
    () =>
      isAutocompleteForUsers
        ? //we use any here because optionData types arent exported anywhere from rc-select
          ({ label, ...rest }: AutocompleteTag) => (
            <CustomTag className={classes.selectionItem} {...rest}>
              {typeof label === 'string' && <BoldNameText text={label} />}
            </CustomTag>
          )
        : undefined,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isAutocompleteForUsers]
  );

  const handleFetchData = useCallback(
    (val: string) => {
      isCollapsing.current = false;
      setSearchValue(val);

      if (!isLimitExceeded) fetchData(val);
    },
    [fetchData, isLimitExceeded]
  );

  const isCollapsing = useRef(false);

  const renderOption = useCallback(
    (option: string) => {
      if (!withBoldedSearchPhrase) return option;

      const selectedValue = Array.isArray(value)
        ? value.find(({ label }) => label === option)
        : undefined;

      return !selectedValue ? (
        <BoldedPartOfText value={option} boldPhrase={searchValue} />
      ) : (
        option
      );
    },
    [withBoldedSearchPhrase, searchValue, value]
  );

  const options = useMemo(
    () =>
      withSorting
        ? data.sort((a, b) =>
            a?.text
              ?.toLocaleLowerCase()
              .localeCompare(b?.text?.toLocaleLowerCase())
          )
        : data,
    [withSorting, data]
  );

  return (
    <div className={classes.selectComponentWrapper}>
      <SelectComponent
        // this key resets component internal state when reaching limit
        // which prevents automatically opening dropdown when we set "open" property to undefined
        key={isLimitExceeded?.toString()}
        showSearch
        showArrow
        open={isLimitExceeded ? false : undefined}
        className={clsx(
          classes.autocompleteWrapper,
          {
            [classes.autocompleteSelect]: isAutocompleteForUsers,
            [multiselectClasses.multiselect]:
              !isAutocompleteForUsers && selectMultiple,
          },
          className
        )}
        placeholder={
          placeholder ??
          intl.formatMessage({
            id: 'placeholders.enterText',
            defaultMessage: 'Enter text',
          })
        }
        notFoundContent={
          fetching || isCollapsing.current ? (
            <Loader size='small' />
          ) : (
            <NoMatchesFound />
          )
        }
        filterOption={false}
        onSearch={handleFetchData}
        onChange={handleChange}
        onBlur={() => {
          handleSelectBlur();
          isCollapsing.current = true;
        }}
        onFocus={handleFocus}
        onDropdownVisibleChange={handleDropdownVisibleChange(setSearchValue)}
        {...{
          tagRender,
          value,
          disabled,
          labelInValue,
          getPopupContainer,
          onClear,
          allowClear,
        }}
        icon={<SearchBoldIcon size={12} className={classes.searchIcon} />}
        data-testid={dataTestId}
      >
        {options.map(option => {
          const { value: optionValue, text } = option;

          return (
            <Option
              additionalData={option} //data which might be needed if filter is not yet in redux. Ticket: #35917
              key={optionValue}
              value={optionValue.toString()}
              title={text}
            >
              {isAutocompleteForUsers ? (
                <BoldNameText {...{ text }} />
              ) : (
                renderOption(text)
              )}
            </Option>
          );
        })}
      </SelectComponent>
    </div>
  );
};

export default AutocompleteSelect;
