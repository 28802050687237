import { CancelToken } from 'axios';
import { UsersAndGroupsFormValue } from 'components/FormPreview2/widgets/inPlaceEdit/AdaptedInPlaceEditUser/AdaptedInPlaceEditUser.types';
import { apiCall } from 'utils/api';

/**
 * Calls the PATCH /object-records/:id endpoint to update the user type field for the record.
 */
export const patchUserFieldUsersAndGroups = (
  patchUrl: string,
  objectClassId: number | string,
  objectClassFieldAlias: string,
  patchData: UsersAndGroupsFormValue | null,
  cancelToken?: CancelToken
) => {
  return apiCall.patch(
    patchUrl,
    {
      object_class: objectClassId,
      [objectClassFieldAlias]: patchData,
    },
    {
      cancelToken,
    }
  );
};
