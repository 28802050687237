import React from 'react';
import { useDragLayer, XYCoord } from 'react-dnd';
import { DUMMY_ELEM, TABLE_BODY_ID } from 'utils/elementsIds';
import { TableRow } from '../TableRow';
import { useDraggableTableStyles } from './styles';

const CustomDragLayer = () => {
  const styles = useDraggableTableStyles({});
  const { isDragging, item, initialOffset, currentOffset } = useDragLayer(
    monitor => ({
      item: monitor.getItem(),
      initialOffset: monitor.getInitialSourceClientOffset(),
      currentOffset: monitor.getSourceClientOffset(),
      isDragging: monitor.isDragging(),
    })
  );

  if (!isDragging) {
    return null;
  }

  function getItemStyles(
    initialOffset: XYCoord | null,
    currentOffset: XYCoord | null
  ) {
    if (!initialOffset || !currentOffset) {
      return {
        display: 'none',
      };
    }

    const getY = () => {
      const tableBodyElem =
        document.getElementById(TABLE_BODY_ID)?.getBoundingClientRect() ||
        ({} as DOMRect);
      const dummyElem =
        document.getElementById(DUMMY_ELEM)?.getBoundingClientRect() ||
        ({} as DOMRect);
      const bottomLine = tableBodyElem?.bottom - dummyElem.height;

      if (currentOffset.y < tableBodyElem?.top) return tableBodyElem?.top;

      if (currentOffset.y > bottomLine) return bottomLine;

      return currentOffset.y;
    };

    const transform = `translate(${initialOffset.x}px, ${getY()}px)`;

    return {
      transform,
      WebkitTransform: transform,
    };
  }

  return (
    <div className={styles.customDragLayerWrapper}>
      <div style={getItemStyles(initialOffset, currentOffset)}>
        {item.rowProps?.row ? <TableRow {...item.rowProps} /> : ''}
      </div>
    </div>
  );
};

export default CustomDragLayer;
