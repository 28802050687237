import { createUseStyles } from 'react-jss';
import { ThemeState } from 'store/reducers/types/theme.types';

export const useExpandedUserGroupPanelStyles = createUseStyles(
  (theme: ThemeState) => ({
    contentContainer: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'start',
    },
    controlsContainer: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'start',
      gap: '0.5rem',
      padding: '0.5rem',
      borderBottom: `1px solid ${theme['border-color-base']}`,
    },
    userListContainer: {
      width: '100%',
      height: '100%',
      maxHeight: '300px',
      overflowY: 'auto',
    },
    searchIcon: {
      color: theme['primary-color'],
    },
    loadingIndicatorContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '12px',
      height: '22px',

      '& .ant-spin': {
        display: 'flex',
      },
    },
    searchInput: {
      '& .ant-input': {
        paddingLeft: '0.313rem !important',
      },
    },
  })
);
