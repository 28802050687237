import CommaSeparatedString from 'components/CustomCell/components/Cells/components/CommaSeparatedString';
import { CustomCellProps } from 'components/CustomCell/CustomCell.types';
import React from 'react';
import CustomCells from 'utils/Enums/CustomCells';

export const ObjectRecordGroupsCell = ({ row }: CustomCellProps) => {
  const { object_classes } = row;

  return (
    <CommaSeparatedString
      values={object_classes}
      highlightedColumnKey={CustomCells.ObjectRecordGroups}
    />
  );
};
