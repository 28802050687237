import { useCallback, useState } from 'react';
import { UsersAndGroupsSelectionCountLimits } from 'utils/types/selectInput.types';
import { UsersAndGroupsFormValue } from 'components/FormPreview2/widgets/inPlaceEdit/AdaptedInPlaceEditUser/AdaptedInPlaceEditUser.types';
import {
  useUserFieldMinErrorMessages,
  useValidateFulfillment,
} from 'components/UsersAndGroupsSelection/hooks';
import { useFieldRequiredValidation } from 'components/InPlaceEditControls/hooks';
import { useUsersValidation } from './useUsersValidation';
import { useGroupsValidation } from './useGroupsValidation';

/**
 * Provides tools to validate various elements of InPlaceEditUser.
 */
export const useInPlaceEditUserValidation = (
  fieldLabel: string,
  apiErrors: string[],
  limits: UsersAndGroupsSelectionCountLimits | undefined,
  required: boolean,
  minUsers: number | undefined,
  minGroups: number | undefined
) => {
  const [validationErrors, setValidationErrors] = useState<string[]>([]);

  const { minGroupsError, minUsersError } = useUserFieldMinErrorMessages(
    fieldLabel,
    minUsers ?? 1,
    minGroups ?? 1
  );

  const {
    validateFulfillment,
    tooltip,
    fulfillmentErrors,
  } = useValidateFulfillment(true, minUsersError);

  const { validateRequired } = useFieldRequiredValidation(fieldLabel, required);
  const { validateUsers } = useUsersValidation(fieldLabel, limits);
  const { validateGroups } = useGroupsValidation(fieldLabel, limits);

  const clearErrors = useCallback(() => {
    setValidationErrors([]);
  }, []);

  const validateField = useCallback(
    (fieldValue: UsersAndGroupsFormValue | null) => {
      const errors = [
        ...validateRequired(fieldValue),
        ...validateUsers(fieldValue),
        ...validateGroups(fieldValue),
      ];

      setValidationErrors(errors);
      return errors.length === 0;
    },
    [validateGroups, validateRequired, validateUsers]
  );

  const finalErrors = [
    ...fulfillmentErrors,
    ...validationErrors,
    ...(apiErrors ?? []),
  ];

  return {
    validateFulfillment,
    validateField,
    errors: finalErrors,
    tooltip,
    clearErrors,
    minUsersError,
    minGroupsError,
  };
};
