import { CustomCellProps } from 'components/CustomCell/CustomCell.types';
import UserCell from 'components/CustomCell/components/Cells/components/UserCell';
import React from 'react';

export const UserCustomCell = ({ row }: CustomCellProps) => {
  const { first_name, last_name, id } = row;

  return (
    <UserCell
      user={{
        id,
        first_name,
        last_name,
      }}
    />
  );
};
